import React, {memo, useState} from 'react';
import { makeStyles } from '@material-ui/core';
import {Popover, TextField, Typography, FormControlLabel, Checkbox, MenuItem } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { DroppedItemTypes } from '../../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    textFieldContainer: {
        padding: '8px',
    },
}))


const SetLabelPopover = memo(({ anchorEl, handleLabelPopoverClose, index, handleChangeLabel, label, droppedItems, fromTextArea, component_type }) => {
    const classes = useStyles();

    const [usePreviousLabel, setUsePreviousLabel] = useState(false);
    const [selectedLabel, setSelectedLabel]  = useState('-1');

    const labelList = Array.from(new Set(droppedItems
        .filter((item) => item.component_type === component_type && item.component_label !== '')
        .map((item) => item.component_label)
    ));

    const handleLabelChange = (e) => {
        const value = e.target.value;
        setSelectedLabel(value);

        handleChangeLabel(e, index);
    }

    return (
        <Popover
            id={'label-popover'}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleLabelPopoverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            style={{top:fromTextArea ? '24px' : '0px' }}
        >
            <div className={classes.textFieldContainer}>
                {labelList.length > 0 && 
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="checkedB"
                                color="primary"
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => setUsePreviousLabel(e.target.checked)}
                                checked={usePreviousLabel}
                            />
                        }
                        label={`Use Previous Label`}
                    />
                }
                
                {labelList.length > 0 && 
                    <TextField fullWidth
                        margin="dense"
                        variant="outlined"
                        select
                        disabled={!usePreviousLabel}
                        value={selectedLabel}
                        onChange={handleLabelChange}
                        style={{marginBottom:'8px', marginTop:'-2px'}}
                    >
                        <MenuItem value={"-1"}>Select Label</MenuItem>
                        {labelList.map((label) => (
                            <MenuItem key={label} value={label}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>
                }


                <Typography style={{marginBottom:'-4px', marginTop:'8px', fontSize:'14px'}}>
                    New Label
                </Typography>
                <TextField
                    variant='outlined'
                    margin='dense'
                    value={label}
                    onChange={(e) => handleChangeLabel(e, index)}
                    fullWidth
                    disabled={usePreviousLabel}
                    placeholder='Type here'
                />
            </div>
        </Popover>
    );
})

export default SetLabelPopover;