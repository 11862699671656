import { Button, Box, Dialog, Grid, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Slide from "@material-ui/core/Slide";
import CreateUpdateBillDetailComponent from "../CreateUpdateBillDetailComponent";
import {
    PdfLoader,
    PdfHighlighter,
    Tip,
    Highlight,
    Popup,
    AreaHighlight,
} from "react-pdf-highlighter";
import moment from "moment";
import { useIsMount } from "../useIsMount";
import { useSnackbar } from "notistack";
import { getConvertedBoundingBox } from "../../util/pdfUtil";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { Spinner } from "../Spinner";
import { consoleToLog, getUrlAccordingToSelectedComponent } from "../../util/AppUtil";
import { getBillDetailsApi } from "../../services/authService";
import { history } from '../../routers/AppRouter';
import { connect } from "react-redux";
import PreviewBillComponent from "../PreviewBillComponent";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    zoomContainerStyle: {
        width:'100px',
        position:"absolute", 
        bottom:12, 
        left:12, 
        zIndex:999, 
        padding:'8px',
        
    },
    zoomContainerStyle1: {
        width:'100px',
        position:"absolute", 
        bottom:18, 
        left:12, 
        zIndex:999, 
        padding:'8px',
        
    },
    backIconContainerStyle: {
        position:"absolute", 
        top:12, 
        left: 12, 
        zIndex:999,
        background:'#fff'
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BillDetailModalComponent=(props)=>{
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [billObj, setBillObj]=useState({});
    const [selectedEntity, setSelectedEntity]=useState(props.selectedEntity);

    const isMount = useIsMount();
    //const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    
    const [pdfUrl, setPdfUrl] = useState("");
    const [pageDimensions, setPageDimensions] = useState([]);
    //const [docArr, setDocArr] = useState([]);
    const [pdfLoaded, setPdfLoaded] = useState(false);
    const [ExpenseDocuments, setExpenseDocumentsArr] = useState([]);
    const [zoomVal, setZoomVal] = useState(0.9);
    const [count, setCount] = useState(0);

    const pdfHighlighterRef = useRef(null);
    const highlightArr = useRef([]);
    

    const urlParts = window.location.pathname?.split('/');
    const pathname = urlParts[1];
    //const showEstimateScreen = pathname === 'estimate' ? true : false;
    const slug = urlParts[2];

    //let type = urlParts.length > 3 ? urlParts[3] : '';
    let type;
    //if bill is viewed from unclaimed bill component
    //then irrespective of in url type is edit or preview 
    //always have type=''preview, as we want to show bill only
    if(props.isFromUnclaimedBill){
        type='preview';
    }else{
        type = urlParts.length > 3 ? urlParts[3] : '';
    }

    let scrollViewerTo = (highlight) => {};

    const parseIdFromHash = () =>  document.location.hash.slice("highlight-".length);
    
    const resetHash = () => document.location.hash = "";
    let currentTimeStamp = moment().valueOf();
    
    
    const HighlightPopup = ({
        comment
    }) =>
        comment.text ? (
            <div className="Highlight__popup">
                {comment.emoji} {comment.text}
            </div>
        ) : null;
    

    const [isDialogOpen, setIsDialogOpen] = useState(props.openBillDetailDialog); // Local state for dialog

    useEffect(() => {
        console.log("UseEffect open dialog");
        // Update local dialog state based on prop changes
        setIsDialogOpen(props.openBillDetailDialog);
    }, [props.openBillDetailDialog]);


    const handleClose = () => {
        // Close dialog and call parent handler
        setIsDialogOpen(false);
        if(!props.isFromUnclaimedBill){
            history.push(getUrlAccordingToSelectedComponent(props.drawerState));
        }
        props.handleBillDetailClickDialogClose(props.editPreview);
    };

    useEffect(() => {
        //if from bill list edit clicked 
        if(slug && (type === 'edit' || type === 'preview')) {
            console.log("In Edit/preview");
            getBillDetails();
        }
        else if(props.billObj){
            console.log("In Create", billObj);
            console.log("Left side***bill obj", props.billObj);
            console.log("Left side****In class", props.billObj?.purchase_invoice?.ocr_json?.ExpenseDocuments);
            setBillObj(props.billObj);
            setExpenseDocumentsArr(props.billObj?.purchase_invoice?.ocr_json?.ExpenseDocuments || [])
            setPdfUrl(props.billObj?.purchase_invoice?.attachment_url);
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        console.log("UseEffect previewBillObj", props.previewBillObj);
        if(props.previewBillObj && Object.keys(props.previewBillObj).length>0){
            setBillObj(props.previewBillObj);
        }
    }, [props.previewBillObj]);


    const getBillDetails = async () => {
        try {
            const response = await getBillDetailsApi(props.selectedAccount.id, slug, props.isFromUnclaimedBill, props.billId);
            const res = response.data;
            consoleToLog('Response getBillDetailsApi: ', res);

            if (type === 'preview') {
                const billObjLocal={...res};
                console.log("***Preview Left component got bill obj", billObjLocal);
                //setExpenseDocumentsArr(billObjLocal?.ocr_json?.ExpenseDocuments || [])
                setBillObj({purchase_invoice: billObjLocal, vendors: []});
                setPdfUrl(billObjLocal?.attachment_url);
            } else {
                const billObjLocal={...res}
                console.log("***Left component got bill obj", billObjLocal);
                setExpenseDocumentsArr(billObjLocal?.ocr_json?.ExpenseDocuments || [])
                setBillObj({purchase_invoice: billObjLocal, vendors: []});
                setPdfUrl(billObjLocal?.attachment_url);
            }

        } catch (error) {
            consoleToLog("Error getBillDetailsApi", error);
        }
    }

    useEffect(() => {
        if(!isMount) {
            console.log("pdfUrl:useeffect got pdf url");
            const handleHashChange = () => {
                console.log("From useeffect scollhash ")
                scrollToHighlightFromHash();
            };

            if(pdfUrl) {
                window.addEventListener("hashchange", handleHashChange, false);
            } else {
                setLoading(false);
            }

            return () => {
                // Cleanup: Remove the event listener when the component unmounts.
                window.removeEventListener("hashchange", handleHashChange);
            };
        }
    }, [pdfUrl]);

    const scrollToHighlightFromHash = () => {
        if(highlightArr.current.length === 0){ 
            consoleToLog("In scrollToHighlightFromHash");
            getWidthAndHeight();
        }
        const highlight = getHighlightById(parseIdFromHash());
            //console.log('highlight obj', highlight);

        if (highlight) {
            scrollViewerTo(highlight);
        }
    }

    const getWidthAndHeight = () => {
        if(pdfHighlighterRef.current) {
            console.log('item logged');
            const pageElements = pdfHighlighterRef.current.querySelectorAll('.page');

            const pageDimensions = Array.from(pageElements).map((pageElement) => {
                return {
                width: pageElement.clientWidth,
                height: pageElement.clientHeight,
                };
            });
            setPageDimensions(pageDimensions);
            transformJsonToHighLightArray(pageDimensions);
        } else {
            setLoading(false);
        }
    }

    const isTypeAlreadtExistInArray = (localSF, type) => {
        return localSF.some( sf => sf['Type'].Text === type )
    }

    const extractRequiredHighlights = (SummaryFields)=> {
    let localSF=[]
    for(let i=0;i<SummaryFields.length;i++){
        if(SummaryFields[i].Type.Text === 'VENDOR_PAN_NUMBER' && !isTypeAlreadtExistInArray(localSF, SummaryFields[i].Type.Text)){
            localSF.push(SummaryFields[i]);
        }
        else if(SummaryFields[i].Type.Text === "VENDOR_NAME" && !isTypeAlreadtExistInArray(localSF, SummaryFields[i].Type.Text)){
            localSF.push(SummaryFields[i]);
        }
        // else if(SummaryFields[i].Type.Text === "TAX_PAYER_ID"){
        //   localSF.push(SummaryFields[i]);
        // }
        else if(SummaryFields[i].Type.Text === "INVOICE_RECEIPT_ID" && !isTypeAlreadtExistInArray(localSF, SummaryFields[i].Type.Text)){
            localSF.push(SummaryFields[i]);
        }
        else if(SummaryFields[i].Type.Text === "INVOICE_RECEIPT_DATE" && !isTypeAlreadtExistInArray(localSF, SummaryFields[i].Type.Text)){
            localSF.push(SummaryFields[i]);
        }
        else if(SummaryFields[i].Type.Text === "DUE_DATE" && !isTypeAlreadtExistInArray(localSF, SummaryFields[i].Type.Text)){
            localSF.push(SummaryFields[i]);
        }
        else if(SummaryFields[i].Type.Text === "VENDOR_GST_NUMBER" && !isTypeAlreadtExistInArray(localSF, SummaryFields[i].Type.Text)){
            localSF.push(SummaryFields[i]);
        }
        else if(SummaryFields[i].Type.Text === "ITEM"){
            localSF.push(SummaryFields[i]);
        }
        else if(SummaryFields[i].Type.Text === "PRICE"){
            localSF.push(SummaryFields[i]);
        }
        else if(SummaryFields[i].Type.Text === "QUANTITY"){
            localSF.push(SummaryFields[i]);
        }
        else if(SummaryFields[i].Type.Text === "PRODUCT_CODE"){
            localSF.push(SummaryFields[i]);
        }
        /* else if(SummaryFields[i].Type.Text === "SUBTOTAL"){
        localSF.push(SummaryFields[i]);
        }
        else if(SummaryFields[i].Type.Text === "TOTAL"){
        localSF.push(SummaryFields[i]);
        } */

        }
        return localSF;
        
    }

    
    const transformJsonToHighLightArray = (pageDimensions) => {
        console.log('**pageDimension', pageDimensions);
        if(pageDimensions.length > 0) {
            console.log('summaryfiedl dad', ExpenseDocuments)
            let SummaryFields = [...ExpenseDocuments.map(document => document.SummaryFields)].flat();

            console.log("total extracted items length: ", SummaryFields.length)
            SummaryFields = extractRequiredHighlights(SummaryFields);
            //const obj = extractRequiredHighlightsOne(SummaryFields);
            //console.log('arr**********', SummaryFields);
            let docArr = SummaryFields.map((document) => getConvertedBoundingBox(document, pageDimensions));
            //console.log('docArr length**********', docArr.length);
            //console.log('docArr**********', docArr);
            highlightArr.current = [...docArr];
            setLoading(false);
        } else {
            setLoading(false);
        }
    }
    
    
    const getHighlightById = (id) => {
        //console.log('id***********************', id, highlightArr.current);
        return highlightArr.current.find((highlight) => highlight ? highlight.id === id.replace('-', '') : '');
    };


    const getNextId = () => String(Math.random()).slice(2);

    const updateHighlight = (highlightId, position, content) => {
    const higlightList = highlightArr.current.map((h) => {
        const {
        id,
        position: originalPosition,
        content: originalContent,
        ...rest
        } = h;
        return id === highlightId
        ? {
            id,
            position: { ...originalPosition, ...position },
            content: { ...originalContent, ...content },
            ...rest,
        }
        : h;
    });
    highlightArr.current = [...higlightList];
    //setDocArr(higlightList);
    }


    const onZoomChange = (val) => {
        if(val === 'in') {
            let zoomIn = zoomVal + 0.3
            setZoomVal(zoomIn);
            setCount(count => count + 1);
            //setPdfUrl(billObj?.purchase_invoice?.attachment_url);
        } else {
            let zoomOut = zoomVal - 0.3;
            if(zoomOut < 0.6) {
                setZoomVal(0.6);
                setCount(count => count + 1);
            } else {
                setZoomVal(zoomOut);
                setCount(count => count + 1);
            }
            if(zoomVal !== 0.6) {
                //setPdfUrl(billObj?.purchase_invoice?.attachment_url);
            }
        }
    }

    return (
        <Dialog
            fullScreen
            open={isDialogOpen}
            onClose={handleClose}
            TransitionComponent={Transition}
            style={props.isFromUnclaimedBill ? {zIndex:'100', margin: '10px'} : {zIndex:'100'}}
            /* onClose={()=>{
                history.push(getUrlAccordingToSelectedComponent(props.drawerState));
                props.handleBillDetailClickDialogClose(props.editPreview);
            }} */>

            <div style={{ display: "flex", height: "100vh" }}>
                <div style={{
                        height: "100vh",
                        width: (type === 'preview')? "45vw" : "50vw",
                        position: "relative",
                        overFlowX: "scroll"
                    }}
                >   
                    <div className={classes.backIconContainerStyle}>
                        <ArrowLeftIcon className={'preview_invoice_back_btn'}
                            onClick={handleClose}
                        />
                    </div>
                    {
                    <Grid item container
                        className={props.editPreview ? classes.zoomContainerStyle1 : classes.zoomContainerStyle}>
                        <Grid item>
                            <Button variant='contained' color='secondary' 
                                style={{minWidth:"22px", padding:'4px'}}
                                onClick={() => onZoomChange('in')}>
                                <Tooltip title='Zoom In' arrow>
                                    <AddIcon fontSize='small'/>
                                </Tooltip>
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button variant='contained' color='secondary'  
                                style={{minWidth:"22px", padding:'4px', marginLeft:'8px'}}
                                onClick={() => onZoomChange('out')}>
                                <Tooltip title='Zoom Out' arrow>
                                    <RemoveIcon fontSize='small'/>
                                </Tooltip>
                            </Button>
                        </Grid>
                    </Grid>
                    }
                    {pdfUrl ?
                    <PdfLoader url={pdfUrl} beforeLoad={<Spinner/>}>
                        {(pdfDocument) => {
                        return (
                            <div ref={pdfHighlighterRef}>
                                <PdfHighlighter
                                    key={count}
                                    pdfDocument={pdfDocument}
                                    enableAreaSelection={(event) => event.altKey}
                                    pdfScaleValue={zoomVal}
                                    onScrollChange={resetHash}
                                    scrollRef={(scrollTo) => {
                                        scrollViewerTo =  scrollTo;
                                        scrollToHighlightFromHash();
                                    }}

                                    onSelectionFinished={(
                                        position,
                                        content,
                                        hideTipAndSelection,
                                        transformSelection
                                        ) => (
                                        <Tip
                                            onOpen={transformSelection}
                                            onConfirm={comment => {
                                            //this.addHighlight({ content, position, comment });
                        
                                            hideTipAndSelection();
                                            }}
                                        />
                                    )}

                                    highlightTransform={(
                                    highlight,
                                    index,
                                    setTip,
                                    hideTip,
                                    viewportToScaled,
                                    screenshot,
                                    isScrolledTo
                                    ) => {
                                    const isTextHighlight = !Boolean(
                                        highlight.content && highlight.content.image
                                    );

                                    const component = isTextHighlight ? (
                                        <Highlight
                                        isScrolledTo={isScrolledTo}
                                        position={highlight.position}
                                        comment={highlight.comment}
                                        />
                                    ) : (
                                        <AreaHighlight
                                        isScrolledTo={isScrolledTo}
                                        highlight={highlight}
                                        onChange={(boundingRect) => {
                                            updateHighlight(
                                            highlight.id,
                                            { boundingRect: viewportToScaled(boundingRect) },
                                            { image: screenshot(boundingRect) }
                                            );
                                        }}
                                        />
                                    );

                                    return (
                                        <Popup
                                        popupContent={<HighlightPopup {...highlight} />}
                                        onMouseOver={(popupContent) =>
                                            setTip(highlight, (highlight) => popupContent)
                                        }
                                        onMouseOut={hideTip}
                                        key={index}
                                        children={component}
                                        />
                                    );

                                    }}
                                    highlights={highlightArr.current}
                                />
                            </div>
                        )}}
                    </PdfLoader>
                    :
                    !loading &&
                    <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center' height='100vh'>
                        <img src="/images/no_pdf_fallback_image.svg" width="60%"/>
                        <Typography variant="h6" style={{marginTop:'8px'}}>
                            No PDF Uploaded
                        </Typography>
                    </Box>
                }
                </div>
                <div style={{ width: (type === 'preview')? "55vw" : "50vw"/* , position:'relative'  */}}>
                    {type === 'preview' ? 
                    <PreviewBillComponent billObj={billObj}
                        selectedEntity={selectedEntity}
                        setBillObj={setBillObj}
                        handleBillDetailClickDialogClose={()=>handleClose()}
                        loading={loading}
                        setOpenAddPamentBillDrawer={props.setOpenAddPamentBillDrawer}
                        handleCloseAddPaymentBillDrawer={props.handleCloseAddPaymentBillDrawer}
                        updateBillObj={props.updateBillObj}
                        setFromPreviewScreen={props.setFromPreviewScreen}
                        editPreview={props.editPreview}
                        setUpdatedBillObj={props.setUpdatedBillObj}
                        isFromUnclaimedBill={props.isFromUnclaimedBill}
                    />
                    :
                    <CreateUpdateBillDetailComponent
                        highlights={highlightArr.current}
                        billObj={billObj}
                        selectedEntity={selectedEntity}
                        /* handleBillDetailClickDialogClose={()=>{
                            history.push(getUrlAccordingToSelectedComponent(props.drawerState));
                            props.handleBillDetailClickDialogClose(props.editPreview);
                        }} */
                        handleBillDetailClickDialogClose={()=>handleClose()}
                        loading={loading}
                        setUpdatedBillObj={props.setUpdatedBillObj}
                        //pageDimensions={pageDimensions}
                        />}
                </div>
            </div>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    drawerState: state.drawerState.setDrawerState
});

export default connect(mapStateToProps)(BillDetailModalComponent);