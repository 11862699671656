import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { loadState, saveState } from './localStorage'
import configureStore from './store/configureStore';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "./theme";
import ReactGA from "react-ga4";
import { consoleToLog, isDebug } from './util/AppUtil';

const persistedState = loadState();
const store = configureStore(persistedState);

//Things you want to be persisted all time
store.subscribe(() => {
  saveState({
      invoiceAuth: store.getState().invoiceAuth,
      drawerState: store.getState().drawerState,
      invoiceAccounts: store.getState().invoiceAccounts,
      invoiceFilters: store.getState().invoiceFilters,
      transactionFilters: store.getState().transactionFilters,
      proposalFilters: store.getState().proposalFilters,
      documentFilters: store.getState().documentFilters,
      overlay: store.getState().overlay
  });
});

// //google analytics
const initGA4 = () => {
  consoleToLog("***init GA4")
  ReactGA.initialize(process.env.REACT_APP_GA_API_KEY);
}

if (!isDebug()) {
  initGA4();
}

const container = document.getElementById('root');
const root = createRoot(container); 
root.render(
    <Provider store={store} >
      <ThemeProvider theme={theme}>
        <App />
        </ThemeProvider>
    </Provider>
);
