import React from 'react';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import {Grid, Button, FormControlLabel, Checkbox} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IssueDateRangeFilterComponent from './filters/IssueDateRangeFilterComponent';
import StatusFilterComponent from './filters/StatusFilterComponent';
import ClientFilterComponent from './filters/ClientFilterComponent';
import {clearSelectedInvoiceFilters, setUnclaimedBillFilter} from '../actions/invoiceFilters';
import {connect} from 'react-redux';
import InvoiceEstimateTypeFilterComponent from './filters/InvoiceEstimateTypeFilterComponent';
import AutocompleteEntities from './filters/AutocompleteEntities';
import { clearSelectedProposalFilters } from '../actions/proposalFilters';
import BillStatusFilterComponent from './filters/BillStatusFilterComponent';
import VendorFilterComponent from './filters/VendorFilterComponent';

const useStyles = makeStyles((theme) => ({
    customMarginTop : {
        marginTop:"16px"
    },
    customMarginBottom : {
        marginBottom:'-8px'
    },
}));

const BillFilterDrawer = (props) => {
    const classes = useStyles();

    const proforma_enabled = props.selectedAccount.general_settings?.proforma_enabled
    return (
        <RightToLeftSideDrawer title="Filters" 
            openDrawer={props.openFilterDrawer}
            closeDrawer={props.handleCloseFilterDrawer}>

            <Grid item sm={12} style={{padding:'16px'}}>
                    <IssueDateRangeFilterComponent pageReset={props.pageReset}
                        />

                    <BillStatusFilterComponent pageReset={props.pageReset} 
                        />

                    <AutocompleteEntities pageReset={props.pageReset}/>

                    <VendorFilterComponent pageReset={props.pageReset}/>
                    <FormControlLabel
                        style={{marginTop: "10px"}}
                        control={
                        <Checkbox
                            checked={props.unclaimedBills}
                            onChange={() =>{
                                props.pageReset();
                                props.setUnclaimedBillFilter(!props.unclaimedBills)
                            }
                        }
                        />
                        }
                        label="Unclaimed bills"
                    />
                <div className="modal__footer">
                    <Button variant="contained" color="primary" 
                            className="modal__button"
                            onClick={() =>{
                                    props.pageReset();
                                    if(props.fromProposalListComponent) {
                                        props.clearSelectedProposalFilters();
                                    } else {
                                        props.clearSelectedInvoiceFilters();
                                    }
                                }
                            }
                            >
                        Reset Filters
                    </Button>
                </div> 
            </Grid>
            
        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    unclaimedBills: state.invoiceFilters.unclaimedBills
})

const mapDispatchToProps = (dispatch) => ({
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    clearSelectedProposalFilters: () => dispatch(clearSelectedProposalFilters()),
    setUnclaimedBillFilter: (unclaimedBills) => dispatch(setUnclaimedBillFilter(unclaimedBills))
});

export default connect(mapStateToProps, mapDispatchToProps)(BillFilterDrawer);