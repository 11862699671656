import React, {useState, useEffect} from 'react';
import {Dialog, DialogContent, Grid, Typography, Button, TextField, CircularProgress} from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {FormControlLabel, Checkbox} from '@material-ui/core';
import { isInvoiceStatusCancelled } from '../util/AppUtil';
import TransformIcon from '@material-ui/icons/Transform';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import CancelPresentationRoundedIcon from '@material-ui/icons/CancelPresentationRounded';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
    MuiDialogpaper: {
        scrollbarWidth: 'none !important',
    },
    dialogActionImg: {
        width:'60px',
        marginBottom:'8px',
        display:'flex',
        justifyContent:'center'
    },
    dailogHeading: {
        fontSize:'21px',
        fontWeight:'600',
        marginBottom:'8px',
        textAlign:'center'
    },
    yesButtonStyle: {
        borderRadius: '5px',
        background: '#318415',
        color:'#fff',
        width:'110px',
        '&:hover': {
            background:'#318415'
        }
    },
    noButtonStyle: {
        borderRadius: '5px',
        background: '#888',
        color:'#fff',
        width:'110px',
        '&:hover': {
            background:'rgba(0,0,0,0.3)'
        },
    }
}))



const ShowConfirmDialog = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [deleteText, setDeleteText] = useState('');
    const [cancelRecurringInvoice, setCancelRecurringInvoice] = useState(false);

    const invoice_account_id = props.selectedAccount?.id;

    const displayIcon = () => {

        if(props.fromEntitiesComponent && props.tabValue === 'active') {
            return <ArchiveOutlinedIcon style={{fontSize:'45px', color:'red'}}/>
        }

        if(props.fromEntitiesComponent && props.tabValue === 'archived' && !props.entityDelete) {
            return <RestoreOutlinedIcon style={{fontSize:'45px', color:'#34A853'}}/>
        }

        if(props.fromEntitiesComponent && props.tabValue === 'archived' && props.entityDelete) {
            return <DeleteOutlineOutlinedIcon style={{fontSize:'45px', color:'red'}}/>
        }

        if((props.fromInvoiceListComponent && props.invoiceItem?.cancel_invoice_clicked) || (!props.showEstimateScreen && props.fromPreviewScreen && props.invoiceItem?.cancel_invoice_clicked)) {
            return <CancelOutlinedIcon style={{fontSize:'45px', color:'red'}}/>
        }

        if(props.fromInvoiceListComponent && props.invoiceItem?.convert_to_draft_clicked) {
            return <TransformIcon style={{fontSize:'45px', color:'#828c98'}}/>
        }


        if((props.showEstimateScreen || props.fromEstimateListComponent) && props.estimateItem?.cancel_estimate_clicked) {
            return <CancelOutlinedIcon style={{fontSize:'45px', color:'red'}}/>
        }

        if((props.showEstimateScreen || props.fromEstimateListComponent) && props.estimateItem?.reopen_estimate_clicked) {
            return <UndoOutlinedIcon style={{fontSize:'45px', color:'#828c98'}}/>
        }

        if((props.showEstimateScreen || props.fromEstimateListComponent) && props.estimateItem?.close_estimate_clicked) {
            return <CloseOutlinedIcon style={{fontSize:'45px', color:'red'}}/>
        }

        if((props.fromClientEstimatePreview && props.estimateItem?.approve_estimate_clicked) || (props.showEstimateScreen  && props.estimateItem?.mark_as_approved)) {
            return <PlaylistAddCheckOutlinedIcon style={{fontSize:'45px', color:'green'}}/>
        }

        if(props.fromClientEstimatePreview && props.estimateItem?.reject_estimate_clicked || (props.showEstimateScreen  && props.estimateItem?.mark_as_rejected)) {
            return <CancelPresentationRoundedIcon style={{fontSize:'45px', color:'red'}}/>
        }

        if(props.fromInvoiceListComponent && props.invoiceItem?.delete_invoice_clicked) {
            return <DeleteOutlineOutlinedIcon style={{fontSize:'45px', color:'red'}}/>
        }

        if(props.fromEstimateListComponent && props.estimateItem?.delete_estimate_clicked) {
            return <DeleteOutlineOutlinedIcon style={{fontSize:'45px', color:'red'}}/>
        }

        if(props.fromTransactionListComponent && props.transactionItem?.delete_transaction_clicked) {
            return <DeleteOutlineOutlinedIcon style={{fontSize:'45px', color:'red'}}/>
        }
        if(props.fromPaymentGateway) {
            return <DeleteOutlineOutlinedIcon style={{fontSize:'45px', color:'red'}}/>
        }
    }

    const displayHeading = () => {

        if(props.fromEntitiesComponent && props.tabValue === 'active') {
            return 'Archive Entity?';
        }

        if(props.fromEntitiesComponent && props.tabValue === 'archived' && !props.entityDelete) {
            return 'Restore Entity?';
        }

        if(props.fromEntitiesComponent && props.tabValue === 'archived' && props.entityDelete) {
            return  `Delete Entity?`;
        }

        if((props.fromInvoiceListComponent && props.invoiceItem?.cancel_invoice_clicked) || (!props.showEstimateScreen && props.fromPreviewScreen && props.invoiceItem.cancel_invoice_clicked)) {
            return  props.isFromBill? 'Cancel Bill' : `Cancel Invoice?`;
        }

        if(props.fromInvoiceListComponent && props.invoiceItem?.convert_to_draft_clicked) {
            return  `Convert To Draft?`;
        }

        if((props.showEstimateScreen || props.fromEstimateListComponent) && props.estimateItem?.cancel_estimate_clicked) {
            return 'Cancel Estimate?'
        }

        if((props.showEstimateScreen || props.fromEstimateListComponent) && props.estimateItem?.reopen_estimate_clicked) {
            return 'Reopen Estimate?'
        }

        if((props.showEstimateScreen || props.fromEstimateListComponent) && props.estimateItem?.close_estimate_clicked) {
            return 'Close Estimate?'
        }

        if(props.fromClientEstimatePreview && props.estimateItem.approve_estimate_clicked) {
            return 'Approve Estimate?'
        }

        if(props.fromClientEstimatePreview && props.estimateItem.reject_estimate_clicked) {
            return 'Reject Estimate?'
        }

        if(props.showEstimateScreen  && props.estimateItem?.mark_as_approved) {
            return 'Approve Estimate?'
        }

        if(props.showEstimateScreen && props.estimateItem?.mark_as_rejected) {
            return 'Reject Estimate?'
        }

        if(props.fromInvoiceListComponent && props.invoiceItem?.delete_invoice_clicked) {
            return props.isFromBill? 'Delete Bill?':'Delete Invoice?'
        }

        if(props.fromEstimateListComponent && props.estimateItem?.delete_estimate_clicked) {
            return 'Delete Estimate?'
        }

        if(props.fromTransactionListComponent && props.transactionItem?.delete_transaction_clicked) {
            return 'Delete Transaction?'
        }

        if(props.fromTransactionListComponent && props.transactionItem?.delete_payment_clicked) {
            return 'Delete Payment?'
        }

        if(props.fromPaymentGateway){
            return "Revoke razorpay authorization?"
        }

        if(props.invoiceItem?.reopen_bill_clicked){
            return 'Reopen bill?'
        }
    }

    const displayMessage = () => {
        if(props.invoiceItem?.reopen_bill_clicked){
             return `Are you sure you want to reopen this bill?`
        }

        if(props.fromEntitiesComponent && props.tabValue === 'active') {
            return 'Are you sure you want to archive this entity?';
        }

        if(props.fromEntitiesComponent && props.tabValue === 'archived' && !props.entityDelete) {
            return 'Are you sure you want to restore this entity?';
        }

        if(props.fromEntitiesComponent && props.tabValue === 'archived' && props.entityDelete) {
            return "Are you sure you want to delete the entity? All invoices and related data will be deleted.";
        }

        if((props.fromInvoiceListComponent && props.invoiceItem?.cancel_invoice_clicked) || (!props.showEstimateScreen && props.fromPreviewScreen && props.invoiceItem.cancel_invoice_clicked)) {
            return props.isFromBill?  "Are you sure you want to cancel this bill?": "Are you sure you want to cancel this invoice?";
        }

        if(props.fromInvoiceListComponent && props.invoiceItem?.convert_to_draft_clicked) {
            return "Are you sure you want to convert this invoice to draft?";
        }

        if((props.showEstimateScreen || props.fromEstimateListComponent) && props.estimateItem.cancel_estimate_clicked) {
            return `Are you sure you want to cancel this estimate?`
        }

        if((props.showEstimateScreen || props.fromEstimateListComponent) && props.estimateItem.reopen_estimate_clicked) {
            return `Are you sure you want to reopen this estimate?`
        }

        if((props.showEstimateScreen || props.fromEstimateListComponent) && props.estimateItem.close_estimate_clicked) {
            return `Are you sure you want to close this estimate?`
        }

        if((props.fromClientEstimatePreview && props.estimateItem.approve_estimate_clicked) || (props.showEstimateScreen  && props.estimateItem?.mark_as_approved)) {
            return `Are you sure you want to approve this estimate?`
        }

        if(props.fromClientEstimatePreview && props.estimateItem.reject_estimate_clicked || (props.showEstimateScreen  && props.estimateItem?.mark_as_rejected)) {
            return `Are you sure you want to reject this estimate?`
        }

        if(props.fromInvoiceListComponent && props.invoiceItem?.delete_invoice_clicked) {
            return props.isFromBill? `Are you sure you want to delete this bill?`:`Are you sure you want to delete this invoice?`
        }

        if(props.fromEstimateListComponent && props.estimateItem?.delete_estimate_clicked) {
            return `Are you sure you want to delete this estimate?`
        }
        
        if(props.fromTransactionListComponent && props.transactionItem?.delete_transaction_clicked) {
            return `Are you sure you want to delete this transaction?`
        }
        
        if(props.fromTransactionListComponent && props.transactionItem?.delete_payment_clicked) {
            return `Are you sure you want to delete this payment?`
        }

        if(props.fromPaymentGateway){
            return 'Are you sure you want to revoke Razorpay authorization?'
        }

    }

    const callCancelInvoiceApiOrConvertToDraftApiFromParent = () => {
        const invoice_id = props.invoiceItem?.id;
        const cancel_recurring_invoice = cancelRecurringInvoice;
        const recurring_invoice_id = cancel_recurring_invoice ? props.invoiceItem?.recurring_invoice.id : undefined;

        if(props.invoiceItem?.reopen_bill_clicked) {
            props.callReopenBillApi(invoice_account_id, invoice_id);
        }
        if(props.invoiceItem?.cancel_invoice_clicked) {
            props.callCancelInvoiceApi(invoice_account_id, invoice_id, cancel_recurring_invoice, recurring_invoice_id);
        }

        if(props.invoiceItem?.convert_to_draft_clicked) {
            props.convertToDraftInvoice(invoice_account_id, invoice_id)
        }

        if(props.invoiceItem?.delete_invoice_clicked) {
            props.callDeleteInvoiceApi(invoice_account_id, invoice_id)
        }

    }

    const apiRouteStr = () => {
        if(props.estimateItem?.cancel_estimate_clicked) {
            return 'cancel';
        }

        if(props.estimateItem?.reopen_estimate_clicked) {
            return 'reopen';
        }

        if(props.estimateItem?.close_estimate_clicked) {
            return 'close';
        }

        if(props.estimateItem?.mark_as_approved) {
            return 'mark_approved';
        }

        if(props.estimateItem?.mark_as_rejected) {
            return 'mark_rejected';
        }
    }

    const callApis = () => {
        const estimate_id = props.estimateItem?.id;

        const apiStr = apiRouteStr();

        if(props.estimateItem?.cancel_estimate_clicked ||  props.estimateItem?.close_estimate_clicked || props.estimateItem?.reopen_estimate_clicked) {
            props.callApiForActionClicked(estimate_id, apiStr);
        }

        if(props.estimateItem?.mark_as_approved || props.estimateItem?.mark_as_rejected) {
            props.onMarkAsApprovedOrRejectedClick(apiStr);
        }

        if(props.estimateItem?.delete_estimate_clicked) {
            props.callDeleteEstimateApi(invoice_account_id, estimate_id);
        }
    }

    const onYesClick = (e) => {

        if((props.fromInvoiceListComponent || props.fromPreviewScreen) && !props.showEstimateScreen) {
            callCancelInvoiceApiOrConvertToDraftApiFromParent();
        }

        if(props.showEstimateScreen || props.fromEstimateListComponent) {
            callApis();
        }

        if(props.fromClientEstimatePreview) {
            const path = props.estimateItem.approve_estimate_clicked ? 'approve' : 'reject';
            props.onApproveOrRejectClick(path);
        }

        if(props.fromEntitiesComponent) {
            if(props.entityDelete && (!deleteText || deleteText !== 'delete')) {
                enqueueSnackbar("Please type delete to confirm", {variant: 'error'});
                return;
            } else {
                props.callArchiveRestoreDeleteCompanyApi();
            }
            //props.handleConfirmDialogClose();
        } 

        if(props.fromTransactionListComponent && props.transactionItem?.delete_transaction_clicked) {
            props.callDeleteTransactionApi(invoice_account_id, props.transactionItem.id);
        }

        if(props.fromTransactionListComponent && props.transactionItem?.delete_payment_clicked) {
            props.callDeleteTransactionApi(invoice_account_id, props.transactionItem.id);
        }
        
        //props.handleConfirmDialogClose();
        if(props.fromPaymentGateway) {
            props.callRevokeRazorPay();
        } 
    }

    const onNoClick = () => {
        props.handleConfirmDialogClose();
    }

    return (
        <Dialog fullScreen={false}
        open={props.openConfirmDialog}
        onClose={props.handleConfirmDialogClose}
        scroll="paper"
        classes={{
            paper: classes.MuiDialogpaper}}
        maxWidth="sm"
        fullWidth>
            {
                <DialogContent style={{padding:'16px'}}>
                    <Grid item container 
                        direction='column'
                        alignItems='center'
                        justifyContent='center'>
                        <Grid item className={classes.dialogActionImg}>
                            {displayIcon()}
                        </Grid>
                        <Grid item>
                            <Typography className={classes.dailogHeading}>
                                {displayHeading()}
                            </Typography>
                            <Typography variant='body1'>
                                {displayMessage()}
                            </Typography>
                        </Grid>
                        {props.invoiceItem?.recurring_invoice?.status === 'active' && 
                        !isInvoiceStatusCancelled(props.invoiceItem?.status) &&
                            <Grid item sm={12}>
                                <FormControlLabel control={
                                <Checkbox  
                                    onChange = {(e)=>{
                                        setCancelRecurringInvoice(e.target.checked)
                                    
                                    }}
                                    checked={cancelRecurringInvoice}
                                />
                                } 
                                label="Do you want to cancel recurring invoice as well?" />
                            </Grid>
                        }
                        {props.fromEntitiesComponent && props.tabValue === 'archived' && props.entityDelete 
                            &&
                            <Grid item style={{marginTop:"8px"}}>
                                <Typography variant='body2' style={{marginBottom:'-5px'}}>
                                    Type "delete" to confirm
                                </Typography>
                                <TextField fullWidth
                                    color='secondary'
                                    variant='outlined'
                                    value={deleteText}
                                    margin='dense'
                                    onChange={(e) => setDeleteText(e.target.value)}
                                />
                            </Grid>

                        }
                        <Grid item style={{marginTop:'16px'}}>
                            <Grid item container spacing={1}>
                                <Grid item>
                                    <Button 
                                        onClick={onYesClick}
                                        className={classes.yesButtonStyle}>
                                        Yes
                                        {props.apiLoading && <CircularProgress size={15} style={{color:"white", marginLeft:'8px'}}/>}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button 
                                        onClick={onNoClick}
                                        className={classes.noButtonStyle}>
                                        No
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            }
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
})

export default connect(mapStateToProps)(ShowConfirmDialog);