import {getAxios} from './axiosUtil';

export const createTemplateApi = (invoice_account_id, name, components, document_pdf) => {
    var data = new FormData();
    data.set('name', name);
    data.set('components', JSON.stringify(components));
    data.set('document_pdf', document_pdf);
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/esign/template/create`, data);
}

export const createDocumentApi = (invoice_account_id, name, components, document_pdf) => {
    var data = new FormData();
    data.set('name', name);
    data.set('components', JSON.stringify(components));
    data.set('document_pdf', document_pdf);
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/esign/document/create`, data);
}

export const updateTemplateApi = (invoice_account_id, template_id, name, components, document_pdf) => {
    var data = new FormData();
    data.set('name', name);
    data.set('components', JSON.stringify(components));
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/esign/template/${template_id}/update`, data);
}

export const updateDocumentApi = (invoice_account_id, document_id, name, components, template_id) => {
    var data = new FormData();
    data.set('name', name);
    data.set('components', JSON.stringify(components));
    if(template_id) {
        data.set('template_id', template_id);
    }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/esign/document/${document_id}/update`, data);
}

export const getAllDocumentTemplateListApi =  (invoice_account_id, apiPath, page, template_id, status) => {
    const param = {
        params: {
            page,
            template_id,
            status
        }
    }

    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/esign/${apiPath}/all`, param);
}

export const uploadUserSignatureApi = (signature_image) => {
    var data = new FormData();
    data.set('signature_image', signature_image);
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/user/signature`, data);
} 

export const getTemplateBySlugApi = (invoice_account_id, slug) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/esign/template/${slug}/get`);
}

export const getDocumentBySlugApi = (invoice_account_id, slug) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/esign/document/${slug}/get`);
}

export const createDocumentFromTemplateApi = (invoice_account_id, template_id, name, components) => {
    var data = new FormData();
    data.set('name', name);
    data.set('components', JSON.stringify(components));
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/esign/template/${template_id}/document/create`, data);
}

export const sendDocumentEmailApi = (invoice_account_id, document_id, email_array, subject, body) => {
    const data = {
        email_array,
        subject,
        body
    }

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/esign/document/${document_id}/send`, data);
}

export const downloadDocumentPdfApi = (invoice_account_id, document_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/esign/document/${document_id}/download`);
}

export const deleteDocumentOrTemplateApi = (invoice_account_id, id, apiPath) => {
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/esign/${apiPath}/${id}/delete`);
}

export const voidDocumentApi = (invoice_account_id, document_id) => {
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/esign/document/${document_id}/void`);
}

export const createTemplateFromDocumentApi = (invoice_account_id, name, components, document_pdf_path) => {
    var data = new FormData();
    data.set('name', name);
    data.set('components', JSON.stringify(components));
    data.set('document_pdf_path', document_pdf_path);
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/esign/template/path/create`, data);
}

export const revokeDocumentApi = (invoice_account_id, document_id) => {
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/esign/document/${document_id}/revoke`);
}

export const clientDocumentPreviewApi = (md5, encrypted_url, document_id) => {
    getAxios().defaults.headers.common['Challenge'] = md5;
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/client/${encrypted_url}/document/${document_id}/preview`);
}

export const getDocumentByIdApi = (invoice_account_id, document_id) => {
    //return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/esign/document/id/${document_id}/get`);
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/esign/document/${document_id}/get`);
}

export const requestOtpApi = (md5, document_id, email) => {
    const param = {
        params: {
            email
        }
    }

    getAxios().defaults.headers.common['Challenge'] = md5;
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/document/${document_id}/otp/request`, param);
}

export const verifyOtpApi = (md5, email, otp) => {
    const data = {
        email,
        otp
    }

    getAxios().defaults.headers.common['Challenge'] = md5;
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/otp/verify`, data);
}

export const signByMemberApi = (invoice_account_id, document_id, email, sign_image) => {
    var data = new FormData();
    data.set('email', email);
    data.set('sign_image', sign_image);
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/esign/document/${document_id}/member/sign`, data);
}

export const signByClientApi = (md5, document_id, email, sign_image, otp) => {
    var data = new FormData();
    data.set('email', email);
    data.set('otp', otp);
    data.set('sign_image', sign_image);

    getAxios().defaults.headers.common['Challenge'] = md5;
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/document/${document_id}/sign`, data);
}

export const clientDownloadDocumentPdfApi = (md5, encrypted_url, document_id) => {
    getAxios().defaults.headers.common['Challenge'] = md5;
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/client/${encrypted_url}/document/${document_id}/download`);
}