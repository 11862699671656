import { Button, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUnclaimedBillApi } from "../services/authService";
import { consoleToLog } from "../util/AppUtil";
import { useSnackbar } from 'notistack';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { formatAmount } from "../util/currencyUtil";

const UnclaimedBills = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [unclaimedBillList, setUnClaimedBillList]=useState([]);
    const [unclaimedGlobalBillList, setUnClaimedGlobalBillList]=useState([]);

    useEffect(()=>{
        getUnClaimedBills();
    },[])
    useEffect(()=>{
       if(props.deletedClaimedBill){
        console.log("useEffect UnclaimedBills: ", props.deletedClaimedBill)
        setUnClaimedBillList([...unclaimedBillList, props.deletedClaimedBill])
       }
    },[props.deletedClaimedBill])

    const getUnClaimedBills = async() => {
        try {
            const invoice_account_id = props.selectedAccount?.id;
            const client_id= props.invoiceObj?.client?.id || props.client?.id;
            const response = await getUnclaimedBillApi(invoice_account_id, client_id);
            const res = response.data;
            consoleToLog("Response getUnclaimedBillApi", res);
            setUnClaimedBillList(res);
            setUnClaimedGlobalBillList(res);

        } catch (e) {
            consoleToLog("Error getUnclaimedBillApi", e);
            e.response && e.response.data.message && enqueueSnackbar(e.response.data.message, {variant:'error'});
        }
    }

    const onClickUnclaimedBill = (e, ucItem) => {
        let  localUnclaimedBillList=unclaimedBillList;
        localUnclaimedBillList=localUnclaimedBillList.filter((item)=> item.id !== ucItem.id);
        setUnClaimedBillList([...localUnclaimedBillList]);
        props.addToClaimedBill(unclaimedGlobalBillList, ucItem);
    }

    return(
        <>
       { unclaimedBillList && unclaimedBillList.length>0 ? 
        <Grid item container direction='column' 
                alignItems='center' 
                className="save_invoice"
                style={{marginTop:'16px'}}
            >
                    <Grid item container direction="column" style={{position:'relative'}}>
                        {<Typography className='font_styles1' style={{marginTop: "-4px", marginBottom: "8px"}}>
                            Unclaimed Bills
                        </Typography>}
                        {
                            unclaimedBillList && unclaimedBillList.length>0 
                            && unclaimedBillList.map((item, index)=>{
                                let desc=item?.description ? item?.description : item.bill_number 
                                return(
                                    <>
                                    <Grid container spacing={1} style={{marginBottom: '6px'}}>
                                        <Grid item md={7} container 
                                            justifyContent="flex-start" 
                                            alignItems="center">
                                            <Tooltip title={desc}>
                                                <Typography variant="body1" style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                                                    {desc}
                                                </Typography>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={3} container justifyContent="center" alignItems="center">
                                            <Typography variant="body1">
                                                {formatAmount(item.total, props.invoiceObj?.currencies)}
                                            </Typography>
                                        </Grid>
                                        {!props.fromPreviewScreen && <Grid item md={!props.fromPreviewScreen ? 1 : 2} container justifyContent="center" alignItems="center">
                                            <Tooltip title="Add">
                                                <AddOutlinedIcon onClick={(e)=>onClickUnclaimedBill(e,item)} 
                                                    style={{width: '20px', height: '20px', marginRight: "2px",  cursor: 'pointer'}} />
                                            </Tooltip>
                                        </Grid>}
                                        <Grid item md={!props.fromPreviewScreen? 1 : 2} container justifyContent="center" alignItems="center">
                                            <Tooltip title="View">
                                                <VisibilityOutlinedIcon 
                                                    onClick={(e)=>{
                                                        props.handleEditPreviewBillDialogOpen(props.invoiceObj?.entity, true, item.id);
                                                    }} 
                                                    style={{width: '20px', height: '20px', cursor: 'pointer'}} />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                    </>
                                )
                            })
                        }
                    </Grid> 

            </Grid>

        : <></>
        }
        </>
    )
}


const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
});

export default connect(mapStateToProps, mapDispatchToProps)(UnclaimedBills);