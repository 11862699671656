import React, {useState, useEffect} from 'react';
import {Grid, Typography, Button, Dialog, TextField, Card, MenuItem, colors} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {makeStyles} from '@material-ui/core/styles';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import { useSnackbar } from 'notistack';
import { history } from '../../routers/AppRouter';
import {getAllDocumentTemplateListApi} from '../../services/documentService';
import { consoleToLog } from '../../util/AppUtil';
import { useIsMount } from '../useIsMount';

const useStyles = makeStyles((theme) => ({
    MuiDialogpaper: {
        width:'800px',
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    dialogTitle : {
        borderBottom:'1px solid #ccc', 
        padding:'14px 0px', 
        fontSize:'16px',
        fontWeight:'600',
    },
    cardStyle: {
        height:'150px',
        padding:'18px',
        display:'flex',
        flexDirection:'column',
        justifyContent:"center",
        alignItems:"center",
        cursor:'pointer',
        '&:hover': {
            background: 'rgba(0,0,0,0.1)'
        }
    },
    selectedBackgound: {
        background: 'rgba(0,0,0,0.1)'
    },
    flatIcons: {
        fontSize:'25px',
        color:'#007bfb'
    },
    docTextStyle: {
        fontSize:'13px',
        fontWeight:500,
        color: '#4a5668'
    },
    footerStyle: {
        padding: '16px',
        borderTop:'1px solid rgba(0,0,0,0.1)'
    },
    descpStyle: {
        fontSize: '11px',
        fontWeight:600,
        color: '#888',
        textAlign:'center',
        marginTop:'16px'
    },
    positionStyle: {
        position:'relative',
        top:'4px'
    }
}))


const SelectDocumentOrTemplateModal = ({ openSelectDocTempModal, handleSelectDocTempModalClose, handleDocumentModalOpen, selectedAccount }) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [uploadedPdf, setUploadedPdf] = useState(false);
    const [templateList, setTemplateList] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(-1);
    const [selectedItem, setSelectedItem] = useState(undefined);

    useEffect(() => {
        fetchDocumentOrTemplateList();
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(templateList && templateList.length === 0) {
                setSelectedItem('document');
            }
        }
    }, [templateList]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type === "application/pdf") {
            setUploadedPdf(e.target.files[0]);
        } else {
            enqueueSnackbar("Please select a valid PDF file.", {variant:'error'});
            return;
        }
    }

    const fetchDocumentOrTemplateList = async() => {
        const apiPath = 'templates';
        const invoice_account_id = selectedAccount.id;

        try {
            const response = await getAllDocumentTemplateListApi(invoice_account_id, apiPath)
            const res = response.data;
            consoleToLog("Response getAllDocumentTemplateListApi: ", res);

            setTemplateList(res);

        } catch(e) {
            consoleToLog("Error getAllDocumentTemplateListApi: ", e.response);
            if(e.response.data && e.response.data.message) {
                return;
            }
        }

    }

    const handleTemplateChange = (e) => {
        const value = e.target.value;
        setSelectedTemplate(value);
    }

    const onNextClick = () => {
        if(selectedItem === 'document') {
            if(uploadedPdf) {
                history.push('/document/create', {documentCreate:true, uploadedPdf});
                handleDocumentModalOpen();
                handleSelectDocTempModalClose();
            } else {
                enqueueSnackbar('Please upload a pdf to continue', {variant:'error'});
            }
        }

        if(selectedItem === 'template') {
            history.push('/document/create', {documentFromTemplate:true, selectedTemplate});
            handleDocumentModalOpen();
            handleSelectDocTempModalClose();
        }
    }

    return (
        <Dialog
            open={openSelectDocTempModal}
            //TransitionComponent={Transition}
            scroll="paper"
            classes={{
                paper: classes.MuiDialogpaper}}
            maxWidth="sm"
            fullWidth
            style={{scrollbarWidth:'none !important'}}
            onClose={handleSelectDocTempModalClose}>

                    <Grid item container   
                        justifyContent='space-between'
                        alignItems='center'className={classes.dialogTitle}>

                        <Grid item md={11}>        
                            <Typography style={{fontWeight:600, textAlign:'center'}}>
                                Create New
                            </Typography>
                        </Grid>

                        <Grid item md={1}>
                            <ClearIcon fontSize='small' onClick={handleSelectDocTempModalClose}
                            style={{cursor:'pointer', verticalAlign:'inherit'}}/>
                        </Grid>

                    </Grid>

                    <div style={{padding:'24px 16px'}}>

                        <Grid item container justifyContent='center' 
                            alignItems='center'
                            spacing={2}>

                            <Grid item md={5}>
                                <Card fullWidth className={`${classes.cardStyle} ${selectedItem === 'document' && classes.selectedBackgound}`} 
                                    onClick={() => setSelectedItem('document')}
                                    variant="outlined">
                                        
                                    <Grid item>
                                        <AddOutlinedIcon className={classes.flatIcons} />
                                    </Grid>

                                    <Grid item>
                                        <Typography className={classes.docTextStyle}>
                                            Document
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <Typography className={classes.descpStyle}>
                                            Create a document from scratch
                                        </Typography>
                                    </Grid>

                                </Card>
                            </Grid>

                            {templateList && templateList.length > 0 &&
                            <Grid item md={5}>
                                <Card fullWidth className={`${classes.cardStyle} ${selectedItem === 'template' && classes.selectedBackgound}`}
                                    onClick={() => setSelectedItem('template')}
                                    variant="outlined">

                                    <Grid item>
                                        <LibraryAddOutlinedIcon className={classes.flatIcons} style={{position:'relative', top:'5px'}} />
                                    </Grid>

                                    <Grid item>
                                        <Typography className={classes.docTextStyle} style={{position:'relative', top:'5px'}}>
                                            Document from template
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <Typography className={classes.descpStyle} style={{position:'relative', top:'4px'}}>
                                            Create a document from an existing template
                                        </Typography>
                                    </Grid>

                                </Card>
                            </Grid>}
                        </Grid>

                        <Grid item style={{marginTop:'24px'}}>
                            {
                                selectedItem === 'document' &&
                                <Grid item md={12}>

                                    <Typography variant='body1' style={{textAlign:'center'}}>
                                        Please upload a PDF to continue
                                    </Typography>
                                    <Grid item container justifyContent='center' 
                                        alignItems='center' 
                                        spacing={1}
                                        style={{marginTop:'8px'}}
                                        >
                                        <Grid item>
                                            <label>
                                                <Button size='large' component='div' variant='contained' color='secondary'>
                                                    Upload
                                                </Button>
                                                <input style={{display:'none'}}
                                                        type="file"
                                                        id="document_pdf"
                                                        accept=".pdf"
                                                        onChange={handleFileChange}
                                                    />
                                                </label>
                                        </Grid>
                                    </Grid>
                                    {
                                        uploadedPdf &&
                                        <Typography style={{fontSize:'13px', marginTop:'8px', color:'#888', textAlign:'center'}}>
                                            {uploadedPdf?.name}
                                        </Typography>
                                    }

                                </Grid>
                            }

                            {
                                selectedItem === 'template' && templateList && templateList.length > 0 &&
                                <Grid item md={12}>

                                    <Grid item container direction='column' alignItems='center' justifyContent='center'>

                                        <Typography variant='body1' style={{textAlign:'center'}}>
                                            Please Select template to continue
                                        </Typography>

                                        <Grid item md={12}>
                                            <TextField fullWidth
                                                variant='outlined'
                                                margin='dense'
                                                select
                                                value={selectedTemplate}
                                                onChange={handleTemplateChange}
                                                style={{width:'350px'}}
                                            >
                                                <MenuItem value={-1}>Select Template</MenuItem>
                                                {templateList && templateList.length > 0 &&
                                                templateList.map((template) => {
                                                        return <MenuItem key={template?.id} value={template}>
                                                                    {template.name}
                                                                </MenuItem>
                                                    })
                                                }
                                            </TextField>
                                        </Grid>

                                    </Grid>

                                </Grid>
                            }
                        </Grid>
                    </div>

                    <Grid item className={classes.footerStyle}>
                        <Grid item container alignItems='center' 
                            spacing={1}
                            justifyContent='flex-end'
                            >
                            <Grid item>
                                <Button variant='outlined' color='primary'
                                    onClick={handleSelectDocTempModalClose}
                                    style={{width:'100px'}}>
                                    Cancel
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button variant='contained' color='primary'
                                    onClick={onNextClick}
                                    style={{width:'100px'}}>
                                    Next
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
            
        </Dialog>
    );
}

export default SelectDocumentOrTemplateModal;