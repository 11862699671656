import React, {useEffect, useState, useLayoutEffect} from 'react';
import {Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, 
    Button, CircularProgress, Tooltip, ClickAwayListener, Link} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {getAllDocumentTemplateListApi} from '../../services/documentService';
import { consoleToLog, AccessLevel, DocumentStatusColor } from '../../util/AppUtil';
import { useIsMount } from '../useIsMount';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';
import FolderIcon from '@material-ui/icons/Folder';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import BlockIcon from '@material-ui/icons/Block';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DocumenFilterDrawer from './DocumenFilterDrawer';
import { usePropsContext } from '../context';
import useRestoreComponentState from '../useRestoreComponentState';
import ConfirmDialog from './ConfirmDialog';
import {deleteDocumentOrTemplateApi, voidDocumentApi, downloadDocumentPdfApi} from '../../services/documentService';
import { useSnackbar } from 'notistack';
import {DocumentStatusName} from '../../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        marginTop:'1%'
    },
    itemListContainer : {
        background:'white', 
        minWidth:"100%", 
        position:'relative',
        cursor:'pointer',
        '& .action__icon' : {
            padding:'2px 4px 2px 4px',
            border:'1px solid white',
            borderRadius:'4px',
            cursor:'pointer'
        },
        '&:hover .action__icon' : {
            border:'1px solid #ccc'
        },
        '&:hover':{
            backgroundColor:'#f7fafc',
        },
        '& .more_actionButton' : {
            visibility:'hidden',
            width:'150px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
        },
        '& .more_actionButton1' : {
            visibility:'hidden',
            width:'120px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
        },
        '& .more_actionButton2' : {
            visibility:'hidden',
            width:'180px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
            display:'flex',
            alignItems:'center'
        },
        '&:hover .more_actionButton' : {
            visibility:'visible'
        },
        '&:hover .more_actionButton1' : {
            visibility:'visible'
        },
        '&:hover .more_actionButton2' : {
            visibility:'visible'
        },
    },
    actionsIcons: {
        color: '#b5bbc2',
        fontSize: '18px',
    },
    actionIconContainer : {
        marginRight:'auto',
        display:'flex',
        flexFlow:'row',
        flexGrow:1,
        position: "absolute",
        right: "26px",
        [theme.breakpoints.down(1500)] :{
            marginLeft:'-16px',
        },
        [theme.breakpoints.down(1450)] :{
            marginLeft:'-32px',
        },
        [theme.breakpoints.down(1400)] :{
            marginLeft:'-48px',
        },
        [theme.breakpoints.down(1320)] :{
            marginLeft:'-56px',
        },
        [theme.breakpoints.down(1290)] :{
            marginLeft:'-68px',
        },
    },
    flatIcons : {
        fontSize:'15px', 
        marginRight:'6px',
        display:'flex',
        alignItems:'center'
    },
    docName: {
        marginLeft:'8px'

    },
    cellSize:{
        padding:'10px 16px'
    },
    statusIndicator: {
        marginLeft: '-8px',
    },
    folderIconStye: {
        color:'#F8D775',
        '&:hover' : {
            opacity:"0.8"
        }
    },
    backIcon: {
        marginRight:'8px',
        cursor:'pointer',
        color:'darkgrey',
        position:'relative',
        top:'4px',
        '&:hover' : {
            opacity:"0.8"
        },
    }
}));

// Add this function at the beginning of your component or in a separate utility file
const updateDocumentItem = (documentItem, updatedDocumentObj) => {
    
    return {
        ...documentItem,
        can_edit: updatedDocumentObj.can_edit,
        can_void: updatedDocumentObj.can_void,
        can_revoke: updatedDocumentObj.can_revoke,
        id: updatedDocumentObj.id,
        name: updatedDocumentObj.name,
        status: updatedDocumentObj.status,
        template_id: updatedDocumentObj.template_id,
        url_key: updatedDocumentObj.url_key,
        slug: updatedDocumentObj.slug,
    };
};

const updateTemplateItem = (documentItem, updatedDocumentObj) => {
    
    return {
        ...documentItem,
        id: updatedDocumentObj.id,
        name: updatedDocumentObj.name,
    };
};

const DocumentList = ({value, TabValues, storeComponentState, ...props }) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();
    
    const [documentList, setDocumentList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [nextPage, setNextPage] = useState(false);
    const [total, setTotal] = useState(0);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [menuId, setMenuId] = useState(undefined);
    const [templateId, setTemplateId] = useState(undefined);
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
    const [id, setId] = useState(props.selectedAccount?.id);

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [documentItem, setDocumentItem] = useState(undefined);
    const [apiLoading, setApiLoading] = useState(false);

    const { documentsComponentState, setDocumentsComponentState, updatedDocumentObj, setUpdatedDocumentObj } = usePropsContext();

    const { setScrollContainer, saveState, restoreState } = useRestoreComponentState(documentsComponentState, setDocumentsComponentState);
    const restoredState = restoreState();

    useEffect(() => {
        if (Object.keys(restoredState).length === 0) {
            fetchDocumentOrTemplateList();
        }
    }, [id]);

    useLayoutEffect(() => {
        
        if (Object.keys(restoredState).length > 0) {
            setTemplateId(restoredState.templateId);
            setDocumentList(restoredState.itemList);
            setPage(restoredState.page);
            setTotal(restoredState.total);
        } 
    }, [restoredState]);

    useEffect(() => {
        if(!isMount) {
            pageReset();
        }
    }, [value]);

    useEffect(() => {
        if (!isMount) {
            if (isMoreLoading) {
                //consoleToLog('More Loading Useffect');
                //consoleToLog('isMore loading api called')
                fetchDocumentOrTemplateList();
                // if(props.fromClientDetails && props.clientInfo) {
                //     getClientInvoices();
                // } else {
                //     getInvoiceList();
                // }
            }
        }
    }, [isMoreLoading]);

    useEffect(() => {
        if (!isMount) {
            if (Object.keys(restoredState).length === 0) {
                //consoleToLog('filter changed');
                fetchDocumentOrTemplateList();
            }
        }
    }, [templateId, value, props.selectedStatusDocumentFilter]);

    useEffect(() => {
        if(!isMount) {
            setId(props.selectedAccount?.id);
            setPage(1);
        }
    }, [props.selectedAccount?.id]);

    useEffect(() => {
        if(!isMount) {
            if(updatedDocumentObj && documentList && documentList.length > 0) {
                const documentObjPresent = documentList.find((documentItem) => documentItem.id === updatedDocumentObj?.id);
                if(documentObjPresent) {
                    const updatedDocumentArr = documentList.map((documentItem) => (
                            documentItem.id === updatedDocumentObj?.id ? 
                            value === TabValues.DOCUMENTS ? updateDocumentItem(documentItem, updatedDocumentObj) :
                            updateTemplateItem(documentItem, updatedDocumentObj)
                            : documentItem
                    ));
                    setDocumentList([...updatedDocumentArr]);
                    setUpdatedDocumentObj(undefined);
                } else {
                    //setProposalList(oldInvoices => [updatedInvoiceObj, ...oldInvoices]);
                    setUpdatedDocumentObj(undefined);
                }
            }
        }
    }, [updatedDocumentObj, documentList]);

    const pageReset = () => {
        setPage(1);
    }

    let count = 0;
    if (props.selectedStatusDocumentFilter.length < 4) {
        count = count + 1
    }

    const {selectedAccount} = props;
    const invoice_account_id = selectedAccount.id;
    const {access_level} = selectedAccount;
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;

    const fetchDocumentOrTemplateList = async() => {
        const apiPath = value === TabValues.DOCUMENTS ? 'documents' : 'templates';
        const template_id = value === TabValues.DOCUMENTS ? templateId : undefined;
        const pageNo = value === TabValues.DOCUMENTS ? page : undefined;
        const status =  value === TabValues.DOCUMENTS ? props.selectedStatusDocumentFilter?.map((status) => status)?.join() : undefined;


        if(!isMoreLoading) setLoading(true);
        try {
            const response = await getAllDocumentTemplateListApi(invoice_account_id, apiPath, pageNo, template_id, status)
            const res = response.data;
            consoleToLog("Response getAllDocumentTemplateListApi: ", res);

            let items = value === TabValues.DOCUMENTS ? res.templates.concat(res.documents) : res;

            setLoading(false);

            if(value === TabValues.DOCUMENTS) {
                if(isMoreLoading) {
                    const newItems = documentList.concat([...res.documents]);
                    setDocumentList(newItems);
                    setPage(page + 1);
                    setNextPage(res.next_page);
                    setIsMoreLoading(!isMoreLoading);
                } else {
                    setNextPage(res.next_page);
                    setPage(page + 1);
                    setDocumentList(items);
                    setTotal(res.total);
                }
            } else {
                setDocumentList([...items]);
            }

        } catch(e) {
            consoleToLog("Error getAllDocumentTemplateListApi: ", e.response);
            setLoading(false);
            if(e.response.data && e.response.data.message) {
                return;
            }
        }

    }

    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {nextPage && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    const onEditButtonClick = (e, docObj) => {
        e.stopPropagation();
        storeComponentState(true, documentList, page, total, docObj, templateId, false)
    }

    const onPreviewClick = (e, docObj) => {
        e.stopPropagation();
        storeComponentState(false, documentList, page, total, docObj, templateId, false)
    }

    const closeMoreOptionsMenu = () => {
        setIsActive(false);
        setMenuId('');
    }

    const onFolderIconClick = (e, doc) => {
        e.stopPropagation();
        documentsComponentState && setDocumentsComponentState();
        setTemplateId(doc.id);
        pageReset();
    }

    const handleOpenFilterDrawer = () => {
        setOpenFilterDrawer(true);
    }

    const handleCloseFilterDrawer = () => {
        setOpenFilterDrawer(false);
    }

    const handleConfirmDialogOpen = (e, document, obj) => {
        e.stopPropagation();
        setOpenConfirmDialog(true);
        let docObj = {
            ...obj,
            ...document
        }
        setDocumentItem(docObj)
        closeMoreOptionsMenu();
    }

    const handleConfirmDialogClose = () => {
        setOpenConfirmDialog(false);
        setDocumentItem(undefined);
    }

    const callDeleteApi = async(id) => {
        const apiPath = value === TabValues.DOCUMENTS ? 'document' : 'template';

        setApiLoading(true);

        try {
            const response = await deleteDocumentOrTemplateApi(invoice_account_id, id, apiPath)
            const res = response.data;
            consoleToLog("Response deleteDocumentOrTemplateApi: ", res);

            setApiLoading(false);

            const updatedList = documentList.filter((item) => item.id !== id);
            setDocumentList(updatedList);
            handleConfirmDialogClose();
            enqueueSnackbar(apiPath === 'document' ? `Document deleted successfully` : 'Template deleted successfully', {variant:'success'});


        } catch(e) {
            consoleToLog("Error deleteDocumentOrTemplateApi: ", e.response);
            setApiLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const callVoidApi = async(id) => {
        const apiPath = value === TabValues.DOCUMENTS ? 'document' : 'template';

        setApiLoading(true);

        try {
            const response = await voidDocumentApi(invoice_account_id, id, apiPath)
            const res = response.data;
            consoleToLog("Response voidDocumentApi: ", res);

            setApiLoading(false);
            const updatedList = documentList.map((item) => {
                if(item.id === res.id) {
                    item = res;
                }
                return item;
            });
            setDocumentList(updatedList);
            enqueueSnackbar('Document void successfully', {variant:'success'});

            handleConfirmDialogClose();


        } catch(e) {
            consoleToLog("Error voidDocumentApi: ", e.response);
            setApiLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const onDownloadPdfClick = async(e, document_id) => {
        e.stopPropagation();
        
        try {
            const response = await downloadDocumentPdfApi(invoice_account_id, document_id);
            const res = response.data;
            consoleToLog("Response downloadDocumentPdfApi", res);

            document.getElementById('document_download').href = res.pdf_signed_url;
            document.getElementById('document_download').click();

        } catch(e) {
            consoleToLog("Error downloadDocumentPdfApi", e);
            enqueueSnackbar('Error downloading pdf!!!', {variant:'error'});
            if(e.response.data && e.response.data.message) {
                return;
            }
        }
    }

    const onTableRowClick = (e, doc) => {
        if(value === TabValues.DOCUMENTS && doc.status) { 
            onPreviewClick(e, doc);
        } else if(value === TabValues.DOCUMENTS && !doc.status) {
            onFolderIconClick(e, doc);
        }
    }

    return (
        <div>
            <Grid item xs={12}>
                    
                    <Paper variant='standard' elevation={0} style={{width:'100%'}}>
                    {loading ?
                        <Grid item container justifyContent='center'>
                                <CircularProgress size={25} style={{margin:'16px 0px 0px 0px'}}/>
                        </Grid>
                    :
                    <TableContainer style={{overflow:'visible', height:'100%'}}>
                        <Table>
                        <TableHead style={{position:'sticky', top:'0px', zIndex:99, background:'#f7f7f7'}}>
                            <TableRow>
                                <TableCell className='tableheading_style'>
                                    <Grid item container alignItems='center'>
                                        {templateId &&
                                        <Grid item>
                                            <ArrowBackIcon className={classes.backIcon}
                                                onClick={(e) => onFolderIconClick(e, '')}
                                            />
                                        </Grid>}

                                        <Grid item>
                                                Name
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                {value === TabValues.DOCUMENTS && 
                                <TableCell className='tableheading_style'>
                                        Status
                                </TableCell>}
                                <TableCell className='tableheading_style' align='right'>
                                    {value === TabValues.DOCUMENTS &&
                                    <Tooltip title="Filters" arrow>
                                        <Button
                                            onClick={handleOpenFilterDrawer}  
                                            className={classes.icons}
                                            >
                                            {<img src='/images/filter_icon.svg' 
                                            className={(count && count > 0) ? `filter_icon active` : 'filter_icon'}/>}
                                            {/* <FilterListOutlinedIcon className={`table_header_icons ${count && count > 0 && 'filter_acitve'}`}/> */}
                                            {   count && count > 0 ?
                                                <div className='filter__dot'></div> : <div></div>
                                            }
                                        </Button>
                                    </Tooltip>}

                                </TableCell>
                            </TableRow>
                        </TableHead>
                        
                        <TableBody>
                            {
                            documentList && documentList.length > 0 ? documentList.map((doc) => {
                                return (
                                    <TableRow hover  key={doc.id} className={classes.itemListContainer}
                                        onClick={(e) => onTableRowClick(e, doc)}
                                        >
                                        
                                        <TableCell className={`tablebody_style ${classes.cellSize}`}>
                                            {value === TabValues.DOCUMENTS ?
                                            <Grid item container alignItems='center'>
                                                <Grid item>
                                                    {!doc.status ? <FolderIcon className={classes.folderIconStye}
                                                        onClick={(e) => onFolderIconClick(e, doc)}
                                                    /> : <InsertDriveFileOutlinedIcon />}
                                                </Grid>

                                                <Grid item>
                                                    <Typography variant='subtitle1' className={classes.docName}>
                                                        {doc.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Typography variant='subtitle1'>
                                                {doc.name}
                                            </Typography>
                                            
                                            }
                                        </TableCell>
                                        
                                        {value === TabValues.DOCUMENTS &&
                                            <TableCell className={`tablebody_style ${classes.cellSize}`}>
                                                {doc.status ?
                                                <Grid item container alignItems='center'>
                                                    <div className={`status_style ${classes.statusIndicator}`} style={{background: DocumentStatusColor(doc.status)}}>
                                                    </div>
                                                    <Tooltip title={DocumentStatusName(doc.status)} arrow>
                                                        <Typography 
                                                        style={{
                                                            color:DocumentStatusColor(doc.status?.toLowerCase()), 
                                                            fontSize: "10px",
                                                        }}>
                                                            {DocumentStatusName(doc.status)}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>
                                                :
                                                ''
                                                }
                                            </TableCell>
                                        }

                                        <TableCell className={`tablebody_style ${classes.cellSize}`} 
                                            align='right'>
                                                <Grid item
                                                    style={{display:'flex',
                                                            alignItems:'center',
                                                            justifyContent:'center',
                                                            marginLeft:'13px',
                                                            position:'relative'
                                                            }}
                                                >   
                                                    

                                                    {value === TabValues.DOCUMENTS && doc.status  
                                                    ?
                                                    <div className={classes.actionIconContainer}>
                                                        <Button item className="more_actionButton1"
                                                            onClick={(e) => onPreviewClick(e, doc)}> 
                                                                <VisibilityOutlinedIcon fontSize='small'  
                                                                className={`more_actionsIcons ${classes.actionsIcons}`}/> 
                                                            <span> Preview </span>
                                                        </Button>
                                                        {doc.can_edit && !doc.can_revoke &&
                                                        <Button 
                                                            onClick={(e) => onEditButtonClick(e, doc)}
                                                            className='more_actionButton1 action__icon'>
                                                            <i className={`flaticon-edit ${classes.flatIcons}`}></i>
                                                            Edit
                                                        </Button>
                                                        }

                                                        {doc.can_revoke &&
                                                        <Button 
                                                            onClick={(e) => onEditButtonClick(e, doc)}
                                                            className='more_actionButton2 action__icon'>
                                                            <i className={`flaticon-edit ${classes.flatIcons}`}></i>
                                                            Revoke And Edit
                                                        </Button>
                                                        }
                                                    </div>
                                                    :
                                                    value === TabValues.TEMPLATES &&
                                                    <div className={classes.actionIconContainer} style={{right:0}}>
                                                        <Button 
                                                            onClick={(e) => onEditButtonClick(e, doc)}
                                                            className='more_actionButton1 action__icon'>
                                                            <i className={`flaticon-edit ${classes.flatIcons}`}></i>
                                                            Edit
                                                        </Button>
                                                        <Button item className="more_actionButton1"
                                                            onClick={(e) => handleConfirmDialogOpen(e, doc, {delete_temp_clicked: true})}> 
                                                                <DeleteOutlineIcon fontSize='small'  
                                                                className={`more_actionsIcons ${classes.actionsIcons}`}/> 
                                                            <span> Delete </span>
                                                        </Button>
                                                    </div>
                                                    }
                                                    {value === TabValues.DOCUMENTS && doc.status &&
                                                    <div style={{position: "absolute", right: "0px"}}>
                                                        <Tooltip title="More Actions" arrow>  
                                                            <i className="flaticon-more action__icon"  
                                                            onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setIsActive(!isActive);
                                                                    setMenuId(doc.id);
                                                                }}
                                                            style={{fontSize:'15px', position:'relative'}}></i>
                                                        </Tooltip>
                                                        <>    
                                                            {doc.id === menuId &&
                                                            <ClickAwayListener onClickAway={closeMoreOptionsMenu}>
                                                                <Grid item className={`menu ${isActive ? `active ${classes.marginDropdown}` : "inactive"}`}>
                                                                    <ul>

                                                                        <li onClick={(e) => onDownloadPdfClick(e, doc.id)}>
                                                                            <a  style={{display:'flex', alignItems:'center'}}>
                                                                                <CloudDownloadOutlinedIcon fontSize='small'  
                                                                                className='more_actionsIcons'/>
                                                                                Download
                                                                            </a>
                                                                            <Link
                                                                                style={{ display: "none" }}
                                                                                id='document_download'
                                                                                target="_blank"
                                                                                onClick={(e) => e.stopPropagation()}
                                                                                download
                                                                            ></Link>
                                                                        </li>

                                                                        {doc.can_void &&
                                                                        <li onClick={(e) => handleConfirmDialogOpen(e, doc, {void_doc_clicked: true})}>
                                                                            <a  style={{display:'flex', alignItems:'center'}}>
                                                                                <BlockIcon fontSize='small'  className='more_actionsIcons'/>
                                                                                Void
                                                                            </a>
                                                                        </li>}

                                                                        <li onClick={(e) => handleConfirmDialogOpen(e, doc, {delete_doc_clicked: true})}>
                                                                            <a style={{display:'flex', alignItems:'flex-start'}}>
                                                                                <DeleteOutlineIcon fontSize='small'  className='more_actionsIcons'/>
                                                                                Delete
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </Grid>
                                                                </ClickAwayListener>
                                                                }
                                                        </>
                                                    </div>
                                                    }
                                            </Grid>
                                            
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                            :
                            <TableRow hover className={classes.invoiceListHover}>
                                <TableCell colSpan={4} align='center'>
                                    {value === TabValues.DOCUMENTS ? "No Documents Found" : "No Templates Found"}
                                </TableCell>
                            </TableRow>
                            
                            }
                        </TableBody>
                        </Table>
                    </TableContainer>}
                    {!loading && loadMoreLogic()}
                </Paper>
            </Grid>

            {openFilterDrawer &&
                <DocumenFilterDrawer openFilterDrawer={openFilterDrawer}
                    handleCloseFilterDrawer={handleCloseFilterDrawer}
                    pageReset={pageReset}
                />
            }

            {
                openConfirmDialog &&
                <ConfirmDialog openConfirmDialog={openConfirmDialog}
                    handleConfirmDialogClose={handleConfirmDialogClose}
                    callDeleteApi={callDeleteApi}
                    documentItem={documentItem}
                    apiLoading={apiLoading}
                    fromDocumentList={true}
                    callVoidApi={callVoidApi}
                />
            }

        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    selectedStatusDocumentFilter: state.documentFilters.selectedStatusDocumentFilter
})

export default connect(mapStateToProps)(DocumentList);