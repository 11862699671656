import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Grid, Typography, Button, Box, Container, CircularProgress, Link } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import SendIcon from '@material-ui/icons/Send';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DroppedItemsPreview from './DroppedItemsPreview';
import { trackGAEvent } from '../../util/AppUtil';
import SendEmailComponent from '../SendEmailComponent';
import {sendDocumentEmailApi} from '../../services/documentService';
import { consoleToLog } from '../../util/AppUtil';
import {useSnackbar} from 'notistack';
import {downloadDocumentPdfApi} from '../../services/documentService';
import ConfirmDialog from './ConfirmDialog';
import {deleteDocumentOrTemplateApi, voidDocumentApi} from '../../services/documentService';
import BlockIcon from '@material-ui/icons/Block';
import { history } from '../../routers/AppRouter';
import ActivityFeed from '../ActivityFeed';
import AllActivityFeedDrawer from '../AllActivityFeedDrawer';

// Configure PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const DocumentPreview = ({droppedItems, uploadedPdf, originalDimension, setOriginalDimension, setShowCreateEditDocumentScreen, 
    setShowPreviewDocumentScreen, selectedAccount, user, showSendEmail, setShowSendEmail, document_id, setDocumentObj, documentObj, 
    onCloseDocumentModal, setUpdatedDocumentObj, setUploadedPdf}) => {

    const containerRef = useRef(null);
    const {enqueueSnackbar} = useSnackbar();

    const [numPages, setNumPages] = useState();

    const [downloadApiLoading, setDownloadApiLoading] = useState(false);
    const [pageHeight, setPageHeight] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);
    const [sendEmailLoading, setSendEmailLoading] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);
    const [openActivityDrawer, setOpenActivityDrawer] = useState(false);

    const invoice_account_id = selectedAccount?.id


    // Add useEffect to measure container width
    useLayoutEffect(() => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.offsetWidth);
        }

        // Optional: Add resize listener if container size might change
        const handleResize = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [containerRef.current]);

    const onDocumentLoadSuccess = (pdfObj) => {
        const {numPages} = pdfObj
        setNumPages(numPages);
    }

    const onSendEmailClick = () => {
        setShowSendEmail(true);
    }

    const onEditClick = () => {
        if(documentObj?.can_revoke) {
            history.push(`/document/${documentObj?.slug}/edit`, {fromRevoke: true, selectedDoc: documentObj})
        } else {
            setUploadedPdf(documentObj?.pdf_path_url);
            history.push(`/document/${documentObj?.slug}/edit`);
            setShowCreateEditDocumentScreen(true)
            setShowPreviewDocumentScreen(false);
        }
    }

     const onShowMoreActivities = ()=>{
        setOpenActivityDrawer(true);
    }

     const handleCloseActivityDrawer = ()=>{
        setOpenActivityDrawer(false);
    }

    const showButtonsUi = () => {
        return (
            <Grid>
                <Grid item container direction='column' 
                    className="proposal_preview"
                >
                    <Button fullWidth 
                        onClick={() => {
                            trackGAEvent(selectedAccount.name, 'Document Preview Screen - Send button clicked', `${user.firstname} ${user.lastname}`);
                            onSendEmailClick()
                        }}
                        variant="contained"
                        //disabled={accessLevelViewer}
                        className={`button_purple`}>
                        <SendIcon fontSize='small'
                        style={{marginRight:'5px', fontSize:'16px'}} />
                            Send Email
                    </Button>

                    {(documentObj?.can_edit || documentObj?.can_revoke) &&
                    <Button fullWidth
                        onClick={onEditClick}
                        //className='preview_action_btns'
                        //disabled={accessLevelViewer}
                        variant="outlined" color='secondary' >
                        <EditOutlinedIcon fontSize='small'
                            style={{marginRight:'5px', fontSize:'15px'}} />
                            {documentObj?.can_revoke ? 'Revoke And Edit' : 'Edit'}
                    </Button>}

                    <Button fullWidth
                        onClick={onDownloadPdfClick}
                        className='preview_action_btns'
                        variant="outlined" color='secondary'>
                        {downloadApiLoading ? <CircularProgress size={15} color='secondary' style={{marginRight:'5px'}}/> 
                            :<PictureAsPdfIcon fontSize='small'
                            style={{marginRight:'5px', fontSize:'15px'}} />}
                            Download
                            <Link
                                style={{ display: "none" }}
                                id="document_download"
                                target="_blank"
                                onClick={(e) => e.stopPropagation()}
                                download
                            ></Link>
                    </Button>

                    {documentObj?.can_void &&
                    <Button fullWidth
                        onClick={(e) => handleConfirmDialogOpen(e, {delete_doc_clicked:true})}
                        className='preview_action_btns'
                        variant="outlined" color='secondary'>
                            <BlockIcon fontSize='small'
                            style={{marginRight:'5px', fontSize:'15px'}} />
                            Void
                    </Button>
                    }

                    <Button fullWidth
                        onClick={(e) => handleConfirmDialogOpen(e, {delete_doc_clicked:true})}
                        //className='preview_action_btns'
                        //disabled={accessLevelViewer}
                        variant="outlined" color='secondary' >
                        <DeleteOutlineIcon fontSize='small'
                            style={{marginRight:'5px', fontSize:'15px', color:'red'}} />
                            Delete
                    </Button>

                </Grid>
                
            </Grid>
        )
    }

    const callsendEmailDocumentApi = async(email_array, email_json) => {

        const {subject, body} = email_json;
        setSendEmailLoading(true);
        
        try {
            const response = await sendDocumentEmailApi(invoice_account_id, document_id, email_array, subject, body);
            const res = response.data;
            consoleToLog("Response sendDocumentEmailApi", res);
            setDocumentObj({...res});
            setUpdatedDocumentObj({...res});
            setSendEmailLoading(false);

            enqueueSnackbar('Email sent successfully!!', {variant:'success'});

        } catch(e) {
            consoleToLog("Error sendDocumentEmailApi", e);
            setSendEmailLoading(false);
            enqueueSnackbar('Error sending email', {variant:'error'});
            if(e.response.data && e.response.data.message) {
                return;
            }
        }
    }

    const onDownloadPdfClick = async() => {

        setDownloadApiLoading(true);
        
        try {
            const response = await downloadDocumentPdfApi(invoice_account_id, document_id);
            const res = response.data;
            consoleToLog("Response downloadDocumentPdfApi", res);
            setDownloadApiLoading(false);

            document.getElementById('document_download').href = res.pdf_signed_url;
            document.getElementById('document_download').click();

        } catch(e) {
            consoleToLog("Error downloadDocumentPdfApi", e);
            setDownloadApiLoading(false);
            enqueueSnackbar('Error downloading pdf', {variant:'error'});
            if(e.response.data && e.response.data.message) {
                return;
            }
        }
    }

    const handleConfirmDialogOpen = (e, obj) => {
        e.stopPropagation();
        setOpenConfirmDialog(true);
        let docObj = {
            ...documentObj,
            ...obj
        }
        setDocumentObj(docObj)
    }

    const handleConfirmDialogClose = () => {
        setOpenConfirmDialog(false);
    }

    const callDeleteApi = async(id) => {

        setApiLoading(true);

        try {
            const response = await deleteDocumentOrTemplateApi(invoice_account_id, id, 'document')
            const res = response.data;
            consoleToLog("Response deleteDocumentOrTemplateApi: ", res);

            setApiLoading(false);

            handleConfirmDialogClose();
            enqueueSnackbar(`Document deleted successfully`, {variant:'success'});
            onCloseDocumentModal();


        } catch(e) {
            consoleToLog("Error deleteDocumentOrTemplateApi: ", e.response);
            setApiLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const callVoidApi = async(id) => {

        setApiLoading(true);

        try {
            const response = await voidDocumentApi(invoice_account_id, id, 'document')
            const res = response.data;
            consoleToLog("Response voidDocumentApi: ", res);

            setApiLoading(false);
            setDocumentObj({...res});
            
            enqueueSnackbar('Document void successfully', {variant:'success'});
            setUpdatedDocumentObj({...res});
            handleConfirmDialogClose();


        } catch(e) {
            consoleToLog("Error voidDocumentApi: ", e.response);
            setApiLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }


    return (
        <Container maxWidth='xl'>
            <Grid item container alignItems='center' spacing={2}
                style={{padding:'16px 16px 0px 16px'}}>
                <Grid item>
                    <div onClick={onCloseDocumentModal} className='edit_prop_icon_btn'>
                        <img src='/images/back_arrow_background.svg' />
                    </div>
                </Grid>

                <Grid item>
                    <Typography className='prop_title'>
                        {documentObj?.name}
                    </Typography>
                </Grid>
            
            </Grid>
            <div style={{display:'flex', justifyContent:'center'}}>
                <Box width={showSendEmail ? '57%' :'60%'} position='relative'>
                <div className="pdf_container_scroll" style={{justifyContent:'space-between'}} ref={containerRef}>
                    <Document file={uploadedPdf} onLoadSuccess={onDocumentLoadSuccess}>
                    {numPages > 0 && Array.from(new Array(numPages), (el, index) => (
                        <div 
                            key={`page_${index + 1}`}
                            style={{ position: 'relative' }}
                        >
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                renderTextLayer={false}
                                scale={1}
                                onRenderSuccess={(page) => {
                                    if(pageHeight === 0 && index === 0) {
                                        const height = page.height;
                                        setPageHeight(height);
                                    }
    
                                    if(originalDimension?.width === 0 && index === 0) {
                                        setOriginalDimension({
                                            width: page.originalWidth,
                                            height: page.originalHeight
                                        });
                                    }
                                }}
                            />
                            <div className="pdf-overlay" style={{ 
                                position: 'absolute', 
                                top: 0, 
                                left: 0,
                                width: '100%',
                                height: '100%',
                                pointerEvents: 'none'
                            }}>
                                {containerWidth > 0 && pageHeight > 0 && originalDimension.width > 0 && originalDimension.height > 0 && 
                                droppedItems
                                    .filter(item => item?.coordinates?.page === index + 1)
                                    .map((item) => (
                                        <DroppedItemsPreview
                                            key={item.id}
                                            item={item}
                                            pdfWidth={containerWidth}
                                            pdfHeight={pageHeight}
                                            originalDimension={originalDimension}
                                        />
                                    ))}
                            </div>
                        </div>
                    ))}
                    </Document>
                    </div>
                </Box>

                <Box width={showSendEmail ? '40%' : '25%'} style={{marginTop:'24px'}}>
                    {showSendEmail ?
                        <SendEmailComponent fromDocumentDetails={true}
                            callsendEmailDocumentApi={callsendEmailDocumentApi}
                            sendEmailLoading={sendEmailLoading}
                            setShowSendEmail={setShowSendEmail}
                            droppedItems={droppedItems}
                            documentObj={documentObj}
                        />
                        :
                        showButtonsUi()}
                       {documentObj?.activities && 
                            documentObj?.activities.length>0 
                            && <ActivityFeed activityFeeds={documentObj?.activities}
                                    onShowMoreActivities={onShowMoreActivities}
                                />
                        }
                </Box>
            </div>

            {
                openConfirmDialog &&
                <ConfirmDialog openConfirmDialog={openConfirmDialog}
                    handleConfirmDialogClose={handleConfirmDialogClose}
                    callDeleteApi={callDeleteApi}
                    documentItem={documentObj}
                    apiLoading={apiLoading}
                    fromDocumentPreview={true}
                    callVoidApi={callVoidApi}
                />
            }
            {openActivityDrawer && <AllActivityFeedDrawer openActivityDrawer={openActivityDrawer}
                handleCloseActivityDrawer={handleCloseActivityDrawer}
                obj={documentObj}
                type="esign"
            />}
        </Container>
    );
}

export default DocumentPreview;