import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {connect} from 'react-redux'; 
import {trackGAEvent} from '../../util/AppUtil';
import {addSelectedStatusToBillFilter, addSelectedStatusToEstimateFilter, removeSelectedStatusFromBillFilter, removeSelectedStatusFromEstimateFilter} from '../../actions/invoiceFilters';
import {addSelectedStatusToProposalFilter, removeSelectedStatusFromProposalFilter} from '../../actions/proposalFilters';
import { addSelectedStatusToDocumentFilter, removeSelectedStatusFromDocumentFilter } from '../../actions/documentFilters';

const BillStatusFilterComponent = (props) => {
    const invoiceStatusNameList = ["Draft", "Open",  "Partial", "Paid", "Cancelled"];
    const invoiceStatusValueList = ["draft", "open",  "partial", "paid", "cancelled"];


    const invoiceStatusClassNames = ["draft__status", "open__status",  "partial__status", "paid__status", "cancelled_status", "approved__status", "rejected__status"];
    const invoiceStatusClassNames1 = ["draft__background", "open__background",  "partial__background", "paid__background", "cancelled__background", "approved__background", "rejected__background"]


    let statusNameList = []; //props.fromEstimatesComponent ? estimateStatusNameList : invoiceStatusNameList;
    let statusValueList = []; //props.fromEstimatesComponent ? estimateStatusValueList : invoiceStatusValueList;
    let statusClassNames = []; //props.fromEstimatesComponent ? estimateStatusClassNames : invoiceStatusClassNames;
    let statusClassNames1 = []; //props.fromEstimatesComponent ? estimateStatusClassNames1 : invoiceStatusClassNames1;

    statusNameList = invoiceStatusNameList;
    statusValueList = invoiceStatusValueList;
    statusClassNames = invoiceStatusClassNames;
    statusClassNames1 = invoiceStatusClassNames1;


    const statusClassName = (index) => {
        const STATUS_CLASS = `status__filterList ${statusClassNames1[index]}`;
        if(props.fromProposalListComponent) {
            return props.selectedStatusProposalFilter.includes(statusValueList[index]) ? `${STATUS_CLASS} active` : STATUS_CLASS;
        } else if(props.fromEstimatesComponent) {
            return props.selectedStatusEstimateFilter.includes(statusValueList[index]) ? `${STATUS_CLASS} active` : STATUS_CLASS;
        } else if(props.fromDocumentListComponent) {
            return props.selectedStatusDocumentFilter.includes(statusValueList[index]) ? `${STATUS_CLASS} active` : STATUS_CLASS;
        } else {
            return props.selectedStatusBillFilter.includes(statusValueList[index]) ? `${STATUS_CLASS} active` : STATUS_CLASS;
        }
    }

    const onStatusClick = (selectedValue) => {
        let selectedStatus;
        selectedStatus = props.selectedStatusBillFilter.filter((status) => status === selectedValue);
        selectedStatus && selectedStatus.length > 0 ? props.removeSelectedStatusFromBillFilter(selectedValue) : props.addSelectedStatusToBillFilter(selectedValue);
        trackGAEvent(props.selectedAccount.name, 'Bills filter - status filter changed');
        
    }

    return (
        <div style={{marginTop:'18px'}}>
            <Typography style={{fontSize:'14px'}}>
                Status            
            </Typography> 
            <Grid container lg={12} style={{marginTop:'8px'}} >
                {statusNameList.map((status, index) => {
                        return <Grid item
                                    className={statusClassName(index)}
                                    style={{display:'flex', alignItems:'center'}}
                                    key={status}
                                    onClick={() => {
                                        onStatusClick(statusValueList[index])
                                        props.pageReset();
                                    }}
                                >
                            <span className={`circle ${statusClassNames[index]}`}></span> 
                            <Typography  style={{fontSize:'12px'}}>{status}</Typography>
                        </Grid>
                    })
                }
            </Grid> 
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedStatusBillFilter: state.invoiceFilters.selectedStatusBillFilter,
    selectedStatusEstimateFilter: state.invoiceFilters.selectedStatusEstimateFilter,
    selectedAccount: state.invoiceAccounts.selectedAccount,

    selectedStatusProposalFilter: state.proposalFilters.selectedStatusProposalFilter,

    selectedStatusDocumentFilter: state.documentFilters.selectedStatusDocumentFilter
});

const mapDispatchToProps = (dispatch) => ({
    addSelectedStatusToBillFilter: (item) => dispatch(addSelectedStatusToBillFilter(item)),
    removeSelectedStatusFromBillFilter: (item) => dispatch(removeSelectedStatusFromBillFilter(item)),

    addSelectedStatusToEstimateFilter: (item) => dispatch(addSelectedStatusToEstimateFilter(item)),
    removeSelectedStatusFromEstimateFilter: (item) => dispatch(removeSelectedStatusFromEstimateFilter(item)),
    
    addSelectedStatusToProposalFilter: (item) => dispatch(addSelectedStatusToProposalFilter(item)), 
    removeSelectedStatusFromProposalFilter: (item) => dispatch(removeSelectedStatusFromProposalFilter(item)),

    addSelectedStatusToDocumentFilter: (item) => dispatch(addSelectedStatusToDocumentFilter(item)), 
    removeSelectedStatusFromDocumentFilter: (item) => dispatch(removeSelectedStatusFromDocumentFilter(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(BillStatusFilterComponent);