import React from 'react';
import RightToLeftSideDrawer from '../RightToLeftSideDrawer';
import {Grid, Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import StatusFilterComponent from '../filters/StatusFilterComponent';
import { clearSelectedDocumentFilters } from '../../actions/documentFilters';

const useStyles = makeStyles((theme) => ({
    customMarginTop : {
        marginTop:"16px"
    },
    customMarginBottom : {
        marginBottom:'-8px'
    },
}));

const DocumenFilterDrawer = (props) => {
    const classes = useStyles();

    return (
        <RightToLeftSideDrawer title="Filters" 
            openDrawer={props.openFilterDrawer}
            closeDrawer={props.handleCloseFilterDrawer}>

            <Grid item sm={12} style={{padding:'16px'}}>
                    {<StatusFilterComponent pageReset={props.pageReset} 
                        fromDocumentListComponent={true}
                        />}

                <div className="modal__footer">
                    <Button variant="contained" color="primary" 
                            className="modal__button"
                            onClick={() =>{
                                    props.pageReset();
                                    props.clearSelectedDocumentFilters();
                                }
                            }
                            >
                        Reset Filters
                    </Button>
                </div> 
            </Grid>
            
        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
})

const mapDispatchToProps = (dispatch) => ({
    clearSelectedDocumentFilters: () => dispatch(clearSelectedDocumentFilters())
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumenFilterDrawer);