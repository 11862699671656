import React, {useCallback, memo} from 'react';
import { TextField, Typography } from '@material-ui/core';
import { DroppedItemTypes } from '../../util/AppUtil';

const TextValueComponent = memo(({element, index, status, droppedItems, setDroppedItems  }) => {
    
    const handleTextChange = useCallback((e) => {
        const updatedFormElements = droppedItems;
        const value = e.target.value;
        updatedFormElements.forEach((item, i) => {
            if (item.component_type === DroppedItemTypes.TEXT && item.component_label === updatedFormElements[index].component_label) {
                updatedFormElements[i].value_json.text = value;
            }
        });
        setDroppedItems([...updatedFormElements]);
    }, [droppedItems, setDroppedItems]);

    return (
        <div>
            <Typography style={{fontSize:"14px", margin:0}}>{element.component_label}</Typography>
            <TextField 
                variant='outlined'
                fullWidth
                margin='dense'
                placeholder='Type'
                value={droppedItems[index].value_json?.text}
                onChange={(e) => handleTextChange(e)}
                style={{width:'98%', background:'#fff', marginTop:'2px'}}
            />
        </div>
    );
})

export default TextValueComponent;