import { Button, Checkbox, Chip, CircularProgress, ClickAwayListener, FormControlLabel, Grid, InputAdornment, makeStyles, MenuItem, Popover, Popper, TextField, Typography } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import { useEffect, useState } from "react";
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import { AccessLevel, consoleToLog, extractFlagCode, interStateTaxesArr, intraStateTaxesArr, isInvoiceAccountCountryIndia, ItemTypes, otherTaxesTypes, trackGAEvent } from "../util/AppUtil";
import { getClientByTextApi, getVendorsByTextApi } from "../services/clientService";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { callBillSubmitApi, callCreateBillSubmitApi, callUpdateBillSubmitApi, getBillCategoryListApi, getCurrencyObjApi, getEmployeeListApi } from "../services/authService";
import { formatAmount } from "../util/currencyUtil";
import { useSnackbar } from 'notistack';
import CreateTaxPresetDrawer from "./CreateTaxPresetDrawer";
import AddEditBillCategoriesDrawer from "./AddEditBillCategoriesDrawer";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import AddEditEmployeeDrawer from "./AddEditEmployeeDrawer";
import AddEditClientDrawer from "./AddEditClientDrawer";
import Flags from "country-flag-icons/react/3x2";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DatePicker from "react-datepicker";
import Calender from "./Calender";

const customMarginTop = {
    marginTop: "16px",
    paddingLeft: "8px",
    paddingRight: "8px"
};

const MuiFormControlroot = {
    width:'100%',
    background:'#fff'
    // "& .MuiOutlinedInput-input": {
    //     paddingLeft: "16px",
    //     paddingTop:'8px',
    //     paddingBottom:'8px',
    //     background:'#fff',
    // },
    // "& .MuiSelect-select.Mui-disabled": {
    //     cursor: "default !important",
    //     background: "#EEEEEE !important",
    // },
    // "& .MuiInputBase-input.Mui-disabled": {
    //     background: "#EEEEEE !important",
    //     color: "#555555 !important",
    // },
    // "& .MuiInputBase-adornedEnd" : {
    //     backgroundColor: '#fff !important',
    // }
};

const useStyles = makeStyles((theme) => ({
    itemOptionStyle: {
        width: "100%", 
        height:'100%',
        color:'#777f8e',
        margin:'5px 0px'
    },
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'0.5px 1px 0.5px 1px',
        borderRadius: '4px',
        }
    },
    customTextField: {
        "& input::placeholder": {
            paddingLeft: "8px",
        }
    },
    taxItemStyle: {
        padding: "8px 8px", 
        cursor: 'pointer',
        '&:hover': {
            background: '#f5f5f5'
        }
    },
    MuiTextFieldroot: {
        background:'#fff',
        // '& .MuiOutlinedInput-input': {
        //     paddingTop: '8px',
        //     paddingBottom: '8px'
        // }
    },
    dateBtn: {
        background:'#fff',
        padding:'5px 8px'
    }
}))

const CreateUpdateBillDetailComponent=(props)=>{
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [billObj, setBillObj]=useState();
    const [vendors, setVendors]=useState([]);
    const [currencyObj, setCurrencyObj]=useState(props.billObj ? props.billObj?.purchase_invoice?.currency : props.selectedAccount?.currencies);
    const [clients, setClients]=useState([]);
    const [billDate, setBillDate] = useState(null);
    const [billDueDate, setBillDueDate] = useState(null);
    const [isNoVendor, setIsNoVendor]= useState(true);
    const [isEmpReimburse, setIsEmpReimburse]= useState(true);
    const [isClaimExpense, setIsClaimExpense]= useState(true);
    const [loading, setLoading]= useState(false);

    const [tdsTypeObj, setTDSTypeObj] = useState(null);
    const [tdsAmount, setTDSAmount] = useState(0);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [selectedEntity, setSelectedEntity]=useState(props.selectedEntity);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const [showAddTaxMenu, setShowAddTaxMenu] = useState(false);
    const [taxPresetsArr, setTaxPresetsArr] = useState([...props.selectedAccount?.tax_presets]);
    let other_taxes = otherTaxesTypes(props.selectedAccount?.tax_presets);
    let inter_state_taxes = interStateTaxesArr(props.selectedAccount?.tax_presets);
    let intra_state_taxes = intraStateTaxesArr(props.selectedAccount?.tax_presets);
    const [selectedTaxes, setSelectedTaxes]=useState([]);
    
    const [employeeList, setEmployeeList]=useState([]);
    const [billCategoryList, setBillCategoryList]=useState([]);
    
    const [deductTds, setDeductTds]=useState(false);
    const [billNumber, setBillNumber]=useState("");
    const [subtotal, setSubtotal]=useState(0);
    const [description, setDescription]=useState();
    const [selectedBillCategory, setSelectedBillCategory] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openBillCategoryDrawer, setOpenBillCategoryDrawer] = useState(false);
    const [openBillEmployeeDrawer, setOpenBillEmployeeDrawer] = useState(false);
    const [openBillVendorDrawer, setOpenBillVendorDrawer] = useState(false);
    const [currencies, setCurrencies] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState({});
    const [inputText, setInputText] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);

    const [anchorEl1, setAnchorEl1] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);

    const currenciesObj = props.selectedAccount?.currencies;

    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;
    const invoiceAccountCountry = props.selectedAccount?.country;
    

    const getBillFrom = (highlights) => {
        let billFrom = highlights.find((h)=> h?.type === ItemTypes.VENDOR_NAME);
        //consoleToLog("billFrom", billFrom)
        return billFrom;
    }
    const getBillNum = (highlights) => {
        let billNumber = highlights.find((h)=> h?.type === ItemTypes.INVOICE_RECEIPT_ID);
        //consoleToLog("billNumber", billNumber)
        return billNumber;
    }

    const getBillDate = (highlights) => {
        let billDate = highlights.find((h)=> h?.type === ItemTypes.INVOICE_RECEIPT_DATE);
        return billDate;
    }

    const getBillDueDate = (highlights) => {
        let billDueDate = highlights.find((h)=> h?.type === ItemTypes.DUE_DATE);
        //consoleToLog("billDueDate", billDueDate)
        return billDueDate;
    }

    const billNumObj = getBillNum(props.highlights);
    const billFromObj = getBillFrom(props.highlights);
    const billDateObj = getBillDate(props.highlights);
    const billDueDateObj = getBillDueDate(props.highlights);

    const updateHash = (highlight) => document.location.hash = highlight ? `highlight-${highlight.id}` : '';

    useEffect(() => {
        console.log("**useEffect right side : billObj", props.billObj);
        //get employee list // get category list 
        getList();
        getCurrencies(currenciesObj?.name, true);
    }, []);

    useEffect(() => {
        if(props.billObj && Object.keys(props.billObj).length>0){
            console.log("**right side useeffect of billObj", props.billObj);
            setBillObj(props.billObj);
            //if suggested vendors found use this 
            if(props.billObj.vendors && props.billObj.vendors.length > 0) {
                setIsNoVendor(false);
                setVendors([...props.billObj.vendors]);
                setSelectedVendor({...props.billObj.vendors[0]});
            }
            else{//if no suggested vendor check this
                if(props.billObj?.purchase_invoice?.vendor) {
                    setIsNoVendor(false);
                } else {
                    setIsNoVendor(true);
                }
                setSelectedVendor(props.billObj?.purchase_invoice?.vendor)
            }
            setCurrencyObj(props.billObj ? props.billObj?.purchase_invoice?.currency : props.selectedAccount?.currencies);
            prefillFormFields(props.billObj);

        }
    }, [props.billObj]);

    useEffect(() => {
        if(selectedVendor){
            consoleToLog("**Vendor state", selectedVendor?.state);
            consoleToLog("**selectedEntity state", selectedEntity?.state);
            if(selectedEntity?.state === selectedVendor?.state){
                setTaxPresetsArr([...intra_state_taxes, ...other_taxes]);
            }
            if(selectedEntity?.state !== selectedVendor?.state){
                setTaxPresetsArr([...inter_state_taxes, ...other_taxes]);
            }
        }
        else{
            setTaxPresetsArr([...props.selectedAccount?.tax_presets])
        }
    }, [selectedVendor]);

    useEffect(() => {
        setTaxPresetsArr([...props.selectedAccount?.tax_presets])
    }, [props.selectedAccount?.tax_presets]);

    const prefillFormFields=(bill)=>{
        setSelectedCurrency(bill?.purchase_invoice?.currency);
        setSelectedEntity(bill?.purchase_invoice?.entity);
        setBillNumber(bill?.purchase_invoice?.bill_number);
        setSubtotal(bill?.purchase_invoice?.subtotal || 0);
        setBillDate(bill?.purchase_invoice?.bill_date);
        setBillDueDate(bill?.purchase_invoice?.due_date);
        setSelectedBillCategory(bill?.purchase_invoice?.category);
        setDescription(bill?.purchase_invoice?.description);
        setSelectedTaxes(bill?.purchase_invoice?.taxes || []);
        if(bill?.purchase_invoice?.tds_type_object 
            && Object.keys(bill?.purchase_invoice?.tds_type_object).length>0)
        {
            setDeductTds(true);
            setTDSAmount(bill?.purchase_invoice?.tds_amount);
            setTDSTypeObj(bill?.purchase_invoice?.tds_type_object);
        }
        if(bill?.purchase_invoice?.employee 
            && Object.keys(bill?.purchase_invoice?.employee).length>0){
            setIsEmpReimburse(false);
            setSelectedEmployee(bill?.purchase_invoice?.employee);
        }

        if(bill?.purchase_invoice?.client 
            && Object.keys(bill?.purchase_invoice?.client).length>0){
            setIsClaimExpense(false);
            setSelectedClient(bill?.purchase_invoice?.client);
        }
    }

    const getCurrencies = (query, setCurrencyObj) => {
        const invoice_account_id = props.selectedAccount?.id;
        getCurrencyObjApi(invoice_account_id, query)
        .then((respose) => {
            const res = respose.data;
            consoleToLog('Response getCurrencyObjApi: ', res);

            setCurrencies(res);
            setCurrencyObj && setSelectedCurrency(currenciesObj);
        })
        .catch((e) => {
            consoleToLog('Error getCurrencyObjApi', e);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        })

    }

    const getList = async() => {
        const invoice_account_id = props.selectedAccount?.id;
        try{
            const res=await getEmployeeListApi(invoice_account_id);
            consoleToLog("Response Employee List",res.data)
            setEmployeeList(res.data);

            const res1=await getBillCategoryListApi(invoice_account_id);
            consoleToLog("Response getBillCategoryListApi List",res1.data)
            setBillCategoryList(res1.data);
        }
        catch(e){
            console.log("failed to get employee list", e);
            setEmployeeList([]);
        }
    }

    const handleChangeDate = (date) => {
        setBillDate(moment(date).format('YYYY-MM-DD'));
    };

    const handleBillDatePopoverClick = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleBillDatePopoverClose = () => {
        setAnchorEl1(null);
    }

    const handleBillDatePopOver = () => {
        return (<Popover
            id={'bill-date-popover'}
            open={Boolean(anchorEl1)}
            anchorEl={anchorEl1}
            onClose={handleBillDatePopoverClose}
            className='date_popover_style'
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
        >
            <DatePicker
                selected={billDate ? moment(billDate).toDate() : moment().toDate()}
                onChange={(date) => handleChangeDate(date)}
                //selectsRange
                inline
            />
        </Popover>)
    }
    
    const onSubtotalChange= (e) => {
        let inputSubtotal = e.target.value;
        // Regular expression to allow only numbers with up to 2 decimal places
        if (inputSubtotal === "" || /^\d+(\.\d{0,2})?$/.test(inputSubtotal)) {
            setSubtotal(inputSubtotal);

            //on subtotal change update selected taxes as well
            let localSelectedTaxes=selectedTaxes;
            if(localSelectedTaxes && localSelectedTaxes.length>0){
                let updatedTaxes= localSelectedTaxes.map((t)=>{
                    const finalSubtotal=(inputSubtotal*t.rate)/100;
                    t.amount=finalSubtotal.toFixed(2);
                    return t;
                })
                setSelectedTaxes([...updatedTaxes]);
            }
        }
    };

    const onDescriptionChange=(e)=>{
        let input = e.target.value;
        setDescription(input);
    }

    const handleChangeDueDate = (date) => {
    setBillDueDate(moment(date).format('YYYY-MM-DD'));
    };

    const handleDueDatePopoverClick = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleDueDatePopoverClose = () => {
        setAnchorEl2(null);
    }

    const handleDueDatePopOver = () => {
        return (<Popover
            id={'due-date-popover'}
            open={Boolean(anchorEl2)}
            anchorEl={anchorEl2}
            onClose={handleDueDatePopoverClose}
            className='date_popover_style'
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
        >
            <Calender issueDate={billDate ? moment(billDate).toDate() : moment().toDate()} 
                dueDate={billDueDate ? moment(billDueDate).toDate() : moment().toDate()}
                setDueDate={handleChangeDueDate}
                handleDueDatePopoverClose={handleDueDatePopoverClose}
                />
        </Popover>)
    }

    const onItemClearClick1 = () => {
        setTDSTypeObj(undefined);
        setTDSAmount(0);
    }

    const handleVendorDrawerOpen=()=>{
        trackGAEvent(props.selectedAccount.name, 'Bill Vendor - Add button clicked', `${props.user.firstname} ${props.user.lastname}`);
        setOpenBillVendorDrawer(true);
    }

    const handleVendorDrawerClose=()=>{
        setOpenBillVendorDrawer(false);
    }

    const handleEmployeeDrawerOpen=()=>{
        trackGAEvent(props.selectedAccount.name, 'Bill Employee - Add button clicked', `${props.user.firstname} ${props.user.lastname}`);
        setOpenBillEmployeeDrawer(true);
    }

    const handleEmployeeDrawerClose=()=>{
        setOpenBillEmployeeDrawer(false);
    }

    const handleCategoryDrawerOpen = () => {
        trackGAEvent(props.selectedAccount.name, 'Bill Category - Add button clicked', `${props.user.firstname} ${props.user.lastname}`);
        setOpenBillCategoryDrawer(true);
    }

    const handleCategoryDrawerClose = () => {
        setOpenBillCategoryDrawer(false);
    }

    const handleDrawerOpen = (e) => {
        trackGAEvent(props.selectedAccount.name, 'Bill Edit - Add Tax preset button clicked', `${props.user.firstname} ${props.user.lastname}`);
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    const onAddBillCategoryClick = (category) => {
        setBillCategoryList(billCategoryList => [...billCategoryList, category]);
    }

    const onAddBillEmployeeClick = (category) => {
        setEmployeeList(employeeList => [...employeeList, category]);
    }

    const addClient=(vendor)=>{
        setVendors(vendors => [...vendors, vendor])
    }

     const renderOptions1 = (option) => {
        return (
            <div className={classes.itemOptionStyle}>
                <Grid item container alignItems='center' justifyContent="space-between">
                    <Grid item md={10}>
                        <Typography variant='body2'>
                            {option.tds_display}
                        </Typography> 
                    </Grid>

                    <Grid item md={2} style={{textAlign:'right'}}>
                        <Typography variant='body2'>
                            {option.rate}
                        </Typography>
                    </Grid>
                </Grid>    

            </div>
            );
    }

    const onVendorNameChange=(e, newValue, reason)=>{
        if(newValue){
            if(newValue.id !=='add_new'){
                setSelectedVendor(newValue);
            }
        }
        else{
            setSelectedVendor(null);
        }
    }

    const onClientInputChange= (e, inputValue) => {
        const invoice_account_id = props.selectedAccount?.id
        const text=inputValue;
        const company_type='customer';

        if(text && text !== selectedVendor?.name){
            getClientByTextApi(invoice_account_id, text, company_type)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response getClientByTextApi: ', res);
                    setClients(res.companies);
                })
                .catch((e) => {
                    consoleToLog('Error getClientByTextApi', e.response);
                    if(e.response.data && e.response.data.message) {
                        return;
                    }
            });
        }
        else{
            setClients([]);
        }
    };

    const onVendorInputChange = (e, inputValue) => {
        const invoice_account_id = props.selectedAccount?.id
        const text=inputValue;
        const company_type='vendor';

        if(text && text !== selectedVendor?.name){
            getVendorsByTextApi(invoice_account_id, text, company_type)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response getVendorsByTextApi: ', res);
                    setVendors(res.companies);
                })
                .catch((e) => {
                    consoleToLog('Error getVendorsByTextApi', e.response);
                    if(e.response.data && e.response.data.message) {
                        return;
                    }
            });
        }
        else{
            setVendors([]);
        }
    };
    
    const onAddTaxClick = (event) => {
        //console.log("Apply tax click", showAddTaxMenu);
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setShowAddTaxMenu(!showAddTaxMenu);
        /* if(dropdownIndex !== undefined && anchorEl) {
            setDropDownIndex(undefined);
            setAnchorEl(null);
        } */
    }

    const onTaxItemClick=(tax)=>{
        //console.log("selected tax: ", tax);
        const finalSubtotal=(subtotal*tax.rate)/100;
        tax.amount=finalSubtotal.toFixed(2);
        setSelectedTaxes([...selectedTaxes, tax]);
        setShowAddTaxMenu(false);
        setAnchorEl(null);
    }

    const onTaxDelete = (tax)=>{
        let taxArr=selectedTaxes.filter(t=>t.id!==tax.id);
        setSelectedTaxes(taxArr)
    }

    const onBillNumberChange =(e)=>{
        const bill_number=e.target.value;
        setBillNumber(bill_number);
    }

    const getTotal = () => {
        let tax = (selectedTaxes && selectedTaxes.length>0)? selectedTaxes.reduce((acc, curr) => acc + parseFloat(curr.amount), 0) : 0;
        let total=parseFloat(subtotal)+parseFloat(tax);
        return total
    }

    const onBillCategoryChange = (e, newValue, reason) => {
        if(newValue){
            if(newValue.id !=='add_new'){
                setSelectedBillCategory(newValue);
            }
        }
        else{
            setSelectedBillCategory(null);
        }
    }

    const onEmployeeChange= (e, newValue, reason) => {
        if(newValue){
            if(newValue.id !=='add_new'){
                setSelectedEmployee(newValue);
            }
        }
        else{
            setSelectedEmployee(null);
        }
    }

    const onClientChange= (e, newValue, reason) => {
        if(newValue){
            setSelectedClient(newValue);
        }
        else{
            setSelectedClient(null);
        }
    }

    const onTDSItemChanged = (e, newValue) => {
        if(newValue){
            setTDSTypeObj(newValue);
            let calculateTDSAmount = parseFloat((newValue.rate * subtotal) / 100);
            setTDSAmount(parseFloat(calculateTDSAmount.toFixed(2)));
        }
        else{
            setTDSTypeObj(undefined);
            setTDSAmount(0);
        }
    }
    
   const onSubmitHandler = async() => {
        const currency_id=selectedCurrency?.id || null;

        if(!billNumber){
            enqueueSnackbar('Bill number can not be empty!', {variant:'error'});
            return;
        }

        if(!billDate){
            enqueueSnackbar('Bill date can not be empty!', {variant:'error'});
            return;
        }

        if(!billDueDate){
            enqueueSnackbar('Bill due date can not be empty!', {variant:'error'});
            return;
        }

        if(!subtotal || subtotal<0){
            enqueueSnackbar("Subtotal can not be empty or should be greter than 0", {variant:'error'});
            return;
        }

        if(!currency_id){
            enqueueSnackbar('Kindly select currency!', {variant:'error'});
            return;
        }

        try{
            const entity_id=selectedEntity.id;
            const invoice_account_id=props.selectedAccount.id;
           
            let taxes=selectedTaxes && selectedTaxes.length>0 &&
                selectedTaxes.map((tax)=>{
                  return {
                    name: tax.name,
                    rate: tax.rate,
                    amount: parseFloat(tax.amount),
                    tax_preset_id: tax.id
                  }  
                });
            const claim_client_id=!isClaimExpense && selectedClient ? selectedClient?.id: null;
            const employee_id=!isEmpReimburse && selectedEmployee ? selectedEmployee?.id: null;
            const category_id=selectedBillCategory ? selectedBillCategory?.id : null;
            const tds_type_id=tdsTypeObj?tdsTypeObj.id: null;
            const vendor_id = !isNoVendor && selectedVendor ? selectedVendor?.id : undefined;
            
            setLoading(true);
            if(billObj){
                 //if bilobj available then update api
                const res=await callUpdateBillSubmitApi(entity_id, invoice_account_id, billNumber, 
                    billDate, billDueDate, subtotal, tdsAmount, taxes, 
                    vendor_id, claim_client_id, employee_id, 
                    category_id, props.billObj?.purchase_invoice?.id, 
                    description, tds_type_id, currency_id)
                
                consoleToLog("Response callUpdateBillSubmitApi: ", res.data);

                setLoading(false);
                enqueueSnackbar('Bill updated successfully!', {variant:'success'});
                props.setUpdatedBillObj(res.data);
                
                //close modal
                props.handleBillDetailClickDialogClose();
            }
            else{
                consoleToLog("Creating bill manually!");
                //if bill obj not available then create api
                const res=await callCreateBillSubmitApi(entity_id, invoice_account_id,  billNumber, 
                    billDate, billDueDate, subtotal, tdsAmount, taxes,
                    vendor_id, claim_client_id, employee_id, 
                    category_id, description, tds_type_id, currency_id);
                
                consoleToLog("Response callCreateBillSubmitApi: ", res.data);

                setLoading(false);
                enqueueSnackbar('Bill submitted successfully!', {variant:'success'});
                
                //close modal
                props.handleBillDetailClickDialogClose();
            }
        }
        catch(e){
            console.log("Error submit: ", e);
            setLoading(false);
            if(e?.response?.data && e?.response?.data?.message){
                enqueueSnackbar(e.response.data.message, {variant:'error'});
            }
        }
    } 

    const onSelectedCurrencyChange = (e, newValue) => {
        setSelectedCurrency(newValue);
        //console.log('selected currency', newValue);
    }

    const handleInputChangeForCurrency = (e, newValue) => {
        const value = e?.target.value;
        value && getCurrencies(newValue, false);

        setInputText(newValue);
        if (!newValue) {
            setShowDropdown(false);
        }
    }

    const renderCurrencyOptions = (option) => {
        const flagCode =  extractFlagCode(option.flag);
        const Flag = Flags[flagCode];
        return (
            <Grid item md={12} className={classes.itemOptionStyle}>
                <Grid item container alignItems='center'> 
                    <Grid item md={1}>
                        <div style={{position:'relative', top:'1px'}}>
                            <Flag width='28px' height='22px'/>
                        </div>
                    </Grid>
                    <Grid item md={8}>
                        <Typography variant='body1' style={{marginLeft:'8px'}}>
                            {`${option.name}`}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            );
    }

    const showFlagStartAdornment = () => {
        const flagCode =  extractFlagCode(selectedCurrency?.flag);
        const Flag = Flags[flagCode];

        if (!Flag) {
            console.error(`Flag component for code ${selectedCurrency?.code} not found.`);
            return null; // Return null if the Flag is undefined
        }
    

        return ( 
                <InputAdornment position="start">
                    <Grid item container alignItems='center'>
                        <Grid item style={{paddingLeft:'6px', position:"relative", top:'1px'}}>
                            <Flag width='25px'/>
                        </Grid>
                    </Grid>
                </InputAdornment>
            )
    }

    return(
        <>
        <Grid container>
        {
            props.loading ?
            <Grid item container justifyContent="center">
                <CircularProgress style={{marginTop:'16px'}}/>
            </Grid>
            :
            <Grid item md={12} >
                {/* <Grid container style={{ height: '50px', backgroundColor: '#dddddd', padding: "0px 8px"}}>
                    <Grid item md={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography >Create Bill Form</Typography>
                    </Grid>
                    <Grid item md={6} 
                        style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                        >
                        <Button variant="text" color="primary" 
                            onClick={()=>props.handleBillDetailClickDialogClose()}>
                            <ClearIcon />
                        </Button>
                    </Grid>
                </Grid> */}
                <Grid item md={12} className="sidebar1">
                    <Grid container spacing={1}>
                        <Grid item md={6}>
                            <Typography variant="subtitle1" className='textfield_label mandatory_fields'>
                                Bill Number*
                            </Typography>
                            <TextField
                                size="small"
                                placeholder="Bill Number"
                                variant="outlined"
                                fullWidth
                                value={billNumber}
                                onChange={onBillNumberChange}
                                onClick={(e) => updateHash(billNumObj)}
                                classes={{
                                    root: classes.MuiTextFieldroot
                                }}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <Typography variant="subtitle1" className='textfield_label mandatory_fields'>
                                Currency*
                            </Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                options={currencies}
                                classes={{
                                    root: classes.MuiAutocompleteinputRoot
                                }}
                                style={MuiFormControlroot}
                                open={showDropdown}
                                onOpen={() =>  {
                                    if(inputText) {
                                        setShowDropdown(true)
                                    }
                                }}
                                onClose={() => setShowDropdown(false)}
                                value={selectedCurrency}
                                getOptionLabel={(option) => option.name}
                                renderOption={(option) => renderCurrencyOptions(option)}
                                onChange={(e, newValue) => onSelectedCurrencyChange(e, newValue)}
                                onInputChange = {(e, newValue) => handleInputChangeForCurrency(e, newValue)}
                                renderInput={params => (
                                    <TextField {...params} variant="outlined" 
                                    placeholder='Search Currency' 
                                    fullWidth
                                    classes={{
                                        root: classes.customTextField
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <>
                                            {showFlagStartAdornment()}
                                            {params.InputProps.startAdornment}
                                            </>
                                        ),
                                    }}
                                        />
                                )}
                                
                            />
                        </Grid>
                    </Grid>        
                    <Grid item container 
                        alignItems="center" 
                        spacing={1}
                        style={{ marginTop: '12px'}}>
                        <Grid item md={6}>
                            <Typography variant="subtitle1" 
                                className='textfield_label mandatory_fields'>
                                Bill Date*
                            </Typography>
                            <Button variant="outlined" color="primary" fullWidth
                                className={classes.dateBtn}
                                onClick={handleBillDatePopoverClick}>
                                    <Grid item container justifyContent="space-between" alignItems="center">
                                        {billDate ? moment(billDate).format('DD/MM/YYYY') : 'dd/mm/yyyy'}
                                        <DateRangeIcon fontSize='small'/> 
                                    </Grid>
                            </Button>
                            {handleBillDatePopOver()}
                            {/* <Typography variant="subtitle1" 
                                className='textfield_label mandatory_fields'
                                style={{marginBottom: "-8px"}}>
                                Bill Date*
                            </Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="date"
                                margin="dense"
                                autoComplete="off"
                                value={billDate}
                                onChange={handleChangeDate}
                                onClick={(e) => {
                                    updateHash(billDateObj);
                                }}
                                InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                                classes={{
                                    root: classes.MuiTextFieldroot
                                }}
                            /> */}
                        </Grid>

                        <Grid item md={6}>
                            <Typography variant="subtitle1" 
                                className='textfield_label mandatory_fields'>
                                Due Date*
                            </Typography>
                            <Button variant="outlined" color="primary" fullWidth
                                className={classes.dateBtn}
                                onClick={handleDueDatePopoverClick}>
                                <Grid item container justifyContent="space-between" alignItems="center">
                                    {billDueDate ? moment(billDueDate).format('DD/MM/YYYY') : 'dd/mm/yyyy'}
                                    <DateRangeIcon fontSize='small'/> 
                                </Grid>
                            </Button>
                            {handleDueDatePopOver()}
                            {/* <TextField
                                fullWidth
                                variant="outlined"
                                type="date"
                                margin="dense"
                                autoComplete="off"
                                value={billDueDate}
                                onChange={handleChangeDueDate}
                                onClick={(e) => {
                                    updateHash(billDueDateObj);
                                }}
                                InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                                classes={{
                                    root: classes.MuiTextFieldroot
                                }}
                            /> */}
                        </Grid>
                    </Grid>
                    <Grid item container 
                        alignItems="center" 
                        style={{ marginTop: '12px'}}>
                        <Grid item md={12}>
                            <FormControlLabel
                                style={{marginBottom: "-4px"}}
                                control={
                                    <Checkbox
                                        checked={!isNoVendor}
                                        onChange={() =>{
                                            setIsNoVendor(!isNoVendor);
                                        }}
                                    />
                                }
                                label="Select Vendor"
                            />
                            <Autocomplete
                                size="small"
                                id="bill_from"
                                disabled={isNoVendor}
                                getOptionLabel={(option) => option.name || ''}
                                value={selectedVendor}
                                options={[{ name: "Add New Vendor", id: "add_new" }, ...vendors]}
                                onChange={(e, newValue, reason) => onVendorNameChange(e, newValue, reason)}
                                style={MuiFormControlroot}
                                renderInput={(params) => 
                                    <TextField {...params} color="secondary" variant="outlined"  placeholder="Select Vendor"
                                        onClick={(e) => updateHash(billFromObj)}
                                    />}
                                onInputChange={(e, newValue) => onVendorInputChange(e, newValue)}
                                getOptionSelected={(option, value) => option?.id === value?.id}
                                renderOption={(option) => 
                                    option.id === "add_new" ? (
                                        <Button variant="text" color="primary" style={{ width: '100%' }}
                                            onClick={()=>handleVendorDrawerOpen()}>
                                            <AddOutlinedIcon fontSize='small'
                                                style={{
                                                    marginRight:'8px',
                                                        fontSize: '16px'
                                                        }}
                                            />
                                            Add Vendor
                                        </Button>
                                    ) : (
                                        option.name
                                    )
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item container 
                        alignItems="center" 
                        style={{ marginTop: '12px'}}>
                        <Grid item md={12}>
                            <Typography variant="subtitle1" className='textfield_label'>
                                Bill Category:
                            </Typography>
                            <Autocomplete
                                size="small"
                                id="bill_from"
                                getOptionLabel={(option) => option.name || ''}
                                value={selectedBillCategory}
                                options={[{ name: "Add New Category", id: "add_new" }, ...billCategoryList]}
                                onChange={(e, newValue, reason) => onBillCategoryChange(e, newValue, reason)}
                                style={MuiFormControlroot}
                                renderInput={(params) => 
                                    <TextField {...params} color="secondary" variant="outlined"  placeholder="Select Bill Category"
                                        //onClick={(e) => updateHash(billFromObj)}
                                    />}
                                renderOption={(option) => 
                                    option.id === "add_new" ? (
                                        <Button variant="text" color="primary" style={{ width: '100%' }}
                                            onClick={()=>handleCategoryDrawerOpen()}>
                                            <AddOutlinedIcon fontSize='small'
                                                style={{
                                                    marginRight:'8px',
                                                        fontSize: '16px'
                                                        }}
                                            />
                                            Add New Category
                                        </Button>
                                    ) : (
                                        option.name
                                    )
                                }
                                getOptionSelected={(option, value) => option.id === value.id}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '12px' }}>
                        <Typography variant="subtitle1" className='textfield_label mandatory_fields'>
                            Subtotal*
                        </Typography>
                        <TextField
                            size="small"
                            placeholder="Subtotal"
                            value={subtotal}
                            onChange={onSubtotalChange}
                            variant="outlined"
                            fullWidth
                            type="number"
                            classes={{
                                root: classes.MuiTextFieldroot
                            }}
                        />
                    </Grid>
                    <Grid container style={{ marginTop: '12px' }}>
                        <Typography variant="subtitle1" className='textfield_label'>
                            Description
                        </Typography>
                        <TextField
                            size="small"
                            placeholder="Description"
                            variant="outlined"
                            fullWidth
                            value={description}
                            onChange={onDescriptionChange}
                            classes={{
                                root: classes.MuiTextFieldroot
                            }}
                        />
                    </Grid>
                    <Grid item style={{marginTop: "15px", position: "relative"}}>
                        <Button className="dd-button"
                            onClick={(event) => onAddTaxClick(event)}
                            style={{marginRight:'-6px'}}
                        >
                            Apply Tax
                            <ExpandMoreIcon className="arrow_down_icon"/>
                        </Button>
                        {
                        showAddTaxMenu && 
                            <Popover 
                                anchorEl={anchorEl}
                                open={open}
                                onClose={()=>{
                                    setShowAddTaxMenu(false);
                                    setAnchorEl(false);
                                }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                style={{height:'auto', maxHeight:'330px'}}
                                >
                                <>
                                {
                                    <Button style={{width:'250px', margin: "8px 8px 8px 16px"}}
                                        onClick={()=>{
                                            setShowAddTaxMenu(false);
                                            setAnchorEl(false);
                                            handleDrawerOpen();
                                        }}
                                        variant="outlined" color="secodary">
                                        <AddCircleOutlineIcon fontSize='small'
                                            style={{marginRight:'8px',
                                                    fontSize: '16px'
                                                    }}
                                        />
                                        Create Preset
                                    </Button>
                                }
                                    {
                                        <Typography className={classes.taxPresetHeading} 
                                            style={{fontWeight: "bold", marginLeft: "8px", marginTop: "8px", marginBottom: "8px", fontSize:"14px"}}>
                                            TAX PRESET
                                        </Typography>
                                    }
                                    {
                                        taxPresetsArr && taxPresetsArr.length>0 && taxPresetsArr.map((tax, i) => {
                                        return <Grid container justifyContent="space-between" 
                                                    key={tax.id} 
                                                    onClick={(e) => onTaxItemClick(tax)}
                                                    className={classes.taxItemStyle}>
                                                <Typography variant="subtitle1">
                                                    {tax.name}
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    {`${(tax.rate).toFixed(2)}%`}
                                                </Typography>
                                            </Grid>
                                        })
                                    }
                                </>
                            </Popover>
                        }
                    </Grid>
                    <Grid item style={{ marginTop: '15px' }}>
                        {selectedTaxes && selectedTaxes.length>0 
                            && selectedTaxes.map((tax, index) => (
                            <Grid key={index} container spacing={2} style={{ marginTop: '10px' }}>
                                <Grid item md={4}>
                                    <Chip label={tax.name} />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        size="small"
                                        label={`Tax Amount ${index + 1}`}
                                        variant="outlined"
                                        fullWidth
                                        type="number"
                                        value={tax?.amount}
                                        classes={{
                                            root: classes.MuiTextFieldroot
                                        }}
                                    />
                                </Grid>
                                <Button md={2} variant="text" color="primary" 
                                    onClick={()=>onTaxDelete(tax)}
                                >
                                    <ClearIcon />
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container style={{ marginTop: '15px' }}>
                        <Grid container justifyContent="space-between">
                            <Typography variant="h5">
                                Total
                            </Typography>
                            <Typography variant="h5">
                                {formatAmount(getTotal(), currencyObj)}
                            </Typography>
                        </Grid>
                    </Grid>
                   {isInvoiceAccountCountryIndia(invoiceAccountCountry) && <Grid container  style={{ marginTop: '12px' }}>
                        <FormControlLabel
                            style={{marginBottom: "-4px"}}
                            control={
                                <Checkbox
                                    checked={deductTds}
                                    onChange={() =>{
                                        setDeductTds(!deductTds);
                                    }}
                                />
                            }
                            label="Deduct TDS"
                        />
                    </Grid>}
                    {
                    isInvoiceAccountCountryIndia(invoiceAccountCountry) 
                    && <Grid container item spacing={1}> 
                        <Grid item md={7} lg={7} >
                            <Typography variant="subtitle1" className='textfield_label'>
                               TDS
                            </Typography>   
                            <Autocomplete
                                size="small"
                                id="combo-box-demo"
                                disabled={!deductTds}
                                options={props.tdsTypeList}
                                classes={{
                                    root: classes.MuiAutocompleteinputRoot
                                }}
                                style={MuiFormControlroot}
                                value={tdsTypeObj}
                                getOptionLabel={(option) => option.tds_display}
                                getOptionSelected={(option, value) => option?.id === value?.id}
                                renderOption={(option) => renderOptions1(option)}
                                onChange={(e, newValue) => {
                                    onTDSItemChanged(e, newValue);
                                }}
                                renderInput={params => (
                                    <TextField {...params} variant="outlined"
                                    placeholder='Search items'
                                    fullWidth
                                    classes={{
                                        root: classes.customTextField
                                    }}
                                        />
                                )}
                                closeIcon={<CloseIcon fontSize='small' onClick={()=>onItemClearClick1()}/>}
                                //getOptionSelected={(option, value) => option.id === value.id}
                            />
                        </Grid>    

                        <Grid item md={5} lg={5}>
                            <div style={{ display: "flex" }}>
                                <Typography variant="subtitle1" className='textfield_label' 
                                    style={{ marginBottom:'-8px'}}>
                                TDS Amount
                                </Typography>
                            </div>  
                            <TextField id="tds-amount"
                                size="small"
                                variant='outlined'
                                type='number'
                                disabled={!deductTds}
                                margin='dense'
                                style={{color:'#ccc'}}
                                placeholder='TDS Amount'
                                value={tdsAmount}
                                onChange={(e) => {
                                    let input=e.target.value;
                                    if (input === "" || /^\d+(\.\d{0,2})?$/.test(input)) {
                                        setTDSAmount(parseFloat(input));
                                    }
                                }}
                                InputLabelProps={{
                                // classes: { root: classes.labelRoot, shrink: classes.shrink },
                                    style: {fontSize: 14, color: "#ccc"}
                                }}
                                className={classes.input}
                                classes={{
                                    root: classes.MuiTextFieldroot
                                }}
                                closeIcon={<CloseIcon fontSize='small' onClick={onItemClearClick1}/>}
                            />
                            
                        </Grid>
                    </Grid> 
                    }

                    <Grid container style={{ marginTop: '15px' }}>
                        <Grid container justifyContent="space-between">
                            <Typography variant="h5">
                                Net Payable
                            </Typography>
                            <Typography variant="h5">
                                {formatAmount(getTotal()-tdsAmount, currencyObj)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container 
                        alignItems="center" 
                        style={{ marginTop: '12px'}}>
                        <Grid item md={12}>
                            <FormControlLabel
                                style={{marginBottom: "-4px"}}
                                control={
                                    <Checkbox
                                        checked={!isEmpReimburse}
                                        onChange={() =>{
                                            setIsEmpReimburse(!isEmpReimburse);
                                        }}
                                    />
                                }
                                label="Employee Reimbursement"
                            />
                            <Autocomplete
                                size="small"
                                id="is_emp"
                                disabled={isEmpReimburse}
                                getOptionLabel={(option) => option.name || ''}
                                value={selectedEmployee}
                                options={[{ name: "Add Employee", id: "add_new" }, ...employeeList]}
                                onChange={(e, newValue, reason) => onEmployeeChange(e, newValue, reason)}
                                style={MuiFormControlroot}
                                renderInput={(params) => 
                                    <TextField {...params} color="secondary" variant="outlined"  placeholder="Select Employee"
                                        //onClick={(e) => updateHash(billFromObj)}
                                    />}
                                //onInputChange={(e, newValue) => onEmployeeInputChange(e, newValue)}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderOption={(option) => 
                                    option.id === "add_new" ? (
                                        <Button variant="text" color="primary" style={{ width: '100%' }}
                                            onClick={()=>handleEmployeeDrawerOpen()}>
                                            <AddOutlinedIcon fontSize='small'
                                                style={{
                                                    marginRight:'8px',
                                                        fontSize: '16px'
                                                        }}
                                            />
                                            Add Employee
                                        </Button>
                                    ) : (
                                        option.name
                                    )
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item container 
                        alignItems="center" 
                        style={{ marginTop: '12px'}}>
                        <Grid item md={12}>
                            <FormControlLabel
                                style={{marginBottom: "-4px"}}
                                control={
                                    <Checkbox
                                        checked={!isClaimExpense}
                                        onChange={() =>{
                                            setIsClaimExpense(!isClaimExpense);
                                        }}
                                    />
                                }
                                label="Claim Expense"
                            />
                            <Autocomplete
                                size="small"
                                id="is_emp"
                                disabled={isClaimExpense}
                                getOptionLabel={(option) => option.name || ''}
                                value={selectedClient}
                                options={clients}
                                onChange={(e, newValue, reason) => onClientChange(e, newValue, reason)}
                                style={MuiFormControlroot}
                                renderInput={(params) => 
                                    <TextField {...params} 
                                        color="secondary" 
                                        variant="outlined"  
                                        placeholder="Select client"
                                        //onClick={(e) => updateHash(billFromObj)}
                                    />}
                                onInputChange={(e, newValue) => onClientInputChange(e, newValue)}
                                getOptionSelected={(option, value) => option.id === value.id}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container style={{ marginTop: '12px', marginBottom: "20px    " }}>
                        <Button variant="contained" color="primary" 
                            style={{ marginTop: '16px' }}
                            onClick={()=>onSubmitHandler()}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Submit'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        }
        </Grid>
        {openDrawer && <CreateTaxPresetDrawer openDrawer={openDrawer}
            handleDrawerClose={handleDrawerClose}
        />
    }
        {openBillCategoryDrawer && <AddEditBillCategoriesDrawer 
            openDrawer={openBillCategoryDrawer} 
            handleDrawerClose={handleCategoryDrawerClose}
            onAddItemsClick={onAddBillCategoryClick}
        />
        }
        {openBillEmployeeDrawer && <AddEditEmployeeDrawer
            openDrawer={openBillEmployeeDrawer} 
            handleDrawerClose={handleEmployeeDrawerClose}
            onAddItemsClick={onAddBillEmployeeClick}
        />}
        {
            openBillVendorDrawer && 
            <AddEditClientDrawer openClientDrawer={openBillVendorDrawer} 
                handleClientDrawerClose={handleVendorDrawerClose}
                accessLevelViewer={accessLevelViewer}
                addClient={addClient}
                isFromVendors={true}
            />
        }
        </>
    )
}


const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    tdsTypeList: state.invoiceAccounts.tdsTypeList,
});

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdateBillDetailComponent);