import React, {useState, useEffect} from 'react';
import { Dialog, Slide, Grid, Button } from '@material-ui/core';
import {getUrlAccordingToSelectedComponent, ProposalStatus} from '../../util/AppUtil';
import {connect} from 'react-redux';
import {history} from '../../routers/AppRouter';
import {makeStyles} from '@material-ui/core/styles';
import CreateProposal from './create/CreateProposal';
import EditProposal from './edit/EditProposal';
import PreviewProposal from './preview/PreviewProposal';
import { Spinner } from '../Spinner';
import {getProposalBySlugApi} from '../../services/proposalService';
import { consoleToLog } from '../../util/AppUtil';
import { useLocation } from 'react-router-dom';
import ErrorMessageComponent from './ErrorMessageComponent';
import {revokeProposalApi} from '../../services/proposalService';
import { useIsMount } from '../useIsMount';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    MuiDialogpaper: {
        background:theme.palette.primary.light,
        color:'#222',
        position:'relative',
    },

    buttonContainer: {
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        position:'fixed',
        right:0,
        left:0,
        bottom:0,
        padding:'16px 32px',
        background:'#ffffff',
        boxShadow: '0px 0px 15px 0px #00000026'

    }
    }
))

const CreateEditPreviewProposalModal = ({openProposalModal, handleProposalModalClose, setUpdateProposalObj, ...props}) => {

    const classes = useStyles();
    const location = useLocation();
    const isMount = useIsMount();

    const [proposalObj, setProposalObj] = useState(undefined);
    const [editProposalArr, setEditProposalArr] = useState([]);
    const [previewProposalPdfArr, setPreviewProposalArr] = useState([]);
    const [showClientPreview, setShowClientPreview] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);
    const [showSendEmail, setShowSendEmail] = useState(location.state?.showSendEmail ? location.state?.showSendEmail : false);

    const urlParts = window.location.pathname?.split('/');
    const pathname = urlParts[1];
    const slug = urlParts[2];
    const type = urlParts.length > 3 ? urlParts[3] : '';

    useEffect(() => {
        if(type === '') {
                setLoading(false);
                return;
        } else {
            if(slug) {
                getProposalObj(false);
            }
        }
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(type === 'edit' && proposalObj.status !== ProposalStatus.DRAFT && proposalObj?.url_key) {
                callRevokeProposalApi(proposalObj?.id);
            }
        }
    }, [type])

    const {drawerState, selectedAccount, user} = props;
    const invoice_account_id = selectedAccount?.id;
    const duplicate_proposal_obj = location.state?.proposalObj;

    const getProposalObj = async(tryAgain) => {

        tryAgain && setApiLoading(true);

        try {

            const response = await getProposalBySlugApi(invoice_account_id, slug);
            const res = response.data;
            consoleToLog('Response getProposalBySlugApi: ', res);

            if(res.url_key && res.status !== ProposalStatus.DRAFT && type === 'edit') {
                callRevokeProposalApi(res.id);
            } else {
                setProposalObj(res);
                setLoading(false);
                if (type === 'preview') {
                    setPreviewProposalArr(res.sections);
                } else {
                    setEditProposalArr(res.sections);
                }

                showError && setShowError(false);
            }
            
        } catch (error) {
            consoleToLog("Error getEstimateInvoiceDetailsApi", error);
            setShowError(true);
            setLoading(false);
            tryAgain && setApiLoading(false);
        } 
    }

    const callRevokeProposalApi = async(proposal_id) => {

        try {

            const response = await revokeProposalApi(invoice_account_id, proposal_id);
            const res = response.data;
            consoleToLog('Response revokeProposalApi: ', res);

            setProposalObj({...res});
            setUpdateProposalObj({...res});
            if (type === 'preview') {
                setPreviewProposalArr(res.sections);
            } else {
                setEditProposalArr(res.sections);
            }

            showError && setShowError(false);

        } catch (error) {
            consoleToLog("Error revokeProposalApi", error);
            setShowError(true);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    const displayComponents = () => {
        if(editProposalArr && editProposalArr.length > 0) {
            return <EditProposal onCloseProposalDialog={onCloseProposalDialog}
                        editProposalArr={editProposalArr}
                        setEditProposalArr={setEditProposalArr}
                        selectedAccount={selectedAccount}
                        proposalObj={proposalObj}
                        setProposalObj={setProposalObj}
                        drawerState={drawerState}
                        setUpdateProposalObj={setUpdateProposalObj}
                    />
        }
        if (showClientPreview && previewProposalPdfArr && previewProposalPdfArr.length > 0) {
            return <PreviewProposal onCloseProposalDialog={onCloseProposalDialog}
                        previewProposalPdfArr={previewProposalPdfArr}
                        setPreviewProposalArr={setPreviewProposalArr}
                        selectedAccount={selectedAccount}
                        normalUserPreview={false}
                        user={user}
                        proposalObj={proposalObj}
                    />
        } 

        if (!showClientPreview && previewProposalPdfArr && previewProposalPdfArr.length > 0) {
            return <PreviewProposal onCloseProposalDialog={onCloseProposalDialog}
                        previewProposalPdfArr={previewProposalPdfArr}
                        setPreviewProposalArr={setPreviewProposalArr}
                        selectedAccount={selectedAccount}
                        normalUserPreview={true}
                        user={user}
                        proposalObj={proposalObj}
                        onClosePreview={onClosePreview}
                        setShowSendEmail={setShowSendEmail}
                        showSendEmail={showSendEmail}
                        setProposalObj={setProposalObj}
                        drawerState={drawerState}
                        setUpdateProposalObj={setUpdateProposalObj}
                    />
        } 
        
        if (type !== 'preview' && type !== 'edit') {
            return <CreateProposal 
                        selectedAccount={selectedAccount}
                        onCloseProposalDialog={onCloseProposalDialog}
                        setProposalObj={setProposalObj}
                        setEditProposalArr={setEditProposalArr}
                        duplicate_proposal_obj={duplicate_proposal_obj}
                    />
        }
    }

    const onCloseProposalDialog = () => {
        history.push(getUrlAccordingToSelectedComponent(drawerState));
        handleProposalModalClose();
    }

    const FooterButtons = ({isEditProposal, onCancel, onShowPreviewClick, onClosePreview, onComplete}) => {
        return (
            <div className={classes.buttonContainer}>
                <Grid item>
                    {isEditProposal && 
                    <Button 
                        onClick={onCloseProposalDialog}
                        className='footer_btn footer_btn_cancel'>
                        Cancel
                    </Button>}
                </Grid>

                {isEditProposal
                ?
                <Grid item>
                    <Grid item container alignItems='center' spacing={1}>
                        <Grid item>
                            <Button onClick={onShowPreviewClick}
                                className='footer_btn footer_btn_preview'>
                                Preview
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button onClick={onShowNormalPreviewClick} 
                                className='footer_btn footer_btn_finish'>
                                Finish
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                :
                <Grid item>
                    <Grid item container alignItems='center' spacing={1}>
                        <Grid item>
                            <Button onClick={onClosePreview}
                                className='footer_btn footer_btn_cancel'>
                                Close Preview
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button 
                                onClick={() => setShowClientPreview(false)}
                                className='footer_btn footer_btn_finish'>
                                Complete
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                }
            </div>
        )
    }

    const onShowNormalPreviewClick = () => {
        setShowClientPreview(false);
        setPreviewProposalArr([...editProposalArr]);
        history.push(`/proposal/${proposalObj.slug}/preview`);
        setEditProposalArr([]);
    }

    const onShowPreviewClick = () => {
        setShowClientPreview(true);
        setPreviewProposalArr([...editProposalArr]);
        history.push(`/proposal/${proposalObj.slug}/preview`);
        setEditProposalArr([]);
    }

    const onClosePreview = () => {
        setShowClientPreview(false);
        setEditProposalArr([...previewProposalPdfArr]);
        history.push(`/proposal/${proposalObj.slug}/edit`);
        setPreviewProposalArr([]);
    }

    return (
        <Dialog open={openProposalModal} 
            onClose={onCloseProposalDialog} 
            TransitionComponent={Transition}
            scroll="paper"
            classes={{
                paper: classes.MuiDialogpaper}}
            fullScreen>

                {loading ? <Spinner /> : showError ? <ErrorMessageComponent
                                                        onCloseProposalDialog={onCloseProposalDialog}
                                                        fromParentComponent={true}
                                                        getProposalObj={getProposalObj}
                                                        apiLoading={apiLoading}
                                                    /> :  displayComponents()}

                {(previewProposalPdfArr && previewProposalPdfArr.length > 0 && showClientPreview || editProposalArr && editProposalArr.length > 0) &&
                    <FooterButtons 
                        isEditProposal={editProposalArr && editProposalArr.length > 0 ? true : false}
                        onShowPreviewClick={onShowPreviewClick}
                        onClosePreview={onClosePreview}
                    /> 
                }
        </Dialog>
    );
}

export const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    drawerState: state.drawerState.setDrawerState,
    user: state.invoiceAccounts.user
})

export default connect(mapStateToProps)(CreateEditPreviewProposalModal);