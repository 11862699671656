export const addSelectedStatusToDocumentFilter = (status) => ({
    type: 'ADD_SELECTED_STATUS_TO_DOCUEMNT_FILTER',
    status
});

export const removeSelectedStatusFromDocumentFilter = (status) => ({
    type: 'REMOVE_SELECTED_STATUS_FROM_DOCUEMNT_FILTER',
    status
});

export const clearSelectedDocumentFilters = () => ({
    type: 'CLEAR_SELECTED_DOCUEMNT_FILTERS'
});