import moment from 'moment';



const defaultState = {
    selectedStatusDocumentFilter: ['draft', 'pending_signature', 'void', 'completed'],
}


export default (state = defaultState, action) => {
    switch(action.type) {
            
        //status filters
        case 'ADD_SELECTED_STATUS_TO_DOCUEMNT_FILTER':
            return {
                ...state,
                selectedStatusDocumentFilter: [
                    ...state.selectedStatusDocumentFilter,
                    action.status
                ]
            }
        
        case 'REMOVE_SELECTED_STATUS_FROM_DOCUEMNT_FILTER':
            return {
                ...state,
                selectedStatusDocumentFilter: state.selectedStatusDocumentFilter.filter((s) => s !== action.status)
            }   
        
        //clear filters
        case 'CLEAR_SELECTED_DOCUEMNT_FILTERS' :
            return defaultState     
        
        default:
            return state;
    }
}