import React, {useState, useEffect, useLayoutEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container, Grid, Typography, Button, Tooltip, ClickAwayListener, CircularProgress, Link, Divider, IconButton} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import {connect} from 'react-redux';
import { consoleToLog, showDate, statusColor, trackGAEvent, AccessLevel, showListItemDate, AppRoutes, claimStatusColor } from '../util/AppUtil';
import FilterDrawer from './FilterDrawer';
import { useIsMount } from './useIsMount';
import moment from 'moment';
import { logout } from '../actions/invoiceAuth';
import { setClearInvoiceAccounts, SendEmailType} from '../actions/invoiceAccount';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { SelectedDrawerState, setSelectedDrawerState } from '../actions/drawer';
import { useSnackbar } from 'notistack';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import {createDuplicateInvoiceApi, getBillListApi, deleteBillApi, cancelBillApi, reopenBillApi, getVendorBillsInvoicesApi} from '../services/authService';
import { InvoiceStatus, InoviceTypes } from '../util/AppUtil';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {OverlayMessage} from '../util/AppUtil'; 
import { setOvelayMessage, setOverlayToken, setOverlayView, OverlayView, setApiRoute } from '../actions/overlay';
import { formatAmount } from '../util/currencyUtil';
import { usePropsContext } from './context';
import { history } from '../routers/AppRouter';
import useRestoreComponentState from './useRestoreComponentState';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { isInvoiceStatusCancelled } from '../util/AppUtil';
import ShowConfirmDialog from './ShowConfirmDialog';
import {displayInvoiceTypeLabel} from '../util/AppUtil';
import {Popover, MenuItem} from '@material-ui/core';
import {setInvoiceSortByFilter} from '../actions/invoiceFilters';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import SwapVertOutlinedIcon from '@material-ui/icons/SwapVertOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import BillFilterDrawer from './BillFilterDrawer';
import { exportBillsApi, billDownloadApi } from '../services/authService';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        transition: ".5s", 
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    container: {
        marginTop: "8px"
    },
    MuiButtonlabel : {
        fontSize: "12px"
    },
    buttonPlusIcon: {
        marginRight:'6px', 
        fontSize:'1.2rem', 
        marginTop:'-2px'
    },
    icons : {
        color: "black",
        fontSize: "15px",
        minWidth:'20px',
        lineHeight: '10px',
    },
    headings: {
        fontWeight: '600',
        [theme.breakpoints.down(1400)]: {
            fontSize:'14px'
        },
    },
    invoiceListContainer : {
        background:'white', 
        minWidth:"100%", 
        borderRadius:'4px', 
        padding:'0px 16px 0px 16px', 
        color:'#2B2B2B',
    },
    invoiceListHover: {
        padding:'16px 0px',
        position:'relative',
        '& .action__icon' : {
            padding:'4px 4px 0px 4px',
            border:'1px solid white',
            borderRadius:'4px',
            position:'relative',
            top:'2px'
        },
        '&:hover .action__icon' : {
            border:'1px solid #ccc'
        },
        '&:hover':{
            cursor:"pointer"
        },
        '& .more_actionButton' : {
            visibility:'hidden',
            width:'150px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
        },
        '& .more_actionButton1' : {
            visibility:'hidden',
            width:'120px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
        },
        '& .more_actionButton2' : {
            visibility:'hidden',
            width:'180px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
            display:'flex',
            alignItems:'center'
        },
        '&:hover .more_actionButton' : {
            visibility:'visible'
        },
        '&:hover .more_actionButton1' : {
            visibility:'visible'
        },
        '&:hover .more_actionButton2' : {
            visibility:'visible'
        },
    },
    MuiPopoverpaper: {
        marginTop:'20px',
        marginLeft: "-88px",
        border: '1px solid #eef0f2',
        borderRadius: '6px',
        boxShadow: '0 4px 8px rgb(82 91 100 / 10%)',
        boxSizing: 'border-box',
        minWidth: '180px'
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        marginTop:'3%'
    },
    // customColumn2: {
    //     flexBasis:'26.5%', 
    //     maxWidth:'26.5%'
    // },
    // customColumn3: {
    //     flexBasis:'26.5%', 
    //     maxWidth:'26.5%'
    // },
    marginDropdown: {
        right: '2.4%',
        [theme.breakpoints.down(1400)] :{
            right: '2.6%'
        },
        [theme.breakpoints.down(1300)] :{
            right: '2.8%'
        }
    },
    marginDropdown1: {
        right: '2.6%',
        [theme.breakpoints.down(1400)] :{
            right: '2.8%'
        },
        [theme.breakpoints.down(1300)] :{
            right: '3%'
        }
    },
    // Responsive table columns
    customColumn1: { //Invoice Number Column
        flexBasis: '4%',
        maxWidth: '4%',
        [theme.breakpoints.down(1400)]: { // Adjust for screen sizes < 1400px
            flexBasis: '6%',
            maxWidth: '6%',
        },
        [theme.breakpoints.down(1200)]: { // Adjust for screen sizes < 1200px
            flexBasis: '8%',
            maxWidth: '8%',
        },
    },
    customColumn2: { // Status Column
        flexBasis: '9%',
        maxWidth: '9%',
        [theme.breakpoints.down(1400)]: {
            flexBasis: '12%',
            maxWidth: '12%',
        },
        [theme.breakpoints.down(1200)]: {
            flexBasis: '14%',
            maxWidth: '14%',
        },
    },
    // Apply ellipsis for long text in tables
    textEllipsis: {
        maxWidth: '210px !important',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down(1500)]: {
            maxWidth: '140px !important',
        },
        [theme.breakpoints.down(1200)]: {
            maxWidth: '110px !important',
        },
    },
    textEllipsis: {
        width:'auto',
        maxWidth: '210px !important',
        whiteSpace:  'nowrap !important',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis !important',
        [theme.breakpoints.down(1950)] : {
            maxWidth: '160px !important',
        },
        [theme.breakpoints.down(1500)] : {
            maxWidth: '140px !important',
        },
        [theme.breakpoints.down(1300)] : {
            maxWidth: '130px !important',
        }
    },
    textEllipsis1: {
        width:'auto',
        maxWidth: '200px !important',
        whiteSpace:  'nowrap !important',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis !important',
        [theme.breakpoints.down(1950)] : {
            maxWidth: '170px !important',
        },
        [theme.breakpoints.down(1500)] : {
            maxWidth: '140px !important',
        },
        [theme.breakpoints.down(1300)] : {
            maxWidth: '110px !important',
        }
    },
    textEllipsis2: {
        maxWidth: '25ch !important',
        whiteSpace:  'nowrap !important',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis !important',
        [theme.breakpoints.down(1500)] : {
            maxWidth: '23ch !important',
        },
        [theme.breakpoints.down(1300)] : {
            maxWidth: '19ch !important',
        }
    },
    actionsIcons: {
        fontSize: '18px',
    },
    stickyTopBar : {
        position:'sticky', 
        top:0, 
        zIndex:'999', 
        backgroundColor:theme.palette.primary.light,
        
    },
    proformaTDSColumn1: {
        flexBasis:'8% !important',
        maxWidth:'8% !important',
    },
    proformaTDSColumn2: {
        flexBasis: '9%',
        maxWidth: '9%',
    },
    proformaTDSColumn3: {
        maxWidth: '24%',
        flexWidth:'24%',
    },
    statusCellWidth: {
        width:'68px',
        [theme.breakpoints.down(1470)] : {
            width: '68px',
        }
    },
    billNumberStyle:{
        [theme.breakpoints.down(1470)] : {
            padding: "4px",
            width:'88px',
        }
    },
    billdateStyle:{
        [theme.breakpoints.down(1470)] : {
            padding: "4px",
            width:'90px',
        }
    },
    entityStyle:{
        [theme.breakpoints.down(1470)] : {
            padding: "4px",
        }
    },
    filterStyle:{
        width:'90px',
        [theme.breakpoints.down(1470)] : {
            padding: "4px",
        }
    },
    statusStyle: {
        padding: "16px 6px 16px 16px"
    },
    buttonContainer: {
        display:'flex',
        justifyContent:'flex-start',
        position:'absolute', 
        right:'44px',
        [theme.breakpoints.down(1950)] : {
            right:'42px',
        },
        [theme.breakpoints.down(1450)] : {
            right:'41px',
        }
    },
    invoiceTypeStyle: {
        fontSize:'10px',
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        padding:'3px',
        marginRight:'3px'
    },
    invoiceNoCol: {
        width:'6px',
        [theme.breakpoints.down(1400)] : {
            width:'5px',
        },
        [theme.breakpoints.down(1300)] : {
            width:'2px',
        },
    },
    customHeaderLastColumn: {
        width: '150px',  // Set a fixed width to ensure the icons have enough space
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '10px',   // Add some space between the icons
        whiteSpace: 'nowrap',  // Prevent icons from wrapping
    },

}))

const updateInvoiceItem = (invoiceItem, updatedInvoiceObj) => {
    return {
        ...invoiceItem,
        slug: updatedInvoiceObj.slug,
        bill_number: updatedInvoiceObj.bill_number,
        description: updatedInvoiceObj.description,
        subtotal: updatedInvoiceObj.subtotal,
        due: updatedInvoiceObj.due,
        total: updatedInvoiceObj.total,
        status: updatedInvoiceObj.status,
        ocr_status: updatedInvoiceObj.ocr_status,
        bill_date: updatedInvoiceObj.bill_date,
        due_date: updatedInvoiceObj.due_date,
        entity_id: updatedInvoiceObj.entity?.id,
        claim_client_id: updatedInvoiceObj.client?.id,
        vendor_id: updatedInvoiceObj.vendor?.id,
        tds_amount: updatedInvoiceObj.tds_amount,
        entity_name: updatedInvoiceObj.entity?.name,
        claim_client_name: updatedInvoiceObj.client?.name,
        employee_name: updatedInvoiceObj.employee?.name,
        vendor_name: updatedInvoiceObj.vendor?.name,
        currency: updatedInvoiceObj.currency,
        is_reimbursed: updatedInvoiceObj.is_reimbursed,
        can_edit: updatedInvoiceObj.can_edit,
        can_cancel: updatedInvoiceObj.can_cancel,
        can_reopen: updatedInvoiceObj.can_reopen,
        can_add_payment: updatedInvoiceObj.can_add_payment,
        claim_status: updatedInvoiceObj.claim_status,
        tds_type_object: updatedInvoiceObj.tds_type_object,
    };
};

const BillListComponent =  (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [isActive, setIsActive] = useState(false);
    const [menuId, setMenuId] = useState('');
    //const [openDialog, setOpenDialog] = useState(false);
    const [invoicesList, setInvoicesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
    const [total, setTotal] = useState();
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [id, setId] = useState(props.selectedAccount?.id);
    const [animationActive, setAnimationActive] = useState(false);
    const [items, setItems] = useState([]);
    const [apiLoading, setApiLoading] = useState(false);
    const [nextPage, setNexPage] = useState(false);
    
    const { fullName, handleOpenAddPaymentBillDrawer, handleInvoiceDialogOpen, handleEditPreviewBillDialogOpen, billComponentState, 
        setBillComponentState, setPaymentBillObj, paymentBillObj, updatedBillObj, setUpdatedBillObj} = usePropsContext();

    const { setScrollContainer, saveState, restoreState } = useRestoreComponentState(
        billComponentState, 
        setBillComponentState, 
    );
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [invoiceItem, setInvoiceItem] = useState(undefined);
    const [anchorEl, setAnchorEl] = useState(null);

    const enable_tds = props.selectedAccount?.enable_tds;


    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;
    const entityList = props.selectedAccount?.entities;
    const showTDS = props.selectedAccount?.enable_tds;
    const restoredState = restoreState();

    // useEffect(() => {
    //     getBillList(page);
    // }, []);

    useEffect(() => {
        if(!props.fromClientDetails) {
            document.title = `Bills - ${fullName}`;
        }
    }, []);

    useLayoutEffect(() => {
        
        if (Object.keys(restoredState).length > 0) {
            setInvoicesList(restoredState.invoicesList);
            setNexPage(restoredState.nextPage);
            setPage(restoredState.page);
            setTotal(restoredState.total);
        } 
    }, [restoredState]);

    useEffect(() => {
        if(props.fromClientDetails && props.clientInfo && Object.keys(restoredState).length === 0) {
            getVendorBills(page);
        } else {
            if(Object.keys(restoredState).length === 0) {
                getBillList(page);
            }
        }
    }, [id]);
    
    useEffect(() => {
        if(!isMount) {
            setId(props.selectedAccount?.id);
            setPage(1);
        }
    }, [props.selectedAccount?.id]);

    /*
    useEffect(() => {
        setId(props.selectedAccount?.id)
    }, [props.selectedAccount?.id])

   */


    useEffect(() => {
        if(!isMount) {
            if (isMoreLoading) {
                if(props.fromClientDetails && props.clientInfo) {
                    getVendorBills();
                } else {
                    getBillList();
                }
            }
        }
    }, [isMoreLoading]);

    useEffect(() => {
        if (!isMount) {
                consoleToLog('filter changed');
                getBillList();
        }
    }, [props.issueStartDateFilter, props.issueEndDateFilter, props.invoiceClientsFilter, props.selectedStatusBillFilter, 
        props.selectedTypeInvoiceFilter, props.selectedEntitiesFilter, props.invoiceSortByFilter, props.unclaimedBills]);

    useEffect(() => {
        if(!isMount) {
            //console.log("useEffect bi bi", paymentBillObj);
            if(paymentBillObj) {
                updateInvoiceListItem()
            }

        }
    }, [paymentBillObj]);
    
    useEffect(() => {
        if(updatedBillObj && invoicesList && invoicesList.length > 0) {
            const invoiceObjPresent = invoicesList.find((invoiceItem) => invoiceItem.id === updatedBillObj?.id);
            if(invoiceObjPresent) {
                const updatedInvoicesArr = invoicesList.map((invoiceItem) => 
                    invoiceItem.id === updatedBillObj?.id ?  updateInvoiceItem(invoiceItem, updatedBillObj) : invoiceItem
                );
                setInvoicesList([...updatedInvoicesArr]);
                setUpdatedBillObj(undefined);
            } else {
                //setInvoicesList(oldInvoices => [updatedInvoiceObj, ...oldInvoices]);
                setUpdatedBillObj(undefined);
            }
        }
    }, [updatedBillObj, invoicesList]);

    /* useEffect(() => {
        if(!isMount) {
            if(callgetInvoiceListApi) {
                pageReset();
                if(page === 1) {
                    if(props.fromClientDetails && props.clientInfo) {
                        getVendorBills();
                    } else {
                        getInvoiceList();
                    }
                    setCallGetInvoiceListApi(false);
                }
            }
        }
    }, [callgetInvoiceListApi, page]); */


    const pageReset = () => {
        setPage(1);
        // if(page === 1) {
        //     getInvoiceList();
        // }
    }

    let count = 0;
    if (props.invoiceClientsFilter.length > 0) {
        count = count + 1
    }

    let status_filter = props.selectedStatusBillFilter.filter((status) => status === 'paid')
    if(props.selectedStatusBillFilter.length < 5) {
        count = count + 1
    } 
    
    if(props.selectedTypeInvoiceFilter.length < 3) {
        count = count + 1
    }

    if (props.selectedEntitiesFilter.length > 0) {
        count = count + 1
    }

    const clearRedux = () => {
        props.logout();
        props.setClearInvoiceAccounts();
        props.clearSelectedInvoiceFilters();
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD);
    }

    const getBillList = (pageNo) => {
        const invoice_account_id = props.selectedAccount?.id;
        const issue_start_date = props.issueStartDateFilter;
        const issue_end_date = props.issueEndDateFilter; 
        const vendors = null//props.invoiceClientsFilter?.map((client) => client.id)?.join(); 
        const status = props.selectedStatusBillFilter?.map((status) => status)?.join();
        //const type = props.selectedTypeInvoiceFilter.length > 0 ? props.selectedTypeInvoiceFilter?.map((type) => type)?.join() : undefined;
        const entities = props.selectedEntitiesFilter && props.selectedEntitiesFilter.length > 0 ? props.selectedEntitiesFilter.map((entity) => entity.id).join() : undefined;
        const order_by = props.invoiceSortByFilter;
        const unclaimed_bills = props.unclaimedBills ? "1" : "0";

        if(!isMoreLoading) setLoading(true);
        getBillListApi(invoice_account_id, pageNo ? pageNo : page, issue_start_date, issue_end_date, vendors, status, entities, order_by, unclaimed_bills)
        .then((response) => {
            const res = response.data;
            setLoading(false);
            consoleToLog('Respone getBillList', res);
            setItems(res.purchase_invoices);
            
            if(isMoreLoading) {
                const newItems = invoicesList.concat(res.purchase_invoices);
                setInvoicesList(newItems);
                setPage(page + 1);
                setIsMoreLoading(!isMoreLoading);
                setNexPage(res.next_page);
            } else {
                if(res.next_page){ setPage(page + 1);}
                setNexPage(res.next_page);
                setInvoicesList(res.purchase_invoices);
                setTotal(res.total);
            }
        })
        .catch((e) => {
            if(e.response && e.response.status === 401 && e.response.data && e.response.data.detail) {
                clearRedux();
                enqueueSnackbar( e?.response?.message, {variant:'error'});
            }
            consoleToLog('Error getBillList', e);
            setLoading(false);
            if (e.response && e.response.status !== 401 && e.response.data && e.response.data.message) {
                enqueueSnackbar( e.response.data.message, {variant:'error'});
                return false;
            }
        });
    }

    const getVendorBills = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const client_id = props.clientInfo?.id;

        setLoading(true);
        getVendorBillsInvoicesApi(invoice_account_id, client_id, page)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getVendorBillsInvoicesApi for vendor: ', res);
                setLoading(false);

                setItems(res.purchase_invoices);

                if(isMoreLoading) {
                    const newItems = invoicesList.concat(res.purchase_invoices)
                    setInvoicesList(newItems);
                    setPage(page + 1);
                    setIsMoreLoading(!isMoreLoading);
                    setNexPage(res.next_page);
                } else {
                    if(res.next_page){ setPage(page + 1);}
                    setInvoicesList(res.purchase_invoices);
                    setTotal(res.total);
                    setNexPage(res.next_page);
                }


            })
            .catch((e) => {
                consoleToLog('Error getVendorBillsInvoicesApi for vendor: ', e);
                setLoading(false);
                if(e.respose.data && e.response.data.message) {
                    return;
                }
            })
    }

    const storeBillState = (fromEdit, invoice) => {
        const stateObj = {
            invoicesList,
            nextPage,
            page,
            total,
            ...(props.fromClientDetails && {
                clientListState: {...props.clientComponentObj},
                clientInfo: {...props.showClientInfo},
                tabValue: props.tabValue,
                clientScrollPosition: props.scrollContainer?.scrollTop
            }),
        };
        saveState(stateObj);
        (fromEdit || invoice.status==="draft") ? history.push(`/bill/${invoice.slug}/edit`) : 
        history.push(`/bill/${invoice.slug}/preview`);
    }

    const onEditInvoiceClick = (invoice) => {
        trackGAEvent(props.selectedAccount.name, 'Bill list Item - Edit bill clicked', `${props.user.firstname} ${props.user.lastname}`);
        storeBillState(true, invoice, false);
        //history.push(`/bill/${invoice.slug}/edit`)
        handleEditPreviewBillDialogOpen(invoice.entity);
        closeOptionsMenu();
    }

    const onPreviewInvoiceClick = (e, invoice) => {
        e.stopPropagation();
        trackGAEvent(props.selectedAccount.name, 'Bill list Item - Preview button clicked', `${props.user.firstname} ${props.user.lastname}`);
        storeBillState(false, invoice, false);
        //history.push(`/bill/${invoice.slug}/preview`)
        handleEditPreviewBillDialogOpen(invoice.entity);
        closeOptionsMenu();

    }

    const onSendInvoiceClick = (e, invoice) => {
        e.stopPropagation();
        trackGAEvent(props.selectedAccount.name, 'Send button clicked', `${props.user.firstname} ${props.user.lastname}`);
        storeBillState(false, invoice, true, invoice.invoice_type);
        handleInvoiceDialogOpen(true);
        closeOptionsMenu();

    }

    const handleOpenFilterDrawer = () => {
        setOpenFilterDrawer(true);
    }

    const handleCloseFilterDrawer = () => {
        setOpenFilterDrawer(false);
    }

    const closeOptionsMenu = () => {
        setIsActive(false);
        setMenuId('');
    }

    const onDownloadBillClick = (invoice) => {
        const invoice_account_id = props.selectedAccount?.id;
        const entity_id = invoice.entity_id;
        const purchase_invoice_id = invoice.id;

        billDownloadApi(invoice_account_id, entity_id, purchase_invoice_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response billDownloadApi: ', res);

                const downloadableURL = res.download_signed_url;

                //initiate download
                document.getElementById('bill_download').href = downloadableURL;
                document.getElementById('bill_download').click();
                closeOptionsMenu();

                trackGAEvent(props.selectedAccount.name, 'Purchase Invoice list Item - Bill downloaded', `${props.user.firstname} ${props.user.lastname}`);

            })
            .catch((e) => {
                consoleToLog('Error billDownloadApi: ', e.response);
                closeOptionsMenu();
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {nextPage && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    const updateInvoiceListItem = () => {
        //console.log("bi bi", paymentBillObj);
        let updated_array = invoicesList.map((invoice) => {
            if(invoice.id === paymentBillObj?.purchase_invoice?.id) {
                let obj = paymentBillObj?.purchase_invoice;
                return {
                    ...invoice,
                    paid: obj.paid,
                    status: obj.status,
                    due: obj.due,
                    can_edit: obj.can_edit,
                    can_add_payment: obj.can_add_payment,
                    can_cancel: obj.can_cancel,
                    can_reopen: obj.can_reopen
                }
            } else 
            return invoice;
        });

        setInvoicesList(updated_array);
        setPaymentBillObj(undefined);
    }

    const onDeleteInvoiceClick = (e, invoice) => {
        e.stopPropagation();
        
    }

    const callDeleteInvoiceApi = (invoice_account_id, bill_id) => {
        setAnimationActive(true);
        closeOptionsMenu();
        setApiLoading(true);

        deleteBillApi(invoice_account_id, bill_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response deleteBillApi: ', res);
                enqueueSnackbar('Bill deleted successfully!!', {variant: 'success'});

                const updated_array = invoicesList.filter((invoice) => invoice.id !== bill_id);
                setInvoicesList(updated_array);
                setTotal(total - 1);
                setAnimationActive(false);
                setApiLoading(false);

                trackGAEvent(props.selectedAccount.name, 'Bill deleted', `${props.user.firstname} ${props.user.lastname}`);

                handleConfirmDialogClose();
            })
            .catch((e) => {
                consoleToLog('Error deleteInvoiceApi: ', e.response);
                setAnimationActive(false);
                setApiLoading(false);
                closeOptionsMenu();
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    const createDuplicateInvoice = (invoice) => {
        const invoice_account_id = props.selectedAccount?.id;
        const invoice_id = invoice.id;

        closeOptionsMenu();
        createDuplicateInvoiceApi(invoice_account_id, invoice_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response createDuplicateInvoiceApi: ', res);
                enqueueSnackbar('Invoice duplicated successfully', {variant:'success'});

                setInvoicesList([res, ...invoicesList]);
            })
            .catch((e) => {
                consoleToLog('Error createDuplicateInvoiceApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    const onExportBillsClick = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const issue_start_date = props.issueStartDateFilter; 
        const issue_end_date = props.issueEndDateFilter; 
        const vendors =  props.invoiceClientsFilter &&  props.invoiceClientsFilter.length > 0 ? props.invoiceClientsFilter?.map((client) => client.id)?.join() : null; 
        const status = props.selectedStatusBillFilter?.map((status) => status)?.join();
        const entities = props.selectedEntitiesFilter && props.selectedEntitiesFilter.length > 0 ? props.selectedEntitiesFilter.map((entity) => entity.id).join() : undefined;
        const unclaimed_bills = props.unclaimedBills ? "1" : "0";

        props.setApiRoute('purchase_invoice_export');
        exportBillsApi(invoice_account_id, issue_start_date, issue_end_date, entities, vendors, status, unclaimed_bills)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response exportBillsApi: ', res);

                props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
                props.setOverlayToken(res.task_id);
                props.setOvelayMessage(OverlayMessage.EXPORT_MSG);
            })
            .catch((e) => {
                consoleToLog('Error exportBillsApi: ', e);
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setOverlayToken("");
                if(e.response.data && e.response.data.message){
                    enqueueSnackbar(e.response.data.message, {variant:"error"});
                    return;
                }
            })
    }

    const updateInvoiceObjInArray = (res) => {
        const updatedArray = invoicesList.map((invoice) => {
            if(invoice.id === res.id) {
                return {
                    ...invoice,
                    status: res.status,
                    //invoice_type: res.invoice_type,
                    can_cancel: res.can_cancel,
                    can_add_payment: res.can_add_payment,
                    //can_convert_to_draft: res.can_convert_to_draft,
                    can_edit: res.can_edit,
                    can_reopen: res.can_reopen,
                    //recurring_invoice: res.recurring_invoice
                }
            }
            return invoice
        })
        setInvoicesList([...updatedArray]);
    }

    const onCancelInvoiceClick = (e, invoice) => {
        e.stopPropagation();
        closeOptionsMenu();

        const invoice_account_id = props.selectedAccount?.id;
        const invoice_id = invoice.id;
        const cancel_recurring_invoice = false;
        const recurring_invoice_id = undefined;
        callCancelInvoiceApi(invoice_account_id, invoice_id, cancel_recurring_invoice, recurring_invoice_id);

    }

    const callCancelInvoiceApi = async (invoice_account_id, bill_id) => {

        setApiLoading(true);

        try {
            const response = await cancelBillApi(invoice_account_id, bill_id);
            
            consoleToLog("***Response cancelBillApi: ", response.data);
            const res = response.data;
            updateInvoiceObjInArray(res);
            
            enqueueSnackbar('Bill updated successfully', { variant: 'success'});
            setApiLoading(false);

            handleConfirmDialogClose();
            
        } catch (e) {
            consoleToLog("Err: cancelBillApi", e);
            setApiLoading(false);
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, { variant: 'error' });
            }
        }
    }

    const callReopenBillApi = async (invoice_account_id, bill_id) => {

        setApiLoading(true);

        try {
            const response = await reopenBillApi(invoice_account_id, bill_id);
            
            consoleToLog("***Response cancelreopenBillApiBillApi: ", response.data);
            const res = response.data;
            updateInvoiceObjInArray(res);
            
            enqueueSnackbar('Bill reopened successfully', { variant: 'success'});
            setApiLoading(false);

            handleConfirmDialogClose();
            
        } catch (e) {
            consoleToLog("Err: cancelreopenBillApiBillApi", e);
            setApiLoading(false);
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, { variant: 'error' });
            }
        }
    }

    const handleConfirmDialogOpen = (invoice, obj) => {
        setOpenConfirmDialog(true);

        let invoice_obj = {
            ...invoice,
            ...obj
        }
        setInvoiceItem(invoice_obj);
        closeOptionsMenu();
    }

    const handleConfirmDialogClose = () => {
        setOpenConfirmDialog(false);
        setInvoiceItem(undefined);
    }

    const handleSortMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSortMenuClose = (event) => {
        setAnchorEl(null);
    };

    const selectSortValue = (value) => {
        trackGAEvent(props.selectedAccount.name, 'Invoices Sort', `${props.user.firstname} ${props.user.lastname} `);
        props.setInvoiceSortByFilter(value);
        pageReset();
        setAnchorEl(null);
    } 

    const getClaimStatus = (status, client_name)=>{
        if(status === 'not_a_claim') {
            return 'Not a claim'
        } else if(status === 'unclaimed') {
            return `Unclaimed from ${client_name}`
        } else if(status === 'claimed') {
            return `Claimed from ${client_name}`
        } else if(status === 'paid') {
            return `Paid from ${client_name}`
        } 
    }

    const getReimbursmentStatus = (invoice)=>{
        if(invoice.employee_name) {
            return `Reimburse to ${invoice.employee_name}`;
        } else {
            return "Not a reimbursement"
        }
    }

    return (
        <div className={classes.mainContent}
            ref={(node) => setScrollContainer(node)}
            id='invoices-container'
            style={{
                height: '100%',
                backgroundColor: props.fromClientDetails ? '#ffffff' : '#f7f7f7'}}>

            <Container className={classes.container} 
                maxWidth="xl" style={{padding: "0px 16px"}}>

                {loading 
                ?
                <Grid item container justifyContent='center' style={{marginTop:'32px'}}> 
                    <CircularProgress size={25} />
                </Grid>
                :
                <Paper variant='outlined' elevation={0} style={{marginTop:'10px', marginBottom:'24px', width:'100%'}}>
                    <TableContainer style={{height:'100%', overflow:'visible'}}>
                        <Table>
                        <TableHead style={{position:'sticky', top:'0px', zIndex:99, background:'#f7f7f7'}}>
                            <TableRow>
                                <TableCell className={`tableheading_style ${classes.invoiceNoCol}`}></TableCell>
                                <TableCell className={`tableheading_style ${classes.invoiceNoCol}`}></TableCell>
                                <TableCell className='tableheading_style'>Status</TableCell>
                                <TableCell className={`tableheading_style ${classes.billNumberStyle}`}>Bill No.</TableCell>
                                <TableCell className={`tableheading_style ${classes.billdateStyle}`}>Bill Date</TableCell>
                                {entityList && entityList.length > 1 && <TableCell className={`tableheading_style ${classes.entityStyle}`}>Entity</TableCell>}
                                <TableCell className={`tableheading_style ${classes.entityStyle}`}>Vendor Name</TableCell>
                                <TableCell className='tableheading_style'>Total</TableCell>
                                <TableCell className='tableheading_style'>Due</TableCell>
                                {!props.fromClientDetails &&
                                    <TableCell className={`tableheading_style ${classes.filterStyle}`}>
                                        <div style={{display:'flex', alignItems:'center'}}> 
    
                                            <div className='table_header_icon_column'>
                                                <Tooltip title="Filters" arrow>
                                                    <Button
                                                        onClick={handleOpenFilterDrawer}  
                                                        className={classes.icons}
                                                        >
                                                        {<img src='/images/filter_icon.svg' 
                                                        className={(count && count > 0) ? `filter_icon active` : 'filter_icon'}/>}
                                                        {   count && count > 0 ?
                                                            <div className='filter__dot'></div> : <div></div>
                                                        }
                                                    </Button>
                                                </Tooltip>
                                            </div>
    
                                            <div className='table_header_icon_column'>
                                                <Tooltip title="Export" arrow>
                                                    <Button
                                                        onClick={onExportBillsClick}  
                                                        className={classes.icons}
                                                        >
                                                        <CloudUploadOutlinedIcon className='table_header_icons'/>
                                                    </Button>
                                                </Tooltip>
                                            </div>
    
                                            <div className='table_header_icon_column'>
                                                <Tooltip title="Sort" arrow>
                                                    <Button aria-label="sort" 
                                                        onClick={handleSortMenuClick} 
                                                        className={classes.icons}>
                                                        <SwapVertOutlinedIcon className='table_header_icons'/>
                                                    </Button>
                                                </Tooltip>
    
                                                <Popover
                                                    id="sort-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleSortMenuClose}
                                                    style={{marginTop:'20px'}}
                                                >
                                                    <MenuItem 
                                                        selected={props.invoiceSortByFilter === 'created_at'}
                                                        value="created_at"
                                                        onClick={(e) => selectSortValue("created_at")}>Latest First</MenuItem>
                                                    <MenuItem 
                                                        selected={props.invoiceSortByFilter === 'bill_date'}
                                                        value="issue_date"
                                                        onClick={(e) => selectSortValue("bill_date")}>Bill Date</MenuItem>
                                                </Popover>
                                            </div>
    
                                        </div>
                                    </TableCell>}
                            </TableRow>
                        </TableHead>
                        {loading ?
                        <TableBody>
                            <TableRow hover className={classes.invoiceListHover}>
                                <TableCell colSpan={entityList && entityList.length > 1 ? 9 : 8} align='center'>
                                    <CircularProgress size={25} style={{margin:'24px 0px'}}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        :
                        <TableBody>
                            {
                            invoicesList && invoicesList.length > 0 ? invoicesList.map((invoice) => {
                                let over_due = (moment(invoice?.due_date).toDate().getTime() < Date.now())
                                //let recurr_invoice_status = invoice.recurring_invoice?.status === 'active';
                                const currencyObj = invoice?.currency;
                                return (
                                    <TableRow hover key={invoice.id} className={classes.invoiceListHover}
                                        onClick={(e) => onPreviewInvoiceClick(e, invoice)}
                                        >
                                        <TableCell scope="row" style={{padding:'8px 0px 8px 12px'}}>
                                            <Tooltip title={getReimbursmentStatus(invoice)} arrow>
                                                <AssignmentIndOutlinedIcon style={{fontSize: "14px", color: invoice.employee_name ? "#1a74e6" : "", position:'relative', top:'2px'}}/>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell scope="row"  style={{padding:'8px 8px 8px 4px'}}>
                                            <Tooltip title={`${getClaimStatus(invoice.claim_status, invoice?.claim_client_name)}`} arrow>
                                                <DescriptionOutlinedIcon style={{fontSize: "14px", color: claimStatusColor(invoice.claim_status), position:'relative', top:'2px'}}/>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell className={`tablebody_style ${classes.statusCellWidth}`}
                                            >
                                            <Grid item container alignItems='center'>
                                                <div className='status_style'
                                                    style={{
                                                        background: statusColor(invoice.status.toLowerCase())
                                                        }}>
                                                </div>
                                                <Typography 
                                                    style={{
                                                    color:statusColor(invoice.status.toLowerCase()), 
                                                    fontSize: "10px"
                                                    
                                                }}>
                                                    {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1).toLowerCase()}
                                                </Typography>
                                            </Grid>
                                        </TableCell>
                                        <TableCell className={`tablebody_style ${classes.billNumberStyle}`}>
                                            {invoice.bill_number}
                                        </TableCell>
                                        <TableCell className={`tablebody_style ${classes.billdateStyle}`}>
                                            {invoice.bill_date?<Tooltip title={moment(invoice.bill_date, 'YYYY-MM-DD').format('MMM Do, YY')} arrow>
                                                <Typography variant='subtitle1' className=''>
                                                    {moment(invoice.bill_date, 'YYYY-MM-DD').format('MMM Do, YY')}
                                                </Typography>
                                            </Tooltip> : "-"}
                                        </TableCell>
                                        {entityList && entityList.length > 1 && 
                                            <TableCell className={`tablebody_style ${classes.entityStyle}`}>
                                                <Tooltip title={invoice.entity_name} arrow>
                                                    <Typography variant="subtitle1" 
                                                        className={'text_ellipsis'}>
                                                        {invoice.entity_name}
                                                    </Typography>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                        <TableCell className={`tablebody_style ${classes.entityStyle}`}>
                                            <Tooltip title={invoice.vendor_name} arrow>
                                                <Typography variant="subtitle1" 
                                                    className={'text_ellipsis1'}>
                                                        {invoice.vendor_name}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell className='tablebody_style'>
                                            {formatAmount(invoice.total, currencyObj)}
                                        </TableCell>
                                        <TableCell className='tablebody_style'>
                                            <Typography variant='subtitle1'>
                                                {formatAmount(invoice.due, currencyObj)}
                                            </Typography>
                                        </TableCell>
                                        {!props.fromClientDetails &&
                                        <TableCell className='tablebody_style'>
                                        {!accessLevelViewer &&
                                            <Grid item
                                                    style={{display:'flex',
                                                    alignItems:'center',
                                                    flexDirection:'row-reverse',
                                                    justifyContent:'center',
                                                    position:'relative'}}>
                                                <div style={{position:'absolute', right:'0px'}}>        
                                                    <Tooltip title="More Actions" arrow>  
                                                        <i className="flaticon-more action__icon"
                                                        onClick={(e) => {
                                                                e.stopPropagation();
                                                                setIsActive(!isActive);
                                                                setMenuId(invoice.id);
                                                            }}
                                                        style={{fontSize:'15px', position:'relative', left:'-16px'}}></i>
                                                    </Tooltip>
                                                <>    
                                                {invoice.id === menuId &&
                                                <ClickAwayListener onClickAway={() => {
                                                        setIsActive(false);
                                                        setMenuId('');
                                                }}>
                                                <Grid item className={`menu ${isActive ? `active ${classes.marginDropdown}` : "inactive"}`}>
                                                    <ul>
                                                        {invoice.can_edit &&
                                                            <li onClick={(e) => {
                                                                e.stopPropagation();
                                                                onEditInvoiceClick(invoice)
                                                            }}>
                                                                <a style={{display:'flex', alignItems:'center'}}>
                                                                    <EditOutlinedIcon fontSize='small'  
                                                                    className={`more_actionsIcons ${classes.actionsIcons}`}/>
                                                                    Edit
                                                                </a>
                                                            </li>
                                                        }

                                                        {invoice?.can_download && 
                                                        <li onClick={(e) => {
                                                            e.stopPropagation();
                                                            onDownloadBillClick(invoice);
                                                        }}>
                                                            <a style={{display:'flex', alignItems:'center'}}>
                                                                <CloudDownloadOutlinedIcon fontSize='small'  
                                                                className={`more_actionsIcons ${classes.actionsIcons} icon_position_top`}/>
                                                                Download
                                                                <Link
                                                                    style={{ display: "none" }}
                                                                    id="bill_download"
                                                                    target="_blank"
                                                                    onClick={(e) => e.stopPropagation()}
                                                                    download
                                                                ></Link>
                                                            </a>
                                                        </li>}

                                                        { 
                                                            <li onClick={(e) => onPreviewInvoiceClick(e, invoice)}
                                                            >
                                                                <a style={{display:'flex', alignItems:'center'}}>
                                                                    <VisibilityOutlinedIcon fontSize='small'  
                                                                    className={`more_actionsIcons ${classes.actionsIcons}`}/>
                                                                    Preview
                                                                </a>
                                                            </li>
                                                         }

                                                        {invoice?.can_cancel &&
                                                        <li onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleConfirmDialogOpen(invoice, {cancel_invoice_clicked: true});
                                                        }}>
                                                            <a style={{display:'flex', alignItems:'center'}}>
                                                                <CancelOutlinedIcon fontSize='small'  
                                                                className={`more_actionsIcons ${classes.actionsIcons} icon_position_top`}/>
                                                                Cancel
                                                            </a>
                                                        </li>}
                                                        {invoice?.can_reopen &&
                                                            <li onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleConfirmDialogOpen(invoice, {reopen_bill_clicked: true});
                                                            }}>
                                                                <a style={{display:'flex', alignItems:'center'}}>
                                                                    <UndoOutlinedIcon fontSize='small'  
                                                                    className={`more_actionsIcons ${classes.actionsIcons} icon_position_top`}/>
                                                                    Reopen
                                                                </a>
                                                            </li>}

                                                        <li onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleConfirmDialogOpen(invoice,  {delete_invoice_clicked: true})
                                                            }}>
                                                            <a href="#" style={{display:'flex', alignItems:'center'}}>
                                                                <DeleteOutlinedIcon fontSize='small' 
                                                                className={`more_actionsIcons ${classes.actionsIcons} icon_position_top`}/>
                                                                Delete
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </Grid>
                                                </ClickAwayListener>
                                                }
                                                </>
                                                </div>

                                                {<div className={classes.buttonContainer}>
                                                    {invoice.can_add_payment
                                                    && <Button item className="more_actionButton" 
                                                            onClick={(e)=> handleOpenAddPaymentBillDrawer(e, invoice)}
                                                            >
                                                            <MonetizationOnOutlinedIcon  
                                                                style={{fontSize:'17px', color:'green'}} 
                                                                className='more_actionsIcons'/>
                                                            <span> Add Payment </span>
                                                    </Button>}

                                                    {invoice.can_edit && 
                                                    <Button item className="more_actionButton"
                                                        onClick={(e) => {
                                                                e.stopPropagation();
                                                                onEditInvoiceClick(invoice);
                                                            }}> 
                                                            <EditOutlinedIcon 
                                                            className='more_actionsIcons'
                                                            style={{fontSize:'15px', verticalAlign:'baseline'}}   
                                                            />
                                                        <span>Edit</span>
                                                        
                                                    </Button>}

                                                    {invoice.status === InvoiceStatus.PAID &&
                                                            <Button item className="more_actionButton1"
                                                                onClick={(e) => onPreviewInvoiceClick(e, invoice)}> 
                                                                    <VisibilityOutlinedIcon fontSize='small'  
                                                                    className={`more_actionsIcons ${classes.actionsIcons}`}/> 
                                                                <span> Preview </span>
                                                            </Button>
                                                    }
                                                </div> }
                                            </Grid>  }
                                        </TableCell>}
                                    </TableRow>
                                );
                            })
                            :
                            <TableRow hover className={classes.invoiceListHover}>
                                <TableCell colSpan={entityList && entityList.length > 1 ? 9 : 8} align='center'>
                                    No Bills Found
                                </TableCell>
                            </TableRow>
                            
                            }
                        </TableBody>
                        }
                        </Table>
                    </TableContainer>
                    {!loading && loadMoreLogic()}
                </Paper>
                }

                {/* { openDialog && 
                    <InvoiceModalComponent openDialog={openDialog}
                        handleClickDialogClose={handleClickDialogClose}/>
                } */}
                
                {/* { openAddPaymentDrawer && <AddEditPaymentDrawer openAddPaymentDrawer={openAddPaymentDrawer}
                    handleCloseAddPaymentDrawer={handleCloseAddPaymentDrawer}
                    invoiceObj={invoiceObj}
                    fromInvoiceListPage={true}
                    updateInvoiceListItem={updateInvoiceListItem}
                />} */}

                <BillFilterDrawer openFilterDrawer={openFilterDrawer}
                    handleCloseFilterDrawer={handleCloseFilterDrawer}
                    pageReset={pageReset}
                />

                {
                    openConfirmDialog && <ShowConfirmDialog openConfirmDialog={openConfirmDialog}
                                            handleConfirmDialogClose={handleConfirmDialogClose}
                                            fromInvoiceListComponent={true}
                                            invoiceItem={invoiceItem}
                                            callCancelInvoiceApi={callCancelInvoiceApi}
                                            apiLoading={apiLoading}
                                            callDeleteInvoiceApi={callDeleteInvoiceApi}
                                            callReopenBillApi={callReopenBillApi}
                                            isFromBill={true}
                                        />
                }
            </Container>
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    issueStartDateFilter: state.invoiceFilters.issueStartDateFilter,
    issueEndDateFilter: state.invoiceFilters.issueEndDateFilter,
    invoiceClientsFilter: state.invoiceFilters.invoiceClientsFilter,
    selectedStatusBillFilter: state.invoiceFilters.selectedStatusBillFilter,
    selectedTypeInvoiceFilter: state.invoiceFilters.selectedTypeInvoiceFilter,
    user: state.invoiceAccounts.user,
    selectedEntitiesFilter: state.invoiceFilters.selectedEntitiesFilter,
    drawerState: state.drawerState.setDrawerState,

    invoiceSortByFilter: state.invoiceFilters.invoiceSortByFilter,
    unclaimedBills:  state.invoiceFilters.unclaimedBills
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setSelectedDrawerState: (drawerState) => dispatch((setSelectedDrawerState(drawerState))),
    setClearInvoiceAccounts: () => dispatch((setClearInvoiceAccounts())),
    setInvoiceSortByFilter: (sortBy) => dispatch(setInvoiceSortByFilter(sortBy)),

    setOverlayView: (exportView) => dispatch(setOverlayView(exportView)),
    setOverlayToken: (randomToken) => dispatch(setOverlayToken(randomToken)),
    setOvelayMessage: (overlayMessage) => dispatch(setOvelayMessage(overlayMessage)),
    setApiRoute: (route) => dispatch(setApiRoute(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(BillListComponent);