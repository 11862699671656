import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Typography, Drawer, AppBar, Toolbar,IconButton } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    drawer: {
        flexShrink: 0
    },
    
    drawerPaper1: {
        width: (drawerWidth) => {
            return (drawerWidth===true)? '70%' :'36%';
        },
        height: '100vh',
        flexGrow:1,
        top:'0px',
        overflow:'auto'
    },
    appBar: {
        background:'#F9F9F9',
        height:'45px',
        color:'#202020'
    }
}));

const RightToLeftSideDrawer = (props) => {
    let {drawerWidth} = props;
    //console.log("width", drawerWidth)
    const classes = useStyles(drawerWidth);
    //const [openDrawer, setOpenDrawer] = useState(props.openDrawer);

    return(
        <Drawer variant="temporary"
            anchor="right"
            open={props.openDrawer}
            onClose={props.closeDrawer}
            classes={{
                //paper: props.fromPreviewScreen || props.fromOnboardingComponent ? classes.drawerPaper1 : classes.drawerPaper
                paper: classes.drawerPaper1
                }}
            style={{zIndex:props.fromDueInvoicesDrawer ? 99 : 101}}
            >
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar style={{minHeight:'40px', padding:'0px 16px', }}>
                    <Typography variant="body1" 
                        style={{fontWeight:'600', marginRight:'auto'}}>
                        {props.title}
                    </Typography>
                    <IconButton style={{color:'#202020', marginRight:'-16px'}} 
                        onClick={props.closeDrawer}>
                        <ClearIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div>
                {props.children}
            </div>
            </Drawer>
        );
}

export default RightToLeftSideDrawer;