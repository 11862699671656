import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography,  Container, CircularProgress } from '@material-ui/core';
import {connect} from 'react-redux';
import { setSelectedDrawerState, SelectedDrawerState } from '../actions/drawer';
import { AppRoutes } from '../util/AppUtil';
import {authorizeRazorPayApi} from '../services/authService';
import { setSelectedInvoiceAccount } from '../actions/invoiceAccount';
import { getTdsTypeList } from '../actions/invoiceAccount';
import { setInvoiceAccountsList } from '../actions/invoiceAccount';
import { history } from '../routers/AppRouter';
import { useSnackbar } from 'notistack';
import { consoleToLog } from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    containerStyles : {
        height:'100vh',
        background: theme.palette.primary.light,
        display:'flex',
        alignItems:"center",
        justifyContent:'center'
    }
}))

const RazorpaySuccess = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code');
        const state = queryParams.get('state');
        authorizeRazorPay(code, state);
    }, []);

    
    const authorizeRazorPay = (code, state) => {
        const array = state?.split(',');
        const invoice_account_id=array[0];
        const entity_id=array[1];

        if(!invoice_account_id || !entity_id){
            setLoading(false);
            enqueueSnackbar("Failed to authorize Razorpay!", {variant: 'error'});
            history.push(AppRoutes.PAYMENT_GATEWAY);
            props.setSelectedDrawerState(SelectedDrawerState.SHOW_SETTINGS);
            return;
        }

        authorizeRazorPayApi(code, invoice_account_id, entity_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response authorizeRazorPayApi ", res);
                setLoading(false);
                enqueueSnackbar(res?.message, {
                    variant: 'success'
                });
                history.push(AppRoutes.PAYMENT_GATEWAY);
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_SETTINGS);
            })
            .catch((e) => {
                consoleToLog('Error authorizeRazorPayApi ', e.response);
                setLoading(false);
                enqueueSnackbar("Failed to authorize", {variant: 'error'});
                history.push(AppRoutes.PAYMENT_GATEWAY);
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_SETTINGS);
            });
    }

    /* const onGoToDashboard = () => {
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD);
        history.push(AppRoutes.SETTINGS);
    } */

    return (
        <div className={classes.containerStyles}>
            <Container maxWidth='xs'>
                <Typography variant="h6" align="center">Authorizing...</Typography>
                <img src="/images/razorpay-icon.svg" style={{width:'90%', margin:"0px auto", marginTop: "8px"}} alt="Razorpay Icon" />
                {loading ?
                <Grid item container justifyContent='center'
                    style={{marginTop: "8px"}}>
                    <Grid item textAlign='center'>
                        <CircularProgress size={20} style={{color:"#000", textAlign:'center'}}/>
                    </Grid>
                </Grid>
                : 
                <div>Failed to authorize razorpay</div>
                }
            </Container>
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    drawerState: state.drawerState.setDrawerState
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setInvoiceAccountsList: (accountList) => dispatch(setInvoiceAccountsList(accountList)),
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    getTdsTypeList: (invoice_account_id, country_code) => dispatch(getTdsTypeList(invoice_account_id, country_code))
});

export default connect(mapStateToProps, mapDispatchToProps)(RazorpaySuccess);