 import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import AddAlertOutlinedIcon from '@material-ui/icons/AddAlertOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import SettingsBackupRestoreOutlinedIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

const style={width: "6px", height: "6px"};
export const getIcon = (icon) => {
    if(icon === 'AddOutlinedIcon') return <AddOutlinedIcon style={style}></AddOutlinedIcon>;
    if(icon === 'EmailOutlinedIcon') return <EmailOutlinedIcon style={style}></EmailOutlinedIcon>;
    if(icon === 'EditOutlinedIcon') return <EditOutlinedIcon style={style}></EditOutlinedIcon>;
    if(icon === 'VisibilityOutlinedIcon') return <VisibilityOutlinedIcon style={style}></VisibilityOutlinedIcon>;
    if(icon === 'CloudDownloadOutlinedIcon') return <CloudDownloadOutlinedIcon style={style}></CloudDownloadOutlinedIcon>;
    if(icon === 'AddAlertOutlinedIcon')return <AddAlertOutlinedIcon style={style}></AddAlertOutlinedIcon>;
    if(icon === 'ClearOutlinedIcon') return <ClearOutlinedIcon style={style}></ClearOutlinedIcon>;
    if(icon === 'ThumbUpOutlinedIcon') return <ThumbUpOutlinedIcon style={style}></ThumbUpOutlinedIcon>;
    if(icon === 'ThumbDownOutlinedIcon') return <ThumbDownOutlinedIcon style={style}></ThumbDownOutlinedIcon>;
    if(icon === 'SettingsBackupRestoreOutlinedIcon') return <SettingsBackupRestoreOutlinedIcon style={style}></SettingsBackupRestoreOutlinedIcon>;
    if(icon === 'DescriptionOutlinedIcon') return <DescriptionOutlinedIcon style={style}></DescriptionOutlinedIcon>;

    return null;
}

