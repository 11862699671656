import React from 'react';
import { DraggableText, DraggableSignature, DraggableDateSelector} from './DraggableComponents';

const DragItemToolbar = ({  }) => {
    return (
        <div className='drag_toolbox'>
            <DraggableText />
            <DraggableSignature />
            <DraggableDateSelector />
        </div>
    );
}

export default DragItemToolbar;