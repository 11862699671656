import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Grid, Typography, TextField, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { consoleToLog, EntityInvoiceEstimate, trackGAEvent } from '../util/AppUtil';
import { getInvoiceAccountsEntityListApi } from '../services/authService';
import { updateEntitiesDetailsApi } from '../services/authService';
import { useSnackbar } from 'notistack';
import { setSelectedInvoiceAccount, setInvoiceAccountsList } from '../actions/invoiceAccount';
import { useIsMount } from './useIsMount';
import {InvoiceEntityApi} from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    entityListStyle: {
        padding:'8px 16px',
        background:'#f7f7f7',
        borderRadius:'5px',
        marginBottom:'8px',
        '& .entity_name' : {
            fontSize:'15px',
            fontWeight: 500
        },
        '& .entity_email' : {
            fontSize:'13px',
            color:'#888'
        },

    },
    textFieldStyle: {
        background:'#fff'
    }

}))

const InvoiceEntityPrefixAndNumber = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();


    const [entityList, setEntityList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getEntityList();
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(props.callupdateEntityDetailsApi) {
                updateEntitiesDetails();
            }
        }
    }, [props.callupdateEntityDetailsApi]);

    const getEntityList = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const apiPath = InvoiceEntityApi.ACTIVE

        setLoading(true);
        getInvoiceAccountsEntityListApi(invoice_account_id, apiPath)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getInvoiceAccountsEntityListApi: ', res);
                setLoading(false);

                //match selected entity and extract that
                const filteredEntities=res && res.length>0 &&res?.filter((item)=>item.id===props.selectedEntityItem?.id)
                setEntityList(filteredEntities);

                let account = {
                    ...props.selectedAccount,
                    entities: filteredEntities
                };


                let accountList = props.invoiceAccountsList.map((ia)=>{
                    if(ia.id === account.id) return account;
                    else return ia;
                });

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account);
            })
            .catch((e) => {
                consoleToLog("getInvoiceAccountsEntityListApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
    }

    const updateEntitiesDetails = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const apiStr = props.value === EntityInvoiceEstimate.ENTITY_INVOICE_PREFIX ? 'entities/update_details' : `estimate/update_estimate_details`;

        const entities = entityList.map((entityItem) => {
            if(props.value === EntityInvoiceEstimate.ENTITY_INVOICE_PREFIX) {
                return {
                    entity_id: entityItem.id,
                    next_invoice_number: Number(entityItem.next_invoice_number),
                    prefix: entityItem.prefix
                }
            } else {
                return {
                    entity_id: entityItem.id,
                    next_estimate_number: Number(entityItem.next_estimate_number),
                    estimate_prefix: entityItem.estimate_prefix
                }
            }
        })

        props.setButtonLoading(true);
        updateEntitiesDetailsApi(invoice_account_id, entities, apiStr)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateInvoicePrintSettingsApi: ', res);
                props.setButtonLoading(false);
                
                props.setCallupdateEntityDetailsApi(false);

                if((res.entities && res.entities.length === 0) || (res.entities && res.entities.length>0)) {
                    props.handleDrawerClose();
                    enqueueSnackbar("Entity Details updated", {variant: 'success'});
                } else {
                    if(res.error_list && res.error_list.length > 0) {
                        res.error_list.map((error) => {
                            enqueueSnackbar(error, {variant: 'error'});
                        })
                    }
                    const updatedEntityList = entityList.map((entityItem) => {
                        res.entities.map((entityObj) => {
                            if(entityItem.id === entityObj.id) {
                                if(props.value === EntityInvoiceEstimate.ENTITY_INVOICE_PREFIX) {
                                    return {
                                        ...entityItem,
                                        prefix: entityObj.prefix,
                                        next_invoice_number:  Number(entityObj.next_invoice_number),
                                    }
                                } else {
                                    return {
                                        ...entityItem,
                                        estimate_prefix: entityObj.estimate_prefix,
                                        next_estimate_number:  Number(entityObj.next_estimate_number),
                                    }
                                }
                            }
                            return entityItem;
                        });
                        return entityItem;
                    });
                    setEntityList(updatedEntityList);
                    let account = {
                        ...props.selectedAccount,
                        entities: updatedEntityList
                    };
    
                    let accountList = props.invoiceAccountsList.map((ia)=>{
                        if(ia.id === account.id) return account;
                        else return ia;
                    });
    
                    props.setInvoiceAccountsList(accountList);
                    props.setSelectedInvoiceAccount(account);
                }
                trackGAEvent(props.selectedAccount.name, 'Invoice Entities details updated', `${props.user.firstname} ${props.user.lastname}`);
                
            })
            .catch((e) => {
                consoleToLog('Error updateInvoicePrintSettingsApi: ', e.response);
                props.setCallupdateEntityDetailsApi(false);
                props.setButtonLoading(false);
                if(e.response.data && e.response.data.message) {
                    //enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }



    const updateEntityPrefix = (e, index) => {
        const updatedEntityList = entityList;

        updatedEntityList[index][props.value === EntityInvoiceEstimate.ENTITY_INVOICE_PREFIX ? 'prefix' : 'estimate_prefix'] = e.target.value;
        setEntityList([...updatedEntityList]);
    }

    const updateEntityNextInvoiceNumber = (e, index) => {
        const updatedEntityList = entityList;

        updatedEntityList[index][props.value === EntityInvoiceEstimate.ENTITY_INVOICE_PREFIX ? 'next_invoice_number' : 'next_estimate_number'] = e.target.value;
        setEntityList([...updatedEntityList]);
    }
    
    return (
            loading
            ?
            <Grid item container justifyContent='center'>
                <CircularProgress size={25} style={{marginTop:"16px"}} />
            </Grid>
            :
            <Grid item md={12}>
                {
                    entityList && entityList.length > 0 ?
                    entityList.map((entityItem, index) => {
                        return <Grid item container direction='column'  
                                    key={entityItem.id}
                                    className={classes.entityListStyle}>

                                    <Grid item md={12}>
                                        <Typography className='entity_name'>
                                            {entityItem.name}
                                        </Typography>
                                        <Typography className='entity_email'>
                                            {entityItem.email}
                                        </Typography>
                                    </Grid>

                                    <Grid item md={12}>
                                        <Grid item container alignItems='center' spacing={2}>
                                            <Grid item md={6}>
                                                <TextField variant='outlined'
                                                    fullWidth
                                                    type='text'
                                                    margin='dense'
                                                    placeholder='Enter Prefix'
                                                    value={props.value === EntityInvoiceEstimate.ENTITY_INVOICE_PREFIX ? entityItem.prefix : entityItem.estimate_prefix}
                                                    classes={{
                                                        root: classes.textFieldStyle
                                                    }}
                                                    onChange={(e) => updateEntityPrefix(e, index)}

                                                />
                                            </Grid>
                                            <Grid item md={6}>
                                                <TextField variant='outlined'
                                                    fullWidth
                                                    type='text'
                                                    margin='dense'
                                                    placeholder='Enter Next Invoice Number'
                                                    classes={{
                                                        root: classes.textFieldStyle
                                                    }}
                                                    value={props.value === EntityInvoiceEstimate.ENTITY_INVOICE_PREFIX ? entityItem.next_invoice_number : entityItem.next_estimate_number}
                                                    onChange={(e) => updateEntityNextInvoiceNumber(e, index)}
                                                />  
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                    }) 
                    :
                    <Grid item container justifyContent='center'>
                        <Typography variant='body1'>
                            No Entities Found
                        </Typography>
                    </Grid>
                }
            </Grid>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceEntityPrefixAndNumber);