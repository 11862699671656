import React, {useState, useEffect, useRef, memo} from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import { DroppedItemTypes } from '../../util/AppUtil';
import { useDrop } from 'react-dnd';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import DroppedItemsComponent from './DroppedItemsComponent';
import DroppedItemsPreview from './DroppedItemsPreview';

// Configure PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PDFContainer = memo(({droppedItems, setDroppedItems, uploadedPdf, showCreateEditTemplateScreen, originalDimension, setOriginalDimension}) => {

    const [numPages, setNumPages] = useState();
    const [containerWidth, setContainerWidth] = useState(0);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [pageHeight, setPageHeight] = useState(0);

    const containerRef = useRef(null);
    const heightRef = useRef(null);
    const pagesRef = useRef({});
    //const [pageNumber, setPageNumber] = useState(1);

    // Add useEffect to measure container width
    useEffect(() => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.offsetWidth);
        }

        // Optional: Add resize listener if container size might change
        const handleResize = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    const onDocumentLoadSuccess = (pdfObj) => {
        const {numPages} = pdfObj
        setNumPages(numPages);
    }

    const [, drop] = useDrop(() => ({
        accept: [
            DroppedItemTypes.TEXT,
            DroppedItemTypes.SIGNATURE,
            DroppedItemTypes.DROPPED_ITEM,
            DroppedItemTypes.DATE,
        ],
        drop: (item, monitor) => {
            const offset = monitor.getClientOffset();
            const initialClientOffset = monitor.getInitialClientOffset();
            const initialSourceOffset = monitor.getInitialSourceClientOffset();
    
            if (!offset || !initialClientOffset || !initialSourceOffset) return;
    
            const canvasElements = document.querySelectorAll('.react-pdf__Page__canvas');
    
            for (let i = 0; i < canvasElements.length; i++) {
                const canvasEl = canvasElements[i];
                const pageRect = canvasEl.getBoundingClientRect(); // Get canvas bounding box
                const pageNum = i + 1;
    
                if (
                    offset.x >= pageRect.left &&
                    offset.x <= pageRect.right &&
                    offset.y >= pageRect.top &&
                    offset.y <= pageRect.bottom
                ) {
                    // Adjust position for scaling factors
                const scaleX = canvasEl.offsetWidth / pageRect.width;
                const scaleY = canvasEl.offsetHeight / pageRect.height;

                const dragOffsetX = (initialClientOffset.x - initialSourceOffset.x) * scaleX;
                const dragOffsetY = (initialClientOffset.y - initialSourceOffset.y) * scaleY;

                const x = (offset.x - pageRect.left) / scaleX - dragOffsetX;
                const y = (offset.y - pageRect.top) / scaleY - dragOffsetY;

                const absoluteX = Math.max(0, Math.min(x, canvasEl.offsetWidth));
                const absoluteY = Math.max(0, Math.min(y, canvasEl.offsetHeight));
    
                    if (item.index !== undefined) {
                        setDroppedItems((prevItems) =>
                            prevItems.map((prevItem, idx) =>
                                idx === item.index
                                    ? {
                                        ...prevItem,
                                        position: { x: absoluteX, y: absoluteY },
                                        page: parseInt(pageNum, 10),
                                        showLabelPopover: undefined
                                    }
                                    : prevItem
                            )
                        );
                    } else {
                        let newItem = {};
                        if (item.type === DroppedItemTypes.TEXT) {
                            newItem = {
                                component_type: item.type,
                                position: { x: absoluteX, y: absoluteY },
                                item_id: new Date(),
                                coordinates: {},
                                component_label: '',
                                page: parseInt(pageNum, 10),
                                value_json: { text: '' },
                                showLabelPopover: true
                            };
                        }
                        if (item.type === DroppedItemTypes.SIGNATURE) {
                            newItem = {
                                component_type: item.type,
                                position: { x: absoluteX, y: absoluteY },
                                item_id: new Date(),
                                coordinates: {},
                                component_label: '',
                                page: parseInt(pageNum, 10),
                                value_json: {
                                    is_member: true,
                                    signature_image_path: '',
                                    signed_date: '',
                                    signed_ip_address: '',
                                    name: '',
                                    email: '',
                                    member_id:''
                                },
                                showLabelPopover: true
                            };
                        }
    
                        if (item.type === DroppedItemTypes.DATE) {
                            newItem = {
                                component_type: item.type,
                                position: { x: absoluteX, y: absoluteY },
                                item_id: new Date(),
                                coordinates: {},
                                component_label: '',
                                page: parseInt(pageNum, 10),
                                value_json: {
                                    date: '',
                                },
                                showLabelPopover: true
                            };
                        }
                        setDroppedItems((prevItems) => [...prevItems, newItem]);
                    }
                    break;
                }
            }
        },
    })); 

    return (
        <div  className={showCreateEditTemplateScreen ? "pdf_container" : "pdf_container_scroll"}
            style={{width:showCreateEditTemplateScreen ? '60%' : '100%', overflow: showCreateEditTemplateScreen ? 'hidden' : 'auto'}}
            ref={containerRef}
        >   
            <div ref={drop} style={{ position: 'relative' }}>
                <Document file={uploadedPdf} onLoadSuccess={onDocumentLoadSuccess}>
                {numPages > 0 && Array.from(new Array(numPages), (el, index) => (
                    <div 
                        //key={`page_${index + 1}`}
                        ref={el => pagesRef.current[index + 1] = el}
                        style={{ position: 'relative' }}
                    >
                        <Page
                            //key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            renderTextLayer={false}
                            onRenderSuccess={(page) => {
                                //console.log('page', page);
                                if(pageHeight === 0 && index === 0) {
                                    setPageHeight(page.height);
                                }

                                if(originalDimension?.width === 0) {
                                    setOriginalDimension({
                                        width: page.originalWidth,
                                        height: page.originalHeight
                                    });
                                }
                            }}
                        />
                        <div className="pdf-overlay" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                            {containerWidth > 0 && originalDimension?.width > 0 && originalDimension.height > 0 &&
                                pageHeight > 0 && droppedItems.length > 0 && droppedItems.filter(item => item.page === index + 1).map((item, index) => (
                                <DroppedItemsComponent
                                    key={item.item_id}
                                    item={item}
                                    index={droppedItems.findIndex(i => i.item_id === item.item_id)}
                                    droppedItems={droppedItems}
                                    setDroppedItems={setDroppedItems}
                                    pagesRef={pagesRef}
                                    showCreateEditTemplateScreen={showCreateEditTemplateScreen}
                                    pdfWidth={containerWidth}
                                    pageHeight={pageHeight}
                                    originalDimension={originalDimension}
                                />
                            ))}
                        </div>
                    </div>
                ))}
                </Document>
            </div>
        </div>
    );
});

export default PDFContainer;