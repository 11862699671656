import React, { useState, useEffect, useRef } from 'react';

const OtpInput = ({ setSaveOtp }) => {
    const [otp, setOtp] = useState(['', '', '', '']);
    const inputRefs = useRef([]);

    const handleChange = (e, index) => {
        const value = e.target.value;

        // Allow only one digit
        if (/^\d?$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Move to the next input if the current one is filled
            if (value && index < 3) {
                inputRefs.current[index + 1].focus();
            }

            // Save OTP when all fields are filled
            if (newOtp.every((digit) => digit)) {
                setSaveOtp(newOtp.join(''));
            }
        }
    };

    const handleKeyDown = (e, index) => {
        // Move to the previous input on backspace
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
            {otp.map((digit, index) => (
                <input
                    key={index}
                    type="text"
                    value={digit}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)}
                    maxLength="1"
                    style={{
                        width: '30px',
                        height: '30px',
                        textAlign: 'center',
                        margin: '0 5px',
                        fontSize: '18px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                    }}
                />
            ))}
        </div>
    );
};

export default OtpInput; 