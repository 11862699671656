import React, { useEffect, useLayoutEffect } from 'react';
import {Grid, Typography, Button, Dialog, TextField} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    MuiDialogpaper: {
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    dialogTitle : {
        borderBottom:'1px solid #ccc', 
        padding:'14px 16px', 
        fontSize:'16px',
        fontWeight:'600',
    }
}))

const DocumentNameModal = ({openDocumentNameModal, handleDocumentNameModalClose, documentName, setDocumentName, callApi, templateObj, documentObj, callTemplateApi, setCallTemplateApi, ...props}) => {
    const classes = useStyles();

    useEffect(() => {
        if(callTemplateApi) {
            setDocumentName(templateObj?.name || '')
        } else {
            setDocumentName(documentObj?.name || '')
        }
    }, [callTemplateApi])

    const onCancelClick = () => {
        handleDocumentNameModalClose();
        callTemplateApi && setCallTemplateApi(false)
    }   

    const onSaveClick = () => {
        callApi();
        handleDocumentNameModalClose();
    }

    return (
        <Dialog
                open={openDocumentNameModal}
                //TransitionComponent={Transition}
                scroll="paper"
                classes={{
                    paper: classes.MuiDialogpaper}}
                maxWidth="xs"
                fullWidth
                style={{scrollbarWidth:'none !important'}}
                onClose={handleDocumentNameModalClose}>

                    <Grid item container   
                        justifyContent='space-between'
                        alignItems='center'className={classes.dialogTitle}>

                        <Grid item>        
                            <Typography style={{fontWeight:600}}>
                                <span style={{marginLeft:'9px'}}>
                                    {callTemplateApi ? `Set Template Name` : `Set Document Name` }
                                </span>
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Grid item>
                                <ClearIcon fontSize='small' onClick={props.handleClickDialogClose}
                                style={{cursor:'pointer', verticalAlign:'inherit'}}/>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item sm={12} style={{padding:'32px 16px'}}>
                        <Typography variant='body2'>
                            {callTemplateApi ? 'Template Name' : 'Document Name'}
                        </Typography>

                        <TextField fullWidth
                            variant='outlined'
                            value={documentName}
                            onChange={(e) => setDocumentName(e.target.value)}
                            margin='dense'
                            placeholder='Enter document name'
                            style={{marginTop:'2px'}}
                        />

                    </Grid>
                    
                    
                    {
                        
                        <Grid item container justifyContent='center' spacing={1} style={{marginBottom:'32px'}}>
                            <Grid item>
                                <Button variant='contained' color='primary' 
                                    onClick={onSaveClick}
                                >
                                    Save
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button variant='outlined' color='primary' 
                                    onClick={onCancelClick}
                                >
                                    No
                                </Button>
                            </Grid>
                        </Grid>
                    }
                
            </Dialog>
    );
}

export default DocumentNameModal;