import React, {useState} from 'react';
import {Grid, Button, Popover, Typography} from '@material-ui/core';
import { DateRangePicker} from "materialui-daterange-picker";
import moment from 'moment';
import {connect} from 'react-redux';
import { consoleToLog, trackGAEvent } from '../../util/AppUtil';
import {setIssueStartDateFilter, setIssueEndDateFilter} from '../../actions/invoiceFilters';
import {setProposalIssueStartDateFilter, setProposalIssueEndDateFilter} from '../../actions/proposalFilters';

const IssueDateRangeFilterComponent = (props) => {
    const [anchorEl, setAnchorEl] = useState(false);
    const [dateRange, setDateRange] = useState({});

    const startMonthOptions = [{name: "January", value:1}, {name: "February", value: 2}, {name: "March", value:3}, 
        {name: "April", value: 4}, {name: "May", value: 5}, {name: "June", value: 6},
        {name: "July", value: 7}, {name: "August", value:8}, {name: "September", value: '9'}, 
        {name: "October", value: 10}, {name: "November", value: 11}, {name: "December", value: 12}
    ];

    const financial_year_start_month = props.selectedAccount?.financial_year_start_month;
    const startMonth = startMonthOptions[financial_year_start_month - 1]?.name;
    const endMonth = financial_year_start_month !== 1 ? startMonthOptions[financial_year_start_month - 2]?.name : startMonthOptions[startMonthOptions.length - 1]?.name;
    
    const start_date = props.fromReportsComponent ? props.dateRange?.startDate : props.fromProposalListComponent ? props.proposalIssueStartDateFilter : props.issueStartDateFilter;
    const end_date = props.fromReportsComponent ? props.dateRange?.endDate : props.fromProposalListComponent ? props.proposalIssueEndDateFilter : props.issueEndDateFilter; 

    //Last Month
    var prevMonthFirstDay = new moment().subtract(1, 'months').date(1)
    
   //for next month last day
   var thisMonthFirstDay = moment().startOf('month').format('YYYY-MM-DD');
   var thisMonthLastDay = moment().format('YYYY-MM-DD');
    
    //for last day of previous month
    var lastdayLastMonth = moment(lastdayLastMonth).subtract(1,'months').endOf('month').format('YYYY-MM-DD');

    let currentFinancialYearStart;
    let currentFinancialYearEnd;

    currentFinancialYearStart = (moment().month() < financial_year_start_month - 1) ? moment().subtract(1, 'year').month(startMonth).startOf('month').format('YYYY-MM-DD')
    : moment().month(startMonth).startOf('month').format('YYYY-MM-DD');

    currentFinancialYearEnd =  (moment().month() < financial_year_start_month - 1) ? moment().month(endMonth).endOf('month').format('YYYY-MM-DD')
    : moment().add(1, 'year').month(endMonth).endOf('month').format('YYYY-MM-DD');
    
    let lastFinancialYearStart = moment(currentFinancialYearStart).subtract(1, 'year').format('YYYY-MM-DD');
    let lastFinancialYearEnd = moment(currentFinancialYearEnd).subtract(1, 'year').format('YYYY-MM-DD');


    const openDateRangePicker = (event) => {
        consoleToLog("123");
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getDuration = (range) => {
        if(props.fromReportsComponent) {
            props.setDateRange(range);
        } else {
            setDateRange(range);
            trackGAEvent(props.selectedAccount.name, 'Invoice filter - date range filter changed');

            if(props.fromProposalListComponent) {
                props.setProposalIssueStartDateFilter(moment(range.startDate).format('YYYY-MM-DD'));
                props.setProposalIssueEndDateFilter(moment(range.endDate).format('YYYY-MM-DD'));
                if(range.startDate !== props.proposalIssueStartDateFilter && range.endDate !== props.proposalIssueEndDateFilter) {
                    props.pageReset();
                }
            } else {
                props.setIssueStartDateFilter(moment(range.startDate).format('YYYY-MM-DD'));
                props.setIssueEndDateFilter(moment(range.endDate).format('YYYY-MM-DD'));
                if(range.startDate !== props.issueStartDateFilter && range.endDate !== props.issueEndDateFilter) {
                    props.pageReset();
                }
            }
        }
    }   

    const onAllClick = () => {
        if(props.fromReportsComponent) {
            props.setDateRange(undefined);
        } else {
            if(props.fromProposalListComponent) {
                props.setProposalIssueStartDateFilter(undefined);
                props.setProposalIssueEndDateFilter(undefined);
            } else {
                props.setIssueStartDateFilter(undefined);
                props.setIssueEndDateFilter(undefined);
            }
            props.pageReset();
        }

    }



    return (
        <div style={{marginBottom:props.fromReportsComponent ? 0 : '16px'}}>
            {!props.fromReportsComponent &&
            <Typography style={{fontSize:'14px'}}>
                Issue Date Range
            </Typography>}
            <Grid item container lg={12} style={{marginTop: props.fromReportsComponent ? 0 : '3px'}}>
                <Grid item lg={props.fromReportsComponent ? 12 : 9}>
                    <Button variant='outlined' 
                        color='primary' 
                        style={{fontWeight:"300 !important", border: !start_date && !end_date && '2px solid #0076ff', borderTopRightRadius:'0px', borderBottomRightRadius:'0px'}}
                        onClick={onAllClick}
                        >
                        {/* {`${moment(props.startDateFilter).format('DD-MM-YYYY')} - 
                                ${moment(props.endDateFilter).format('DD-MM-YYYY')}`} */}
                            All
                    </Button>
                    <Button variant='outlined' 
                        color='primary' 
                        style={{fontWeight:"300 !important", border: start_date && end_date && '2px solid #0076ff', borderTopLeftRadius:'0px', borderBottomLeftRadius:'0px'}}
                        onClick={openDateRangePicker}>
                        {(start_date && end_date) ? `${moment(start_date).format('DD-MM-YYYY')} - 
                                    ${moment(end_date).format('DD-MM-YYYY')}` : 'Select Duration'}
                    </Button>
                    <Popover
                        id='issue_date_range-popover'
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center"
                        }}
                        classes={{
                            paper:'filter_date_popover'
                        }}
                    >
                        <DateRangePicker
                            open={true}
                            toggle={handleClose}
                            onChange={(range) => getDuration(range)}
                            style={{
                            width:'50%'
                            }}
                            initialDateRange={{
                                startDate:  start_date,
                                endDate: end_date
                            }}
                            definedRanges={[
                            {
                                label: `${moment(lastFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(lastFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                startDate: lastFinancialYearStart,
                                endDate: lastFinancialYearEnd
                            },
                            {
                                label: `${moment(currentFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(currentFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                startDate: currentFinancialYearStart,
                                endDate: currentFinancialYearEnd
                            },
                            {
                                label: 'Last Month',
                                startDate: prevMonthFirstDay,
                                endDate: lastdayLastMonth
                            },
                            {
                                label: 'This Month',
                                startDate: thisMonthFirstDay,
                                endDate: thisMonthLastDay
                            }
                            ]}
                        />
                    </Popover>    
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    issueStartDateFilter: state.invoiceFilters.issueStartDateFilter,
    issueEndDateFilter: state.invoiceFilters.issueEndDateFilter,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    proposalIssueStartDateFilter: state.proposalFilters.proposalIssueStartDateFilter,
    proposalIssueEndDateFilter: state.proposalFilters.proposalIssueEndDateFilter
});

const mapDispatchToProps = (dispatch) => ({
    setIssueStartDateFilter: (start_date) => dispatch(setIssueStartDateFilter(start_date)),
    setIssueEndDateFilter: (end_date) => dispatch(setIssueEndDateFilter(end_date)),
    
    setProposalIssueStartDateFilter: (start_date) => dispatch(setProposalIssueStartDateFilter(start_date)), 
    setProposalIssueEndDateFilter: (end_date) => dispatch(setProposalIssueEndDateFilter(end_date))
})

export default connect(mapStateToProps, mapDispatchToProps) (IssueDateRangeFilterComponent);