import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles'; 
import {Grid, Button, CircularProgress} from '@material-ui/core';
import {connect} from 'react-redux';
import { setSelectedInvoiceAccount, setInvoiceAccountsList } from '../actions/invoiceAccount';
import { logout } from '../actions/invoiceAuth';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import InvoiceEntityPrefixAndNumber from './InvoiceEntityPrefixAndNumber';
import { EntityInvoiceEstimate } from '../util/AppUtil';
import BanksComponent from './BanksComponent';

const useStyles = makeStyles((theme) => ({
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        marginBottom:'32px'
    },
    preferenceSettingsContainer: {
        borderRight: '1px solid rgba(0,0,0, 0.1)',
    },
    drawer: {
        width: '50%',
        flexShrink: 0,
    },
    drawerPaper: {
        width: '50%',
        height:'calc(100vh - 190px)',
        overflow:"auto",
    },
    rowContainerStyle: {
        padding:'4px 16px 0px 16px',
        borderBottom: '1px solid rgba(0,0,0,.1)',
        '&:hover' : {
            background:'#f7f7f7'
        }
    },
    rowTextStyle: {
        fontSize:"15px",
        '&:hover': {
            background: 'transparent'
        }
    },
    customMarginButton: {
        marginBottom:'-8px'
    },
    arrowIconStyle: {
        fontSize:'16px',
        marginLeft:'12px',
        color:'#555'
    }
})) 

const EntityBankSettingsComponent = (props) => {
    const classes = useStyles();
    

    const [callupdateEntityDetailsApi, setCallupdateEntityDetailsApi] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);

    const [value, setValue] = useState(props?.value); 


    const onSaveClick = () => {
        (value === EntityInvoiceEstimate.ENTITY_ESTIMATE_PREFIX 
            || value === EntityInvoiceEstimate.ENTITY_INVOICE_PREFIX) && 
        updateEntitiesDetails();
    }

    const updateEntitiesDetails = () => {
        setCallupdateEntityDetailsApi(true);
    }

    return (
        <Grid item xs={12}  className={classes.settingsContainer}>
            <RightToLeftSideDrawer openDrawer={props.openBankDrawer} 
                closeDrawer={props.handleCloseBankDrawer}
                drawerWidth={true}
                title='Banks'
            >
                <Grid item md={12} className='modal__main1'>
                    <BanksComponent
                        selectedEntityItem={props.selectedEntityItem}
                        handleBankDrawerCloseFromParent={props.handleCloseBankDrawer} 
                        //openBankDrawerFromParent={openBankDrawerFromParent}
                        //handleBankDrawerCloseFromParent={handleBankDrawerCloseFromParent}
                    />
                </Grid>

            </RightToLeftSideDrawer>

        </Grid>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list)),
    logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityBankSettingsComponent);