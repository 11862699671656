export const getConvertedBoundingBox = (document, pageDimensions) => {
    //console.log("pageDimensions: ", pageDimensions);
    let positionObj = document?.ValueDetection?.Geometry?.BoundingBox;
    //console.log('positionObj', positionObj, document, document?.ValueDetection);
    return {
        type: document.Type.Text,
        content: {
            text: document.ValueDetection.Text
        },
        labelDetection: {
            text: document.LabelDetection?.Text || ""
        },
        position: {
            boundingRect: {
                x1: positionObj?.Left * pageDimensions[document.PageNumber-1]?.width,
                y1: positionObj?.Top * pageDimensions[document.PageNumber-1]?.height,
                x2: (positionObj?.Left + positionObj?.Width) * pageDimensions[document.PageNumber-1]?.width,
                y2: (positionObj?.Top + positionObj?.Height) * pageDimensions[document.PageNumber-1]?.height,
                width: pageDimensions[document.PageNumber-1]?.width,
                height: pageDimensions[document.PageNumber-1]?.height,
            },
            rects: [
                {
                    x1: positionObj?.Left * pageDimensions[document.PageNumber-1]?.width,
                    y1: positionObj?.Top * pageDimensions[document.PageNumber-1]?.height,
                    x2: (positionObj?.Left + positionObj?.Width) * pageDimensions[document.PageNumber-1]?.width,
                    y2: (positionObj?.Top + positionObj?.Height) * pageDimensions[document.PageNumber-1]?.height,
                    width: pageDimensions[document.PageNumber-1]?.width,
                    height: pageDimensions[document.PageNumber-1]?.height,
                },
            ],
            pageNumber: document?.PageNumber,
        },
            comment: {
            emoji: "",
        },
        id: Math.random().toString(36).substr(2, 9)
    }
};