import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles'; 
import {Grid, Typography, FormGroup, FormControlLabel, Checkbox, InputAdornment, TextField,Button, CircularProgress} from '@material-ui/core';
import {connect} from 'react-redux';
import { MentionsInput, Mention } from 'react-mentions';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState,  RichUtils, Modifier } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {getMentionOptionsForSubject, getMentionOptionsForBody} from '../services/mentionService';
import { updateEmailSettingsApi } from '../services/authService';
import { consoleToLog, AccessLevel } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import {useIsMount} from './useIsMount';
import {setClearInvoiceAccounts, getInvoiceAccountsList, setInvoiceAccountsList, setSelectedInvoiceAccount} from '../actions/invoiceAccount';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { trackGAEvent } from '../util/AppUtil';
import {clearSelectedTransactionFilters} from '../actions/transactionFilters';

const useStyles = makeStyles((theme) => ({
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        padding:'8px 16px',
        marginBottom:'32px'
    },
    emailContainer: {
       borderRight: '1px solid rgba(0,0,0, 0.1)',
       flexBasis:'55%',
       maxWidth:'55%'
    },
    daysLabel : {
        fontSize:'13px',
        background:'#C4C4C4', 
        padding:'10px', 
        borderRadius:'4px'
    },
    MuiTextFieldroot : {
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '8px'
        }
    },
}))

const EmailSettings = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [value, setValue] = useState("");
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [mentions, setMentions] = useState();
    const [mentionOptionsForSubject, setMentionOptionsForSubject] = useState([]);
    const [mentionOptionsForBody, setMentionOptionsForBody] = useState([]);
    const [beforeDueDate, setBeforeDueDate] = useState(false);
    const [onDueDate, setOnDueDate] = useState(false);
    const [afterDueDate, setAfterDueDate] = useState(false);
    const [beforeDueDateValue, setBeforeDueDateValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);
    const [intervalValue, setIntervalValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);

    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false

    useEffect(() => {
        generateDynamicMentionOptions();
    }, []);

    useEffect(() => {
        if(isMount) {
            prefillFormData();
        }
    }, [props.selectedAccount.email_settings]);

    useEffect(() => {
        if(!isMount) {
            generateDynamicMentionOptions();
            prefillFormData();
        }
    }, [props.selectedAccount?.id])

    useEffect(() => {
        if(!isMount) {
            if(!beforeDueDate) {
                setBeforeDueDateValue(0);
            }

            if(!afterDueDate) {
                setMaxValue(0);
                setIntervalValue(0);
            }
        }

    }, [beforeDueDate, afterDueDate]);

    const prefillFormData = () => {
        const {body, subject, reminders} = props.selectedAccount.email_settings;

        const blocksFromHtml = htmlToDraft(body);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);

        setEditorState(editorState);
        setValue(subject);
        setBeforeDueDate(reminders.before_due_date.enable);
        setOnDueDate(reminders.on_due_date.enable);
        setAfterDueDate(reminders.after_due_date.enable);
        setBeforeDueDateValue(reminders.before_due_date.days);
        setMaxValue(reminders.after_due_date.max);
        setIntervalValue(reminders.after_due_date.days);
    }

    let toolbarConfig = {
        inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough']
        },
        /* blockType: {
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code']
        }, */
        list: {
            options: ['unordered', 'ordered']
        },
        textAlign: {
            options: ['left', 'center', 'right', 'justify']
        },
        link: { options: ['link'] },
        options: ['inline', 'link',/* 'blockType', */ 'fontFamily', 'fontSize', 'colorPicker', 'list', 'textAlign']
        
    };

    const generateDynamicMentionOptions = () => {
        setMentionOptionsForSubject(getMentionOptionsForSubject());
        setMentionOptionsForBody(getMentionOptionsForBody())
        
    }

    const daysLabel = (val) => {
        return {
            endAdornment: <InputAdornment position="end" className={classes.daysLabel}>
                               {(val === 'days') ? 'days' : 'times'}
                          </InputAdornment>
        }
    }

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }

    const handleReturn = (e) =>  {
        if (e.keyCode===13) {
          setEditorState(RichUtils.insertSoftNewline(editorState));
          return 'handled';
        }
        return 'not-handled';
      }

      const handlePastedText = (text, html, editorState) => {
        var content = editorState.getCurrentContent();
        var selection = editorState.getSelection();

        var newContent = Modifier.insertText(
            content, 
            selection,
            text
        )

        var newEditorState = EditorState.push(editorState, newContent, "insert-characters")
        //Then send this to onChange()
        setEditorState(newEditorState);
        return 'handled'
      }

      const onSaveEmailSettingsClick = (load, load1) => {
        let email_settings = {};

        let subject = value; 
        if(!subject){
            enqueueSnackbar('Subject cannot be empty');
            return;
        }

        let body;
        if (!editorState.getCurrentContent().hasText()) {
        enqueueSnackbar('Email body can not be empty!', {
            variant:'error'
        });
        return;
        }
        body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        consoleToLog('body: ', body);

        if(beforeDueDate && !beforeDueDateValue) {
            enqueueSnackbar('Before Due Date cannot be zero', {
                variant:'error'
            });
            return;
        }

        if(afterDueDate && !maxValue) {
            enqueueSnackbar('Maximum Value cannot be zero', {
                variant:'error'
            });
            return;
        }

        if(afterDueDate && !intervalValue) {
            enqueueSnackbar('Interval Value cannot be zero', {
                variant:'error'
            });
            return;
        }

        email_settings = {
            subject: subject,
            body: body,
            reminders: {
                before_due_date: {enable: beforeDueDate, days:parseInt(beforeDueDateValue)},
                on_due_date: {enable: onDueDate},
                after_due_date: {enable: afterDueDate, max: parseInt(maxValue), days: parseInt(intervalValue)}
            }
        }

        const invoice_account_id = props.selectedAccount?.id;

        load && setLoading(true);
        load1 && setLoading1(true);
        updateEmailSettingsApi(invoice_account_id, email_settings)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateEmailSettingsApi: ', res);
                setLoading(false);
                setLoading1(false);

                enqueueSnackbar('Email Settings Update', {variant:'success'});

                let account = {
                    ...props.selectedAccount,
                    email_settings: res.email_settings
                };

                let accountList =  props.invoiceAccountsList.map((acc) => {
                    if(acc.id === account.id) return account
                    else return acc;
                });

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account);
                trackGAEvent(props.selectedAccount.name, 'Email settings updated', `${props.user.firstname} ${props.user.lastname}`);

            })
            .catch((e) => {
                consoleToLog('Error updateEmailSettingsApi: ', e.response);
                setLoading(false);
                setLoading1(false);

                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
            
      }

    return (
        <Grid item xs={12} className={classes.settingsContainer}>
            <Grid item container style={{marginTop:"16px"}}>
                <Grid item sm={7} className={classes.emailContainer}>

                    <Grid item md={10} lg={10} 
                        className={classes.customMarginTop}>
                        <Typography variant="body2" color="primary">
                            Email Subject
                        </Typography> 

                        <div className='emailSchedulerMention'>        
                            <MentionsInput className="mentionWrapper"
                                experimental__cutcopypaste="true"
                                singleLine={true}
                                disabled={accessLevelViewer}
                                value={value}
                                placeholder='Press * to display options'
                                allowSpaceInQuery={true}
                                onChange={(e, newValue, newPlainTextValue, mentions) => {
                                    setValue(e.target.value);
                                    setMentions(mentions)
                                }}>
                                    <Mention
                                        trigger="*"
                                        data={mentionOptionsForSubject}
                                        renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                                            <div className={`user ${focused ? 'focused' : ''}`}>{/*`user ${focused ? 'focused' : ''} m-1 p-1`*/}
                                                {highlightedDisplay}
                                            </div>
                                        )}
                                    />
                            </MentionsInput> 
                        </div> 
                    </Grid>

                    <Grid item md={10} lg={10}  
                        style={{marginTop:'32px'}}>
                        <Typography variant="body2" color="primary">
                            Email Body
                        </Typography>

                        <Editor
                            editorStyle={{ 
                                border: '1px solid lightgray',
                                minHeight: '200px',
                                scrollbarWidth: 'none',
                                lineHeight:1.3,
                                position:'relative',
                                padding: '12px',
                                "&::-webkit-scrollbar": {
                                    display: "none"
                                }
                            }}

                            mention={{
                                separator: ' ',
                                trigger: '*',
                                suggestions: mentionOptionsForBody,
                            }}
                            editorState={editorState}
                            onEditorStateChange={onEditorStateChange}
                            toolbar={toolbarConfig}
                            //handlePastedText={() => false}
                            placeholder='Press * to display options'
                            handlePastedText={handlePastedText}
                            handleReturn={handleReturn}
                            readOnly={accessLevelViewer}
                        />  
                        </Grid>

                        <Grid item style={{marginTop:'16px'}}> 
                            <Button variant='contained' color='primary'
                                className="modal__button"
                                disabled={accessLevelViewer}
                                onClick={() => onSaveEmailSettingsClick(true, false)}
                            >
                                {loading && <CircularProgress size={15} className='loading__style'/>}
                                Save
                            </Button>
                        </Grid>
                </Grid>

                <Grid item sm={5}>

                <FormGroup style={{marginLeft:'32px'}}>
                    <Grid item>
                        <Typography variant='body1'>
                                Reminders
                        </Typography> 
                    </Grid> 

                    <Grid item container alignItems='flex-end'>
                        <FormControlLabel control={
                            <Checkbox checked={beforeDueDate} 
                                disabled={accessLevelViewer}
                                onChange = {(e)=>setBeforeDueDate(e.target.checked)}
                            />
                            } 
                            label="" />

                            <TextField 
                                type="number"
                                autoComplete='off'
                                id="before_due_date"
                                margin="dense"
                                disabled={!beforeDueDate || accessLevelViewer}
                                value={beforeDueDateValue}
                                onChange={(e) => setBeforeDueDateValue(e.target.value)}
                                style={{width:'30%', marginLeft:'-16px'}}
                                InputProps={daysLabel('days')}
                                classes={{
                                    root: classes.MuiTextFieldroot
                                }}
                            />  
                            <Typography variant="body1" style={{marginLeft:'8px', verticalAlign:'baseline'}}>
                                before due date
                            </Typography>      
                    </Grid>

                    <FormControlLabel control={
                        <Checkbox checked={onDueDate}
                            onChange = {(e)=>setOnDueDate(e.target.checked)}
                            disabled={accessLevelViewer}
                        />
                        } 
                        label="on due date" />

                    <Grid item container alignItems='flex-end'>    
                        <FormControlLabel control={
                            <Checkbox checked={afterDueDate} 
                                onChange = {(e)=>setAfterDueDate(e.target.checked)}
                                disabled={accessLevelViewer}
                            />
                            } 
                            label="after due date every" />
                            <TextField 
                                type="number"
                                autoComplete='off'
                                id="before_due_date"
                                margin="dense"
                                disabled={!afterDueDate || accessLevelViewer}
                                value={maxValue}
                                onChange={(e) => setMaxValue(e.target.value)}
                                style={{width:'23%'}}
                                InputProps={daysLabel('days')}
                                classes={{
                                    root: classes.MuiTextFieldroot
                                }}
                            /> 

                            <Typography variant="body1" style={{margin:'0 12px', verticalAlign:'baseline'}}>
                                Max.
                            </Typography>

                            <TextField 
                                type="number"
                                autoComplete='off'
                                id="before_due_date"
                                margin="dense"
                                disabled={!afterDueDate || accessLevelViewer}
                                value={intervalValue}
                                onChange={(e) => setIntervalValue(e.target.value)}
                                style={{width:'23%'}}
                                InputProps={daysLabel('times')}
                                classes={{
                                    root: classes.MuiTextFieldroot
                                }}
                            /> 
                    </Grid>

                    <Grid item style={{marginTop:'26px'}}> 
                        <Button variant='contained' color='primary'
                            disabled={accessLevelViewer}
                            className="modal__button"
                            onClick={() => onSaveEmailSettingsClick(false, true)}
                        >
                            {loading1 && <CircularProgress size={15} className='loading__style'/>}
                            Save
                        </Button>
                    </Grid>    
                </FormGroup>

                </Grid>

            </Grid>            
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    user: state.invoiceAccounts.user

});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    getInvoiceAccountsList: (selectedAccount) => dispatch(getInvoiceAccountsList(selectedAccount)),
    setInvoiceAccountsList: (accountList) => dispatch(setInvoiceAccountsList(accountList)),
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailSettings);