import React, {useCallback, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Popover, Typography, Button} from '@material-ui/core';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { DroppedItemTypes } from '../../util/AppUtil';


const useStyles = makeStyles((theme) => ({
    dateBtnStyles : {
        width:'170px'
    }
}))

const DateValueComponent = ({element, index, droppedItems, setDroppedItems}) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const date = element.value_json.date;
    

    const handleDateSelectorOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleDateSelectorClose = () => {
        setAnchorEl(null)
    }

    const onDateChange = useCallback((date) => {
        const updatedFormElements = droppedItems;
        //console.log('item', date)
        updatedFormElements.forEach((item, i) => {
            if (item.component_type === DroppedItemTypes.DATE && item.component_label === updatedFormElements[index].component_label) {
                updatedFormElements[i].value_json.date = moment(date).format('YYYY-MM-DD');
            }
        });
        setDroppedItems([...updatedFormElements]);
        handleDateSelectorClose();
    }, [droppedItems, setDroppedItems]);

    const dateSelector = () => {
        return (<Popover
            id={'date-selector'}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleDateSelectorClose}
            className='date_popover_style'
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <DatePicker
                selected={moment().toDate()}
                onChange={(date) => onDateChange(date)}
                //selectsRange
                inline
            />
        </Popover>)
    }

    return (
        <div>
            <Typography style={{marginBottom:'4px', fontSize:"14px"}}>{element.component_label}</Typography>
            <Button variant='outlined' 
                color='primary' 
                className={classes.dateBtnStyles}
                onClick={handleDateSelectorOpen}>
                {date ?  `${moment(date).format('DD-MM-YYYY')}` : 'Select Date'}
            </Button>
            {dateSelector()}
        </div>
    );
}

export default DateValueComponent;