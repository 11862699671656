/**logout filters */
export const logoutInvoiceFilters = () => ({
    type: 'LOGOUT_INVOICE_FILTERS'
});


/**Invoice Filters (start_date, end_date, status, clients) */
export const setIssueStartDateFilter = (start_date) => ({
    type: 'SET_ISSUE_START_DATE_FILTER',
    start_date
});

export const setIssueEndDateFilter = (end_date) => ({
    type: 'SET_ISSUE_END_DATE_FILTER',
    end_date
});

//status
export const addSelectedStatusToInvoiceFilter = (status) => ({
    type: 'ADD_SELECTED_STATUS_TO_INVOICE_FILTER',
    status
});

export const removeSelectedStatusFromInvoiceFilter = (status) => ({
    type: 'REMOVE_SELECTED_STATUS_FROM_INVOICE_FILTER',
    status
});

export const addSelectedClientToInvoiceFilter = (client) => ({
    type: 'ADD_SELECTED_CLIENT_INVOICE_FILTER',
    client
});

export const deleteSelectedClientFromInvoiceFilter = (client) => ({
    type: 'DELETE_SELECTED_CLIENT_INVOICE_FILTER',
    client
});

export const clearSelectedInvoiceFilters = () => ({
    type: 'CLEAR_SELECTED_INVOICE_FILTERS'
});

export const addSelectedTypeToInvoiceFilter = (invoiceType) => ({
    type: 'ADD_SELECTED_TYPE_TO_INVOICE_FILTER',
    invoiceType
});

export const removeSelectedTypeFromInvoiceFilter = (invoiceType) => ({
    type: 'REMOVE_SELECTED_TYPE_FROM_INVOICE_FILTER',
    invoiceType
});

export const addSelectedEntitiesToInvoiceFilter = (entity) => ({
    type: 'ADD_SELECTED_ENTITIES_TO_INVOICE_FILTER',
    entity
});

export const removeSelectedEntitiesToInvoiceFilter = (entity) => ({
    type: 'REMOVE_SELECTED_ENTITIES_TO_INVOICE_FILTER',
    entity
});

export const addSelectedStatusToEstimateFilter = (status) => ({
    type: 'ADD_SELECTED_STATUS_TO_ESTIMATE_FILTER',
    status
});

export const removeSelectedStatusFromEstimateFilter = (status) => ({
    type: 'REMOVE_SELECTED_STATUS_FROM_ESTIMATE_FILTER',
    status
});

export const addSelectedBillTypeToEstimateFilter = (billType) => ({
    type: 'ADD_SELECTED_BILL_TYPE_TO_ESTIMATE_FILTER',
    billType
});

export const removeSelectedBillTypeFromEstimateFilter = (billType) => ({
    type: 'REMOVE_SELECTED_BILL_TYPE_FROM_ESTIMATE_FILTER',
    billType
});

export const setInvoiceSortByFilter = (sortBy) => ({
    type: 'SET_INVOICE_SORT_BY',
    sortBy
});

export const setEstimateSortByFilter = (sortBy) => ({
    type: 'SET_ESTIMATE_SORT_BY',
    sortBy
});

export const setUnclaimedBillFilter = (unclaimedBills) => ({
    type: 'UPDATE_UNCLAIMED_BILLS',
    unclaimedBills
});


//status
export const addSelectedStatusToBillFilter = (status) => ({
    type: 'ADD_SELECTED_STATUS_TO_BILL_FILTER',
    status
});

export const removeSelectedStatusFromBillFilter = (status) => ({
    type: 'REMOVE_SELECTED_STATUS_FROM_BILL_FILTER',
    status
});