import React, { useEffect, useState } from 'react';
import { makeStyles} from '@material-ui/core';
import { Typography, Button,} from '@material-ui/core';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import {connect} from 'react-redux';
import { useSnackbar } from 'notistack';
import { useIsMount } from './useIsMount';
import { logout } from '../actions/invoiceAuth';
import { consoleToLog } from '../util/AppUtil';
import { getActivityFeedsApi } from '../services/authService';
import ActivityFeed from './ActivityFeed';

const useStyles = makeStyles((theme) => ({
    drawer: {
    //    width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: '30%',
        //height: 'calc(100vh - 51px)',
        flexGrow:1,
        top:'51px',
    },
    appBar: {
        background:'#F9F9F9',
        height:'45px',
        color:'#202020'
    },
    customMarginTop : {
        marginTop:"16px"
    },
    customMarginBottom : {
        marginBottom:'-8px'
    },
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'0.5px 1px 0.5px 1px',
        borderRadius: '4px',
        background:'#fff',
        marginTop:'4px'
        }
    },
    createItemButton: {
        margin:'16px auto 4px 10px', 
        width:'95%', 
        color:'#1bac3b', 
        borderColor:'#d4d8de'
    },
    createItemIcon: {
        margin:'0px 6px 0px 0px', 
        fontSize:'16px'
    },
    itemOptionStyle: {
        width: "100%", 
        height:'100%',
        color:'#777f8e',
        margin:'5px 0px'
    },
}))

const AllActivityFeedDrawer = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [activityFeeds, setActivityFeed]=useState([]);

    useEffect(() => {
        getActivityFeeds();
    }, []);
   
     const getActivityFeeds = () => {
        const invoice_account_id = props.selectedAccount?.id
        const id = props.obj?.id;
        getActivityFeedsApi(invoice_account_id, id, props.type)  
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updatePaymentTransactionApi: ', res);
                setActivityFeed(res);
            })
            .catch((e) => {
                consoleToLog('Error updatePaymentTransactionApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            });
    }

    return (
            <RightToLeftSideDrawer title={"Activities" }
                openDrawer={props.openActivityDrawer}
                closeDrawer={props.handleCloseActivityDrawer}>

                <div className='modal__main_activity'>
                    {activityFeeds && 
                        activityFeeds.length>0 
                        && <ActivityFeed activityFeeds={activityFeeds} 
                                hideShowActivities={true}/>
                    }
                    
                </div>    
            </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AllActivityFeedDrawer);