import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/invoiceAuth';
import drawerReducer from '../reducers/drawer';
import invoiceAccountReducer from '../reducers/invoiceAccount';
import invoiceFiltersReducer from '../reducers/invoiceFilters';
import transactionFilterReducer from '../reducers/transactionFilters';
import overlayReducer from '../reducers/overlay';
import loadingReducer from '../reducers/loading';
import proposalFilterReducer from '../reducers/proposalFilters';
import documentFilterReducer from '../reducers/documentFilters';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//Store creation:
const storeObj =  (persistedState) => {
    const store = createStore(
        combineReducers({
            invoiceAuth: authReducer,
            drawerState: drawerReducer,
            invoiceAccounts: invoiceAccountReducer,
            invoiceFilters: invoiceFiltersReducer,
            transactionFilters: transactionFilterReducer,
            proposalFilters: proposalFilterReducer,
            documentFilters: documentFilterReducer,
            overlay: overlayReducer,
            loading: loadingReducer
        }),
        persistedState,
        composeEnhancers(applyMiddleware(thunk))
    );

    return store;
};

export default storeObj