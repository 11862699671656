import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles'; 
import {Grid, Typography,Button, CircularProgress, ClickAwayListener, Tooltip, Chip} from '@material-ui/core';
import { connect } from 'react-redux';
import {setInvoiceAccountsList, setSelectedInvoiceAccount, setClearInvoiceAccounts} from '../actions/invoiceAccount';
import { useIsMount } from './useIsMount';
import AddEditInvoiceItemsDrawer from './AddEditInvoiceItemsDrawer';
import { CSSTransition, TransitionGroup} from 'react-transition-group';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import {deleteItemApi, activateItemApi, getAllItemListApi, getBillCategoryListApi, deactivateBillCategory, deactivateBillCategoryApi, activateBillCategoryApi} from '../services/authService';
import { consoleToLog, AccessLevel } from '../util/AppUtil';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import {trackGAEvent} from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import CreateTaxPresetDrawer from './CreateTaxPresetDrawer';
import AutoCompleteTaxPreset from './AutoCompleteTaxPreset';
import { invoiceAccountCountryObj } from '../util/AppUtil';
import { currencyFormatterForAmount } from '../util/currencyUtil';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { formatAmount } from '../util/currencyUtil';
import { isInvoiceAccountCountryIndia } from '../util/AppUtil';
import AddEditBillCategoriesDrawer from './AddEditBillCategoriesDrawer';
import { usePropsContext } from './context';

const useStyles = makeStyles((theme) => ({
    componentContainer: {
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden',
        padding: "20px"
    },
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        marginBottom:'32px',
        padding: "20px",
    },
    heading: {
        fontSize: "20px",
        fontWeight: "600",
    },
    btn: {
        background: "#383838",
        width: "135px",
        paddingLeft: "4px",
        paddingRight: "4px",
        color: "white",
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        marginTop:'3%'
    },
    headings: {
        fontWeight: '600',
        color:'#6B7280',
        [theme.breakpoints.down(1400)]: {
            fontSize:'14px'
        },
    },
    itemTitleList: {
        background:'#f9fafb',
        borderBottom: '1px solid #edf2f7',
    },
    itemListContainer : {
        background:'white', 
        minWidth:"100%", 
        padding:'10px 16px', 
        color:'#6b7280',
        position:'relative',
        borderBottom: '1px solid #edf2f7',
        '& .action__icon' : {
            padding:'2px 4px 0px 4px',
            border:'1px solid white',
            borderRadius:'4px',
            cursor:'pointer'
        },
        '&:hover .action__icon' : {
           border:'1px solid #ccc'
        },
        '&:hover':{
          backgroundColor:'#f7fafc',
        },
        '& .more_actionButton' : {
            visibility:'hidden',
            display: "flex",
            alignItems: "center",
            width:'150px !important',
            background:'#fff',
            border:'1px solid #ccc',
            borderRadius:'5px',
            padding:'1px 0px',
            fontSize:'12px',

        },
        '&:hover .more_actionButton' : {
            visibility:'visible',
        },
    },
    actionsIcons: {
        color: '#b5bbc2',
        fontSize: '18px',
        marginRight: '8px' 
    },
    actionIconContainer : {
        marginRight:'auto',
        display:'flex',
        flexFlow:'row',
        flexGrow:1,
        position: "absolute",
        right: "10px",
        [theme.breakpoints.down(1500)] :{
            marginLeft:'-16px',
        },
        [theme.breakpoints.down(1450)] :{
            marginLeft:'-32px',
        },
        [theme.breakpoints.down(1400)] :{
            marginLeft:'-48px',
        },
        [theme.breakpoints.down(1320)] :{
            marginLeft:'-56px',
        },
        [theme.breakpoints.down(1290)] :{
            marginLeft:'-68px',
        },
    },
    flatIcons : {
        fontSize:'15px', 
        marginRight:'6px',
        display:'flex',
        alignItems:'center'
    },
}
));

const BillCategoriesComponent = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [openDrawer, setOpenDrawer] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [itemId, setItemId] = useState('');
    const [loading, setLoading] = useState(false);
    const [animationActive, setAnimationActive] = useState(false);
    const [itemObj, setItemObj] = useState({});
    const [openTaxPresetDrawer, setOpenTaxPresetDrawer] = useState(false);
    const [taxPresetObj,setTaxPresetObj]=useState(undefined);

    const {fullName} = usePropsContext();

    useEffect(() => {
        if(!props.fromClientDetails) {
            document.title = `Bill Categories - ${fullName}`;
        }
    }, []);

    useEffect(() => {
        getBillCategoryList();
    }, []);

    useEffect(() => {
        if(!isMount) {
            getBillCategoryList();
        }
    }, [props.selectedAccount?.id]);

    useEffect(() => {
        if(!isMount) {
            if(props.openInvoiceItemsdrawerFromParent) {
                handleDrawerOpen();
            }
        }
    }, [props.openInvoiceItemsdrawerFromParent]);

    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;


    const getBillCategoryList = () => {
        const invoice_account_id = props.selectedAccount?.id;

        setLoading(true);
        getBillCategoryListApi(invoice_account_id)
        .then((respose) => {
            const res = respose.data;
            consoleToLog('Response getBillCategoryListApi: ', res);
            setLoading(false);

            setItemList(res);
        })
        .catch((e) => {
            consoleToLog('Error getBillCategoryListApi', e.response);
            setLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        })
    }

    const handleDrawerOpen = () => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Items Settings - Add Invoice items clicked', `${props.user.firstname} ${props.user.lastname}`);
        setOpenDrawer(true)
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
        itemObj && setItemObj({});
        //!props.fromOnboardingComponent && props.handleInvoiceItemsDrawerCloseFromParent();
    }

    const handleTaxPresetDrawerOpen = () => {
       setOpenTaxPresetDrawer(true)
    }

    const handleTaxPresetDrawerClose = () => {
        setOpenTaxPresetDrawer(false)
    }

    const closeMoreOptionsMenu = () => {
        setIsActive(false);
        setItemId('');
    }

    const onEditItemClick = (e, item) => {
        trackGAEvent(props.selectedAccount.name, 'Edit bill category button clicked', `${props.user.firstname} ${props.user.lastname}`);
        e.stopPropagation();
        setItemObj(item);
        handleDrawerOpen();
    }

    const onUpdateLocalList = (obj) => {
        let updatedArray = itemList.map((item) => {
            if(item.id === obj.id) {
                item = obj;
            }
            return item;
        });

        setItemList(updatedArray);
        setItemObj({});
    }

    const onDeleteItemClick = (e, item) => {
        e.stopPropagation();
        closeMoreOptionsMenu();
        //setAnimationActive(true);
        const str=item.active? "Deactivate": "Activate";
        if(window.confirm(`Are you sure you want to ${str} this category?`)) {
            const invoice_account_id = props.selectedAccount?.id;
            const item_id = item.id;
            //deactivate
            if(item.active){
                deactivateBillCategoryApi(invoice_account_id, item_id)
                    .then((response) => {
                        const res = response.data;
                        consoleToLog('Response deactivateBillCategoryApi: ', res);

                        const updated_array = itemList.map((item) =>{ 
                            if(item.id === res.id){
                                return res;
                            }
                            else {return item;}
                        });
                        setItemList(updated_array);
                        //props.fromOnboardingComponent && props.setOnboardingItemList(updated_array);
                        //setAnimationActive(false);

                        trackGAEvent(props.selectedAccount.name, 'Bill category deactivated', `${props.user.firstname} ${props.user.lastname}`);
                    })
                    .catch((e) => {
                        setAnimationActive(false);
                        consoleToLog('Error deactivateBillCategoryApi', e.response);
                        if(e.response.data && e.response.data.message) {
                            enqueueSnackbar(e.response.data.message, {variant: 'error'});
                            return;
                        }
                    })
            }//activate
            else{
                activateBillCategoryApi(invoice_account_id, item_id)
                    .then((response) => {
                        const res = response.data;
                        consoleToLog('Response activateBillCategoryApi: ', res);

                        const updated_array = itemList.map((item) =>{ 
                            if(item.id === res.id){
                                return res;
                            }
                            else {return item;}
                        });
                        setItemList(updated_array);
                        //props.fromOnboardingComponent && props.setOnboardingItemList(updated_array);
                        //setAnimationActive(false);

                        trackGAEvent(props.selectedAccount.name, 'Bill category activated', `${props.user.firstname} ${props.user.lastname}`);
                    })
                    .catch((e) => {
                        setAnimationActive(false);
                        consoleToLog('Error activateBillCategoryApi', e.response);
                        if(e.response.data && e.response.data.message) {
                            enqueueSnackbar(e.response.data.message, {variant: 'error'});
                            return;
                        }
                    })
            }
        }
    }

    const onAddItemsClick = (obj) => {
        setItemList(oldItems => [...oldItems, obj]);
    }

    return (
        <div className={!props.fromOnboardingComponent?classes.componentContainer:{}}>
            <Grid item xs={12} className={classes.settingsContainer}>
                    {/* props.fromOnboardingComponent && */
                            <Grid item container justifyContent='space-between' 
                                style={props.fromOnboardingComponent? {padding:"16px 16px 16px 16px"} : {padding:"0px 16px 0px 16px"}}
                                alignItems='center'>
                                <Grid item>
                                    <Typography className='outboardingLabel'>
                                    Bill category items
                                    </Typography>
                                </Grid>

                            <Grid item>
                                <Button
                                    onClick={handleDrawerOpen}
                                    className={'addButton'}
                                >
                                Add Category
                                <AddCircleOutlineIcon fontSize='small' 
                                    style={{fontSize:'15px', marginLeft:'4px'}}
                                    className={'buttonPlusIcon'}/>
                                </Button>
                            </Grid>
                        </Grid>}   

                    <Grid item xs={12} style={{marginTop:props.fromOnboardingComponent ? 0 :'24px'}}>
                        <Grid item sm={12}>
                            <Grid item sm={12} className={classes.itemTitleList}>
                                <Grid item container style={{padding:'10px 16px'}}>
                                    <Grid item sm={1}>
                                        <Typography variant="body1" className={classes.headings}>
                                            #
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={9}>
                                        <Typography variant="body1" className={classes.headings}>
                                            Category Name
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                        {loading ?
                        <CircularProgress size={30} className={classes.loading}/> 
                        :
                        <Grid item xs={12}>
                        <TransitionGroup mode="out-in">
                        {
                            itemList && itemList.length > 0  &&
                            itemList.map((item) => {
                                return  <CSSTransition
                                            key={item.id}
                                            timeout={1000}
                                            exit={animationActive}
                                            classNames="item">
                                            <Grid item container 
                                                justifyContent="flex-start"
                                                className={classes.itemListContainer}>
                                            
                                                <Grid item md={1} lg={1}>
                                                    <Typography variant="body1">
                                                        {item.id}
                                                    </Typography>
                                                </Grid> 

                                                <Grid item md={9}>
                                                    <Typography variant="body1">
                                                        {item.name}
                                                    </Typography>
                                                </Grid>
                                                
                                                <Grid item md={1} lg={1}>
                                                    <Chip label={item.active?"Active": "Inactive"} 
                                                        style={{
                                                            backgroundColor: item.active?"#d2f3da": "#ffc7c7", 
                                                            color: item.active?"#008000": "#ff0000",
                                                            height: "25px",
                                                            fontSize: "10px"
                                                        }
                                                        } />
                                                </Grid>

                                                {!accessLevelViewer &&
                                                <Grid item md={1} lg={1}
                                                    style={{display:'flex', aligItems:'center', justifyContent:"center", position: "relative"}}>
                                                    <div  className={classes.actionIconContainer}>
                                                        {/* <Button 
                                                            onClick={(e) => onEditItemClick(e, item)}
                                                            className='more_actionButton action__icon'>
                                                            <i className={`flaticon-edit ${classes.flatIcons}`}></i>
                                                            Edit
                                                        </Button> */}
                                                        <Button 
                                                            onClick={(e) => onDeleteItemClick(e, item) }
                                                            className='more_actionButton action__icon'>
                                                            <i className={`flaticon-delete-1 ${classes.flatIcons}`}></i>
                                                            {item.active? "Deactivate": "Activate"}
                                                        </Button>
                                                    </div>
                                                </Grid> }   
                                            </Grid>
                                        </CSSTransition>     
                            })}
                            </TransitionGroup>
                            {itemList && itemList.length === 0 &&
                                <Grid tem container
                                    style={{marginTop:'16px'}}
                                    alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Typography variant="body1">
                                                No Bill Categories Found!
                                        </Typography>
                                    </Grid>     
                                </Grid>
                            }    
                            {/* {loadMoreLogic()} */}
                            </Grid>}
                        </Grid>
                    </Grid> 


                    {openDrawer && <AddEditBillCategoriesDrawer 
                        openDrawer={openDrawer} 
                        handleDrawerClose={handleDrawerClose}
                        onAddItemsClick={onAddItemsClick}
                        itemObj={itemObj}
                        setItemObj={setItemObj}
                        onUpdateLocalList={onUpdateLocalList}
                    />}

            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    setInvoiceAccountsList: (accountList) => (dispatch(setInvoiceAccountsList(accountList))),
    setSelectedInvoiceAccount: (account) => (dispatch(setSelectedInvoiceAccount(account))),
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(BillCategoriesComponent);