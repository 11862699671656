import React, {useState, useEffect, useLayoutEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container, Grid, Typography, Button, Tooltip, CircularProgress} from '@material-ui/core';
import {connect} from 'react-redux';
import { consoleToLog, showDate, statusColor, trackGAEvent, AccessLevel } from '../util/AppUtil';
import FilterDrawer from './FilterDrawer';
import { useIsMount } from './useIsMount';
import { logout } from '../actions/invoiceAuth';
import { setClearInvoiceAccounts} from '../actions/invoiceAccount';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { SelectedDrawerState, setSelectedDrawerState } from '../actions/drawer';
import { useSnackbar } from 'notistack';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {OverlayMessage} from '../util/AppUtil'; 
import { setOvelayMessage, setOverlayToken, setOverlayView, OverlayView, setApiRoute } from '../actions/overlay';
import { usePropsContext } from './context';
import useRestoreComponentState from './useRestoreComponentState';
import ShowConfirmDialog from './ShowConfirmDialog';
import EstimatesTableRow from './EstimatesTableRow';
import {storeComponentState} from '../util/AppUtil';
import {getEstimateListApi} from '../services/authService';
import { deleteEstimateApi } from '../services/authService';
import { cancelReopenCloseEstimateApi, createDuplicateEstimateApi, exportEstimatesApi } from '../services/authService';
import {Popover, MenuItem} from '@material-ui/core';
import {setEstimateSortByFilter} from '../actions/invoiceFilters';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import SwapVertOutlinedIcon from '@material-ui/icons/SwapVertOutlined';

// Add this function at the beginning of your component or in a separate utility file
const updateEstimateItem = (estimateItem, updatedInvoiceObj) => {
    if (updatedInvoiceObj?.estimate) {
        return updatedInvoiceObj.estimate;
    } else {
        return {
            ...estimateItem,
            client_id: updatedInvoiceObj.client.id,
            client_name: updatedInvoiceObj.client.name,
            entity_id: updatedInvoiceObj.entity.id,
            entity_name: updatedInvoiceObj.entity.name,
            status: updatedInvoiceObj.status,
            can_cancel: updatedInvoiceObj.can_cancel,
            can_close: updatedInvoiceObj.can_close,
            can_create_invoice: updatedInvoiceObj.can_create_invoice,
            can_edit: updatedInvoiceObj.can_edit,
            can_reopen: updatedInvoiceObj.can_reopen,
            total: updatedInvoiceObj.total,
            subtotal: updatedInvoiceObj.subtotal,
            bill_status: updatedInvoiceObj.bill_status,
            estimate_description: updatedInvoiceObj.estimate_description,
            estimate_number: updatedInvoiceObj.estimate_number,
            issue_date: updatedInvoiceObj.issue_date,
            currency: updatedInvoiceObj.currency
        };
    }
};

const useStyles = makeStyles((theme) => ({
    mainContent : {
        transition: ".5s", 
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    container: {
        marginTop: "8px"
    },
    MuiButtonlabel : {
        fontSize: "12px"
    },
    buttonPlusIcon: {
        marginRight:'6px', 
        fontSize:'1.2rem', 
        marginTop:'-2px'
    },
    icons : {
        color: "black",
        fontSize: "15px",
        minWidth:'20px',
        lineHeight: '10px',
    },
    headings: {
        fontWeight: '600',
        [theme.breakpoints.down(1400)]: {
            fontSize:'14px'
        },
    },
    invoiceListContainer : {
        background:'white', 
        minWidth:"100%", 
        borderRadius:'4px', 
        padding:'0px 16px 0px 16px', 
        color:'#2B2B2B',
    },
    invoiceListHover: {
        padding:'16px 0px',
        position:'relative',
        '& .action__icon' : {
            padding:'6px 4px 1px 4px',
            border:'1px solid white',
            borderRadius:'4px',
            position:'relative',
            top:'3px'
        },
        '&:hover .action__icon' : {
            border:'1px solid #ccc'
        },
        '&:hover':{
            cursor:"pointer"
        },
        '& .more_actionButton' : {
            visibility:'hidden',
            width:'150px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
        },
        '& .more_actionButton1' : {
            visibility:'hidden',
            width:'120px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
        },
        '& .more_actionButton2' : {
            visibility:'hidden',
            width:'180px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
            display:'flex',
            alignItems:'center'
        },
        '&:hover .more_actionButton' : {
            visibility:'visible'
        },
        '&:hover .more_actionButton1' : {
            visibility:'visible'
        },
        '&:hover .more_actionButton2' : {
            visibility:'visible'
        },
    },
    MuiPopoverpaper: {
        marginTop:'20px',
        marginLeft: "-88px",
        border: '1px solid #eef0f2',
        borderRadius: '6px',
        boxShadow: '0 4px 8px rgb(82 91 100 / 10%)',
        boxSizing: 'border-box',
        minWidth: '180px'
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        marginTop:'3%'
    },
    // customColumn2: {
    //     flexBasis:'26.5%', 
    //     maxWidth:'26.5%'
    // },
    // customColumn3: {
    //     flexBasis:'26.5%', 
    //     maxWidth:'26.5%'
    // },
    customColumn1: { //Invoice Number Column
        flexBasis:'4%', 
        maxWidth:'4%'
    },
    customColumn2: { //Status Column
        flexBasis:'9% !important',
        maxWidth:'9% !important',
    },
    proformaColumn2: {
        flexBasis:'8.5% !important',
        maxWidth:'8.5% !important'
    },
    customColumn3: { //Issue Date Column
        flexBasis: '12%',
        maxWidth: '12%',
    },
    proformaColumn3: { //Issue Date Column
        flexBasis: '11.5%',
        maxWidth: '11.5%',
    },
    customColumn4: { //Customer and Job Description Column
        flexBasis:'27.5%', 
        maxWidth:'27.5%'
    },
    proformaColumn4: { //Issue Date Column
        flexBasis: '23%',
        maxWidth: '23%',
    },
    customColumn5: { // Total amount and Due column
        flexBasis:'9%', 
        maxWidth:'9%'
    },
    customColumn6: { // last column for more action icon
        flexBasis:'2%', 
        maxWidth:'2%'
    },
    customColumn7: { // last column for more action icon
        flexBasis:'20.5%', 
        maxWidth:'20.5%'
    },
    customColumn8: { // last column for more action icon
        flexBasis:'9.5%', 
        maxWidth:'9.5%'
    },
    actionsIcons: {
        fontSize: '18px',
    },
    stickyTopBar : {
        position:'sticky', 
        top:0, 
        zIndex:'999', 
        backgroundColor:theme.palette.primary.light,
        
    },
    proformaTDSColumn1: {
        flexBasis:'8% !important',
        maxWidth:'8% !important',
    },
    proformaTDSColumn2: {
        flexBasis: '9%',
        maxWidth: '9%',
    },
    proformaTDSColumn3: {
        maxWidth: '24%',
        flexWidth:'24%',
    },
    statusCellWidth: {
        width:'78px',
        [theme.breakpoints.down(1470)] : {
            width: '88px',
        }
    },
    buttonContainer: {
        display:'flex',
        justifyContent:'flex-start',
        position:'absolute', 
        right:'44px',
        [theme.breakpoints.down(1950)] : {
            right:'42px',
        },
        [theme.breakpoints.down(1450)] : {
            right:'41px',
        }
    },
    invoiceTypeStyle: {
        fontSize:'10px',
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        padding:'3px',
        marginRight:'3px'
    },
    invoiceNoCol: {
        width:'60px',
        [theme.breakpoints.down(1470)] : {
            width:'65px',
        }
    }

}))

const Estimates =  (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [isActive, setIsActive] = useState(false);
    const [menuId, setMenuId] = useState('');
    const [estimatesList, setEstimatesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
    const [total, setTotal] = useState();
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [id, setId] = useState(props.selectedAccount?.id);
    const [animationActive, setAnimationActive] = useState(false);
    const [items, setItems] = useState([]);
    
    const { fullName, updatedInvoiceObj, setUpdatedInvoiceObj, callgetEstimateListApi, setCallgetEstimateListApi, 
        estimateComponentState, setEstimateComponentState} = usePropsContext();

    const { setScrollContainer, saveState, restoreState } = useRestoreComponentState(
        estimateComponentState, 
        setEstimateComponentState, 
    );
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [estimateItem, setEstimateItem] = useState(undefined);
    const [apiLoading, setApiLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;
    const entityList = props.selectedAccount?.entities;
    const restoredState = restoreState();
    const invoice_account_id = props.selectedAccount?.id;

    useEffect(() => {
        if(!props.fromClientDetails) {
            document.title = `${props.selectedAccount?.estimate_terminology} - ${fullName}`;
        }
    }, [])

    useLayoutEffect(() => {
        
        if (Object.keys(restoredState).length > 0) {
            setEstimatesList(restoredState.itemList);
            setItems(restoredState.items);
            setPage(restoredState.page);
            setTotal(restoredState.total);
        } 
    }, [restoredState]);
    
    useEffect(() => {
        if(Object.keys(restoredState).length === 0) {
            getEstimateList();
        }
    }, [id]);


    useEffect(() => {
        setId(props.selectedAccount?.id)
    }, [props.selectedAccount?.id])

    useEffect(() => {
        if(!isMount) {
            if(id !== props.selectedAccount?.id) {
                setPage(1);
            }
        }   
    }, [props.selectedAccount?.id])


    useEffect(() => {
        if (!isMount) {
            if (isMoreLoading) {
                //consoleToLog('More Loading Useffect');
                consoleToLog('isMore loading api called')
                getEstimateList();
            }
        }
    }, [isMoreLoading]);

    useEffect(() => {
        if (!isMount) {
                consoleToLog('filter changed');
                getEstimateList();
        }
    }, [props.issueStartDateFilter, props.issueEndDateFilter, props.invoiceClientsFilter, props.selectedStatusEstimateFilter, 
        props.billTypeEstimateFilter, props.selectedEntitiesFilter, props.estimateSortByFilter]);


    useEffect(() => {
        if(!isMount) {
            if(updatedInvoiceObj && estimatesList && estimatesList.length > 0 && !callgetEstimateListApi) {
                let estimateObj = updatedInvoiceObj.estimate ? updatedInvoiceObj.estimate : updatedInvoiceObj;
                const invoiceObjPresent = estimatesList.find((estimateItem) => estimateItem.id === estimateObj?.id);
                if(invoiceObjPresent) {
                    const updatedEstimatesArr = estimatesList.map((estimateItem) => 
                        estimateItem.id === estimateObj?.id ? updateEstimateItem(estimateItem, updatedInvoiceObj) : estimateItem
                    );
                    setEstimatesList([...updatedEstimatesArr]);
                    setUpdatedInvoiceObj(undefined);
                } else {
                    //setEstimatesList(oldInvoices => [updatedInvoiceObj, ...oldInvoices]);
                    setUpdatedInvoiceObj(undefined);
                }
            }
        }
    }, [updatedInvoiceObj, estimatesList]);

    useEffect(() => {
        if(!isMount) {
            if(callgetEstimateListApi) {
                pageReset();
                if(page === 1) {
                    getEstimateList();
                }
                setCallgetEstimateListApi(false);
            }
        }
    }, [callgetEstimateListApi, page]);


    const pageReset = () => {
        setPage(1);
        // if(page === 1) {
        //     getEstimateList();
        // }
    }

    let count = 0;
    if (props.invoiceClientsFilter && props.invoiceClientsFilter.length > 0) {
        count = count + 1
    }

    if(props.selectedStatusEstimateFilter && props.selectedStatusEstimateFilter.length < 8) {
        count = count + 1
    } 
    
    if(props.billTypeEstimateFilter && props.billTypeEstimateFilter.length < 2) {
        count = count + 1
    }

    if (props.selectedEntitiesFilter && props.selectedEntitiesFilter.length > 0) {
        count = count + 1
    }

    const clearRedux = () => {
        props.logout();
        props.setClearInvoiceAccounts();
        props.clearSelectedInvoiceFilters();
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD);
    }

    const getEstimateList = async(pageNo) => {
        const invoice_account_id = props.selectedAccount?.id;
        const issue_start_date = props.issueStartDateFilter; 
        const issue_end_date = props.issueEndDateFilter; 
        const clients = props.fromClientDetails && props.clientInfo ? props.clientInfo?.id : props.invoiceClientsFilter?.map((client) => client.id)?.join(); 
        const status = props.selectedStatusEstimateFilter?.map((status) => status)?.join();
        const entity_ids = props.selectedEntitiesFilter && props.selectedEntitiesFilter.length > 0 ? props.selectedEntitiesFilter.map((entity) => entity.id).join() : undefined;
        const bill_status = props.billTypeEstimateFilter?.map((type) => type)?.join();
        const order_by = props.estimateSortByFilter;

        if(!isMoreLoading) setLoading(true);
        try {
            const response = await getEstimateListApi(invoice_account_id, pageNo ? pageNo : page, entity_ids, issue_start_date, issue_end_date, clients, status, bill_status, order_by);
            const res = response.data;
            consoleToLog('Response getEstimateListApi: ', res);

            setItems(res.estimates);
            setLoading(false);

            if(isMoreLoading) {
                const newItems = estimatesList.concat(res.estimates);
                setEstimatesList(newItems);
                setPage(page + 1);
                setIsMoreLoading(!isMoreLoading);
            } else {
                if(res.estimates.length === res.page_size && id === props.selectedAccount?.id) setPage(page + 1);
                setEstimatesList(res.estimates);
                setTotal(res.total);
            }
        } catch (e) {
            consoleToLog('Error getInvoiceListApi', e.response);
            setLoading(false);
            if (e.response && e.response.status !== 401 && e.response.data && e.response.data.message) {
                enqueueSnackbar( e.response.data.message, {variant:'error'});
                return false;
            }
        }
    }


    const handleOpenFilterDrawer = () => {
        setOpenFilterDrawer(true);
    }

    const handleCloseFilterDrawer = () => {
        setOpenFilterDrawer(false);
    }

    const closeOptionsMenu = () => {
        setIsActive(false);
        setMenuId('');
    }

    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {items.length === 20 && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    // const updateInvoiceListItem = () => {
    //     let updated_array = estimatesList.map((estimate) => {
    //         if(estimate.id === paymentInvoiceObj.invoice_id) {
    //             return estimate = paymentInvoiceObj.estimate
    //         }
    //         return estimate;
    //     });

    //     setEstimatesList(updated_array);
    //     setPaymentInvoiceObj(undefined);
    // }

    

    const callDeleteEstimateApi = async(invoice_account_id, estimate_id) => {

        setAnimationActive(true);
        closeOptionsMenu();

        setApiLoading(true);

        try {
            const response = await deleteEstimateApi(invoice_account_id, estimate_id);
            const res = response.data;
            consoleToLog('Response deleteEstimateApi: ', res);
            enqueueSnackbar(res.message, {variant: 'success'});

            const updated_array = estimatesList.filter((estimate) => estimate.id !== estimate_id);
            setEstimatesList(updated_array);
            setApiLoading(false);

            trackGAEvent(props.selectedAccount.name, 'Estimate deleted', `${props.user.firstname} ${props.user.lastname}`);
            setTotal(total - 1);
            setAnimationActive(false);

            handleConfirmDialogClose();
            
        } catch (e) {
            consoleToLog('Error deleteEstimateApi: ', e.response);
            setAnimationActive(false);
            setApiLoading(false);
            closeOptionsMenu();
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const createDuplicateEstimateClick = async(e, estimate, closeOptionsMenu) => {
        e.stopPropagation();

        const entity_id = estimate.entity_id;
        const client_id = estimate.client_id;
        const estimate_id = estimate.id;

        closeOptionsMenu();
        try {
            const response = await createDuplicateEstimateApi(invoice_account_id, entity_id, client_id, estimate_id);
            const res = response.data;
            consoleToLog('Response createDuplicateEstimateApi: ', res);
            enqueueSnackbar('Estimate duplicated successfully', {variant:'success'});
            trackGAEvent(props.selectedAccount.name, 'Duplicate Estimate clicked', `${props.user.firstname} ${props.user.lastname}`)

            const obj = {
                ...res,
                client_name: res.client.name,
                entity_name: res.entity.name,
            }

            setEstimatesList([obj, ...estimatesList]);
        } catch (e) {
            consoleToLog('Error createDuplicateEstimateApi: ', e.response);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const onExportEstimatesClick = async() => {
        const invoice_account_id = props.selectedAccount?.id;
        const issue_start_date = props.issueStartDateFilter; 
        const issue_end_date = props.issueEndDateFilter; 
        const clients = props.invoiceClientsFilter?.map((client) => client.id)?.join(); 
        const status = props.selectedStatusEstimateFilter?.map((status) => status)?.join();
        const entity_ids = props.selectedEntitiesFilter && props.selectedEntitiesFilter.length > 0 ? props.selectedEntitiesFilter.map((entity) => entity.id).join() : undefined;
        const bill_status = props.billTypeEstimateFilter?.map((type) => type)?.join();

        props.setApiRoute('estimate_export');

        try{
            const response =  await exportEstimatesApi(invoice_account_id, issue_start_date, issue_end_date, clients, entity_ids, status, bill_status)
            const res = response.data;
            consoleToLog('Response exportInvoicesApi: ', res);

            props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
            props.setOverlayToken(res.task_id);
            props.setOvelayMessage(OverlayMessage.EXPORT_MSG);

        } catch(e) {
            consoleToLog('Error exportInvoicesApi: ', e);
            props.setOverlayView(OverlayView.NO_VIEW);
            props.setOverlayToken("");
            if(e.response.data && e.response.data.message){
                enqueueSnackbar(e.response.data.message, {variant:"error"});
                return;
            }
        }

    }

    const updateEstimateObjInArray = (res) => {
        const updatedArray = estimatesList.map((estimate) => {
            if(estimate.id === res.id) {
                return {
                    ...estimate,
                    status: res.status,
                    bill_status: res.bill_status,
                    can_cancel: res.can_cancel,
                    can_close: res.can_close,
                    can_create_invoice: res.can_create_invoice,
                    can_edit: res.can_edit,
                    can_reopen: res.can_reopen,
                }
            }
            return estimate
        })
        setEstimatesList([...updatedArray]);
    }

    const callApiForActionClicked = async (estimate_id, apiStr) => {
        setApiLoading(true);

        try {
            const response = await cancelReopenCloseEstimateApi(invoice_account_id, estimate_id, apiStr);
            
            consoleToLog("***Response cancelEstimateApi: ", response.data);
            setApiLoading(false);
            const res = response.data;
            updateEstimateObjInArray(res);
            enqueueSnackbar('Estimate Item updated successfully', { variant: 'success'});
            handleConfirmDialogClose();
            
        } catch (e) {
            consoleToLog("Err: cancelEstimateApi", e);
            setApiLoading(false);
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, { variant: 'error' });
            }
        }
    }


    const handleConfirmDialogOpen = (e, estimate, closeOptionsMenu, obj) => {
        e.stopPropagation();
        setOpenConfirmDialog(true);
        let estimateObj = {
            ...obj,
            ...estimate
        }
        setEstimateItem(estimateObj)
        closeOptionsMenu();
    }

    const handleConfirmDialogClose = () => {
        setOpenConfirmDialog(false);
        setEstimateItem(undefined);
    }

    const storeEstimateState = (fromEdit, fromCreate, estimate, showSendEmail) => {
        storeComponentState(fromEdit, fromCreate, estimatesList, items, page, total, estimate, saveState, 'estimate', props, showSendEmail);
    }

    const handleSortMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSortMenuClose = (event) => {
        setAnchorEl(null);
    };

    const selectSortValue = (value) => {
        trackGAEvent(props.selectedAccount.name, 'Estimate Sort', `${props.user.firstname} ${props.user.lastname} `);
        props.setEstimateSortByFilter(value);
        pageReset();
        setAnchorEl(null);
    } 

    return (
        <div className={classes.mainContent}
            ref={(node) => setScrollContainer(node)}
            id='invoices-container'
            style={{
                height: '100%',
                backgroundColor: props.fromClientDetails ? '#ffffff' : '#f7f7f7'}}>

            <Container className={classes.container} 
                maxWidth="xl" style={{padding: "0px 16px"}}>

                {loading 
                ?
                <Grid item container justifyContent='center' style={{marginTop:'32px'}}> 
                    <CircularProgress size={25} />
                </Grid>
                :
                <Paper variant='outlined' elevation={0} style={{marginTop:'10px', marginBottom:'24px', width:'100%'}}>
                    <TableContainer style={{height:'100%', overflow:'visible'}}>
                        <Table>
                        <TableHead style={{position:'sticky', top:'0px', zIndex:99, background:'#f7f7f7'}}>
                            <TableRow>
                                <TableCell className={`tableheading_style ${classes.invoiceNoCol}`} align='center'>#</TableCell>
                                <TableCell className='tableheading_style'>Status</TableCell>
                                <TableCell className='tableheading_style'>Activity</TableCell>
                                <TableCell className='tableheading_style'>Issued</TableCell>
                                {entityList && entityList.length > 1 && <TableCell className='tableheading_style'>Entity</TableCell>}
                                <TableCell className='tableheading_style'>Customer</TableCell>
                                <TableCell className='tableheading_style'>Description</TableCell>
                                <TableCell className='tableheading_style'>Total</TableCell>
                                <TableCell className='tableheading_style'>Inv. Status</TableCell>
                                {!props.fromClientDetails &&
                                <TableCell colSpan={2} className='tableheading_style'>
                                    <div style={{display:'flex', alignItems:'center'}}> 

                                        <div className='table_header_icon_column'>
                                            <Tooltip title="Filters" arrow>
                                                <Button
                                                    onClick={handleOpenFilterDrawer}  
                                                    className={classes.icons}
                                                    >
                                                    {<img src='/images/filter_icon.svg' 
                                                    className={(count && count > 0) ? `filter_icon active` : 'filter_icon'}/>}
                                                    {/* <i className={(count && count > 0) ? `flaticon-filter active` : `flaticon-filter`}></i> */}
                                                    {   count && count > 0 ?
                                                        <div className='filter__dot'></div> : <div></div>
                                                    }
                                                </Button>
                                            </Tooltip>
                                        </div>

                                        <div className='table_header_icon_column'>
                                            <Tooltip title="Export" arrow>
                                                <Button
                                                    onClick={onExportEstimatesClick}  
                                                    className={classes.icons}
                                                    >
                                                    {/* <i className={`flaticon-export`}></i> */}
                                                    <CloudUploadOutlinedIcon className='table_header_icons'/>
                                                </Button>
                                            </Tooltip>
                                        </div>
                                        
                                        <div className='table_header_icon_column'>
                                            <Tooltip title="Sort" arrow>
                                                <Button aria-label="sort" 
                                                    onClick={handleSortMenuClick} 
                                                    className={classes.icons}>
                                                    {/* <i className="flaticon-sort-1"></i> */}
                                                    <SwapVertOutlinedIcon className='table_header_icons'/>
                                                </Button>
                                            </Tooltip>

                                            <Popover
                                                id="sort-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleSortMenuClose}
                                                style={{marginTop:'20px'}}
                                            >
                                                <MenuItem 
                                                    selected={props.estimateSortByFilter === 'created_at'}
                                                    value="created_at"
                                                    onClick={(e) => selectSortValue("created_at")}>Latest First</MenuItem>
                                                <MenuItem 
                                                    selected={props.estimateSortByFilter === 'issue_date'}
                                                    value="issue_date"
                                                    onClick={(e) => selectSortValue("issue_date")}>Issue Date</MenuItem>
                                                <MenuItem
                                                    selected={props.estimateSortByFilter === 'estimate_number'}
                                                    value="estimate_number"
                                                    onClick={(e) => selectSortValue("estimate_number")}>Estimate Number</MenuItem>
                                            </Popover>
                                        </div>

                                    </div>
                                </TableCell>}
                            </TableRow>
                        </TableHead>
                        {loading ?
                        <TableBody>
                            <TableRow hover className={classes.invoiceListHover}>
                                <TableCell colSpan={entityList && entityList.length > 1 ? 9 : 8} align='center'>
                                    <CircularProgress size={25} style={{margin:'24px 0px'}}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        :
                        <TableBody>
                            {
                            estimatesList && estimatesList.length > 0 ? estimatesList.map((estimate) => {
                                const currencyObj = estimate?.currencies;
                                return (
                                    <EstimatesTableRow key={estimate.id}
                                        estimate={estimate}
                                        entityList={entityList}
                                        accessLevelViewer={accessLevelViewer}
                                        currencyObj={currencyObj}
                                        storeEstimateState={storeEstimateState}
                                        user={props.user}
                                        selectedAccount={props.selectedAccount}
                                        handleConfirmDialogOpen={handleConfirmDialogOpen}
                                        createDuplicateEstimateClick={createDuplicateEstimateClick}
                                        fromClientDetails={props.fromClientDetails}
                                    />
                                );
                            })
                            :
                            <TableRow hover className={classes.invoiceListHover}>
                                <TableCell colSpan={entityList && entityList.length > 1 ? 9 : 8} align='center'>
                                    No Estimates Found
                                </TableCell>
                            </TableRow>
                            
                            }
                        </TableBody>
                        }
                        </Table>
                    </TableContainer>
                    {!loading && loadMoreLogic()}
                </Paper>}

                <FilterDrawer openFilterDrawer={openFilterDrawer}
                    handleCloseFilterDrawer={handleCloseFilterDrawer}
                    pageReset={pageReset}
                    fromEstimatesComponent={true}
                />

                {
                    openConfirmDialog && <ShowConfirmDialog openConfirmDialog={openConfirmDialog}
                                            handleConfirmDialogClose={handleConfirmDialogClose}
                                            fromEstimateListComponent={true}
                                            estimateItem={estimateItem}
                                            callApiForActionClicked={callApiForActionClicked}
                                            apiLoading={apiLoading}
                                            callDeleteEstimateApi={callDeleteEstimateApi}
                                        />
                }
            </Container>
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    issueStartDateFilter: state.invoiceFilters.issueStartDateFilter,
    issueEndDateFilter: state.invoiceFilters.issueEndDateFilter,
    invoiceClientsFilter: state.invoiceFilters.invoiceClientsFilter,
    selectedTypeInvoiceFilter: state.invoiceFilters.selectedTypeInvoiceFilter,
    user: state.invoiceAccounts.user,
    selectedEntitiesFilter: state.invoiceFilters.selectedEntitiesFilter,
    drawerState: state.drawerState.setDrawerState,
    selectedStatusEstimateFilter: state.invoiceFilters.selectedStatusEstimateFilter,
    billTypeEstimateFilter: state.invoiceFilters.billTypeEstimateFilter,

    estimateSortByFilter: state.invoiceFilters.estimateSortByFilter
});

const mapDispatchToProps = (dispatch) => ({
    setEstimateSortByFilter: (sortBy) => dispatch(setEstimateSortByFilter(sortBy)),

    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setSelectedDrawerState: (drawerState) => dispatch((setSelectedDrawerState(drawerState))),
    setClearInvoiceAccounts: () => dispatch((setClearInvoiceAccounts())),

    setOverlayView: (exportView) => dispatch(setOverlayView(exportView)),
    setOverlayToken: (randomToken) => dispatch(setOverlayToken(randomToken)),
    setOvelayMessage: (overlayMessage) => dispatch(setOvelayMessage(overlayMessage)),
    setApiRoute: (route) => dispatch(setApiRoute(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(Estimates);