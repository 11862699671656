import React from 'react';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import {Grid, Typography, Button, Tooltip, TextField, Popover} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PaymentDateRangeFilterComponent from './filters/PaymentDateRangeFilterComponent';
import ClientFilterComponent from './filters/ClientFilterComponent';
import { connect } from 'react-redux';
import {clearSelectedTransactionFilters} from '../actions/transactionFilters';
import PaymentMethodFilterComponent from './filters/PaymentMethodFilterComponent';
import VendorFilterComponent from './filters/VendorFilterComponent';

const useStyles = makeStyles((theme) => ({
    customMarginTop : {
        marginTop:"16px"
    },
    customMarginBottom : {
        marginBottom:'-8px'
    },
}));

const TransactionsFilterDrawer = (props) => {
    const classes = useStyles();

    return (
        <RightToLeftSideDrawer title="Filters" 
            openDrawer={props.openFilterDrawer}
            closeDrawer={props.handleCloseFilterDrawer}
            >

            <Grid item sm={12} style={{padding:'16px'}}>
                    <PaymentDateRangeFilterComponent pageReset={props.pageReset}/>

                    {props.isFromBillPaymentsComponent 
                    ?
                    <VendorFilterComponent pageReset={props.pageReset} 
                        isFromTransactionFilter={true}
                    />
                    :
                    <ClientFilterComponent pageReset={props.pageReset} 
                        isFromTransactionFilter={true}
                    />} 

                    <PaymentMethodFilterComponent pageReset={props.pageReset}/>

                <div className="modal__footer">
                    <Button variant="contained" color="primary" 
                            className="modal__button"
                            onClick={() =>{
                                props.pageReset();
                                props.clearSelectedTransactionFilters();
                                }
                            }
                            >
                        Reset Filters
                    </Button>
                </div> 
            </Grid>
            
        </RightToLeftSideDrawer>
    );
}

const mapDispatchToProps = (dispatch) => ({
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
})

export default connect(null, mapDispatchToProps)(TransactionsFilterDrawer);